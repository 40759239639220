<svg
  class="portals-athena-icon"
  xmlns="http://www.w3.org/2000/svg"
  width="20px"
  height="20px"
  viewBox="-38 -30 50 50">
  <path
    d="m -17.310251,-7.7493217 c 0,0 -4.7,-5.7000103 -4.8,-12.0000003 -0.1,-6.20001 4.4,-10.6 4.4,-10.6 0,0 4.7,5.7 4.8,12 0.1,6.2 -4.4,10.6000003 -4.4,10.6000003 z"
    id="path4"
    style="fill:#baca33" />
  <path
    d="m 7.7897495,-14.849322 c 0,0 0.5,12.6000003 -5.7,21.2999903 -6.3,8.5999997 -17.0000005,10.0999997 -17.0000005,10.0999997 0,0 -0.5,-12.5999997 5.7000005,-21.2999897 6.2,-8.7000103 17,-10.1000003 17,-10.1000003 z m -17.5,-1.5 c 0,1.7 1.3,3 3,3 1.7,0 3,-1.3 3,-3 0,-1.7 -1.3,-3 -3,-3 -1.7,0 -3,1.39999 -3,3 z"
    id="path6"
    style="fill:#799b3e" />
  <path
    d="m -17.510251,5.2506783 c 0,-0.1 0,-0.1 0,0 0,-2.6 -0.4,-10.1 -4.4,-15.6000003 -4.9,-6.60001 -13.1,-7.7 -13.1,-7.7 0,0 -0.4,9.7000003 4.4,16.3000003 3.4,4.7 8.6,6.6 11.3,7.29999 V 15.950668 c 0,0.5 0.4,0.90001 0.9,0.90001 0.5,0 0.9,-0.40001 0.9,-0.90001 z"
    id="path8"
    style="fill:#baca33" />
</svg>
