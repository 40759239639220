import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {PortalLibConfig, PortalLibConfigToken} from "../PortalLibConfig";
import {lastValueFrom} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class MyProfileRestService {

  constructor(@Inject(PortalLibConfigToken) private config: PortalLibConfig,
              private http: HttpClient) {
  }

  /**
   * @return Promise<MyProfile> as any; cannot reference psygnal-model from portal-lib.
   */
  getProfile(): Promise<any> {
    const url = this.config.auth.profileUrl;
    return lastValueFrom(this.http.get(url));
  }

  /**
   * @param request PatchRequest; cannot reference psygnal-model from portal-lib.
   * @return Promise<MyProfile> as any; cannot reference psygnal-model from portal-lib.
   */
  patchProfile(request: any): Promise<any> {
    const url = this.config.auth.profileUrl;
    return lastValueFrom(this.http.patch(url, request));
  }

  /**
   * @param request ResetUserPasswordRequest; cannot reference psygnal-model from portal-lib.
   * @return Promise; server returns NoContent
   */
  updatePassword(request: any): Promise<any> {
    const url = `${this.config.auth.profileUrl}/password`;
    return lastValueFrom(this.http.post(url, request));
  }
}
