export function CheckValue(found: boolean, search: string, target: string): boolean {
  if (!search) {
    return found;
  }
  if (!target) {
    return false;
  }
  return search === target;
}

export function CheckMultivalue(found: boolean, search: string[], target: string[]): boolean {
  if (!search) {
    return found;
  }
  if (search.length === 0) {
    return found;
  }
  if (!target || target.length === 0) {
    return false;
  }
  return search.some(i => target.includes(i));
}

export function CheckMultivalueSingleTarget(found: boolean, search: string[], target: string): boolean {
  if (!search) {
    return found;
  }
  if (search.length === 0) {
    return found;
  }
  if (!target) {
    return false;
  }
  return search.includes(target);
}
