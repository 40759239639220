import {Component, EventEmitter, Inject, OnInit, Output, ViewChild} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NgbModal, NgbModalRef, NgbPopover} from '@ng-bootstrap/ng-bootstrap';
import {PortalToasterService} from "../../services/portal.toaster.service";
import {Subscription, timer} from "rxjs";
import moment, {Moment} from "moment";
import {PortalLibConfig, PortalLibConfigToken} from "../../PortalLibConfig";
import {NavbarProfileComponent} from "../navbar-profile/navbar-profile.component";

@Component({
  selector: 'navbar-profile-menu',
  templateUrl: './navbar-profile-menu.component.html',
  styleUrls: ['./navbar-profile-menu.component.scss']
})
export class NavbarProfileMenuComponent implements OnInit {
  @Output() logoutEvent = new EventEmitter<any>();
  @ViewChild('timeoutPopover') private timeoutPopover: NgbPopover;
  @ViewChild('loggingOutModal') private loggingOutModal;
  @ViewChild('navbarProfile') private navbarProfile: NavbarProfileComponent;
  sessionExpireTimeText: string = '';
  user: any = {};
  private timeoutListener: Subscription;
  private expirationDateTime: Moment;

  constructor(public auth: AuthService,
              private router: Router,
              private modalService: NgbModal,
              @Inject(PortalLibConfigToken) private config: PortalLibConfig) {
    this.timeoutHandler = this.timeoutHandler.bind(this); // allows using as a callback
  }

  ngOnInit(): void {
    this.expirationDateTime = moment(this.auth.getExpiration());
    this.user = this.auth.getProfile();

    // always log out a minute early
    this.expirationDateTime.subtract(1, "m");
    this.initLogoutTimeout();
  }

  async showProfileModal() {
    await this.navbarProfile.showProfile();
    // const modalRef = this.modalService.open(NavbarProfileComponent);
  }

  async logout() {
    try {
      this.modalService.open(this.loggingOutModal, {
        size: "sm",
        backdrop: "static"
      });
      this.logoutEvent.emit();

      await this.auth.logout();

      this.modalService.dismissAll();
    } catch (e) {
      console.log(e);
      this.modalService.dismissAll();
    }
    await this.router.navigate([this.config.portal.loginRoute], {
      queryParams: {
        reason: "manual"
      }
    });
  }

  async timeoutHandler(val) {
    if (!this.timeoutPopover.isOpen()) {
      this.timeoutPopover.open();
    }
    const duration = moment.duration(this.expirationDateTime.diff(moment()));
    this.sessionExpireTimeText = duration.humanize(true, {ss: 0, s: 30});

    if (duration.asMilliseconds() < 1000) {
      this.timeoutListener.unsubscribe();
      await this.logout();
      return;
    }
    if (duration.asMilliseconds() < 60000) {
      this.timeoutListener.unsubscribe();
      // Run every second until it's time to log out.
      this.timeoutListener = timer(0, 1000)
        .subscribe(this.timeoutHandler);
    }
  }

  // Session Logout Timer
  initLogoutTimeout() {
    // DEBUG shortcut
    // let fakeTime = moment();
    // fakeTime = fakeTime.add(1, "m");
    // fakeTime = fakeTime.add(29, "s");
    // this.expirationDateTime = fakeTime;

    const now = moment();
    const timeUntilLogout = this.expirationDateTime.diff(now);

    // if the time until logout is within the last minute and a half before logout,
    // that means the user refreshed in the middle of the timing loop, and
    // we're at a situation that requires seconds-level timing rather than the minutes-level
    // timing.
    if (timeUntilLogout < 90000) {
      // Run every second until it's time to log out.
      this.timeoutListener = timer(0, 1000)
        .subscribe(this.timeoutHandler);
      return;
    }

    // if get here, we have more than a minute before logout, so figure out how long we need
    // to wait until we start the minutes-level timing loop.
    // by default, we should begin the timing loop 5 minutes before expiration
    let timingStartTime = this.expirationDateTime.clone();
    timingStartTime.subtract(5, "m");

    // but if the timing start time is already in the past, that means we've
    // refreshed in the middle of the timing loop, between 1 and 5 minutes before
    // expiration. We should calculate our wait time from now rather
    // than 5 minutes before expiration, giving us a zero wait time.
    if (timingStartTime.isBefore(now)) {
      timingStartTime = now.clone();
    }
    const waitUntil = timingStartTime.diff(now); // in milliseconds

    // run every minute, until we're down to the last minute.
    this.timeoutListener = timer(waitUntil, 60000)
      .subscribe(this.timeoutHandler);
  }
}
