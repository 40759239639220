export enum TaskTypes {
    AthenaSync = "athena_sync",
    FacilityScheduleReminder = "facility_schedule_email",
    AthenaFollowupAppointments = "athena_followup_appointments",
    SalesforceClaimGeneration = "salesforce_claim_generation",
    SalesforcePatientVerification = "salesforce_patient_verification",
    SingleRunTask = "single_run",
}

export enum TaskStatusTypes {
    Complete = "Complete",
    Error = "Error",
}
