export const DateFormat = "YYYY-MM-DD";

export const DateTimeFormat = "YYYY-MM-DD HH:mm";

export const DateTimeFormatWithTZ = "YYYY-MM-DD HH:mm ZZ";

export const AmericanDateFormat = "MM/DD/YYYY";

export const AmericanDateFormatNoSlash = "MM-DD-YYYY";

export const MonthYearFormat = "MMM, YYYY";

export const TimeFormat24Hr = "HH:mm";
