import validator from "validator";
import moment from "moment";

export class Validator {
    errorMessage: string;
    validator: (val: any) => boolean;

    constructor(errorMessage: string, validFunc: (val: any) => boolean) {
        this.errorMessage = errorMessage;
        this.validator = validFunc;
    }
}

export class TypeValidator extends Validator {
    constructor(typename: string) {
        const errorMessage = `Field must be of type "${typename}" or null`;
        const validFunc = (val: any) => typeof val === typename || val === null;
        super(errorMessage, validFunc);
    }
}

export class LengthValidator extends Validator {
    constructor(length: number) {
        const errorMessage = `Field must have length ${length}`;
        const validFunc = (val: any) => val ? val.length === length : true;
        super(errorMessage, validFunc);
    }
}

export class MinLengthValidator extends Validator {
    constructor(length: number) {
        const errorMessage = `Field must have length at least ${length}`;
        const validFunc = (val: any) => val ? val.length >= length : true;
        super(errorMessage, validFunc);
    }
}

export class MaxLengthValidator extends Validator {
    constructor(length: number) {
        const errorMessage = `Field must have length no more than ${length}`;
        const validFunc = (val: any) => val ? val.length <= length : true;
        super(errorMessage, validFunc);
    }
}

export class MinValueValidator extends Validator {
    constructor(min: number) {
        const errorMessage = `Field must have a value that is at least ${min}`;
        const validFunc = (val: any) => val ? val >= min : true;
        super(errorMessage, validFunc);
    }
}

export class MaxValueValidator extends Validator {
    constructor(max: number) {
        const errorMessage = `Field must have a value that is no more than ${max}`;
        const validFunc = (val: any) => val ? val <= max : true;
        super(errorMessage, validFunc);
    }
}


export class EnumValidator extends Validator {
    constructor(values: string[]) {
        const errorMessage = `Field must have a value which is one of the following values: ${values}`;
        const validFunc = (val: any) => {
            if (!val) {
                return true;
            }
            if (typeof val !== "string") {
                return false;
            }
            return values.includes(val);
        };
        super(errorMessage, validFunc);
    }
}

export class EnumArrayValidator extends Validator {
    constructor(values: string[]) {
        const errorMessage = `Field must be an array of strings where each element is a value which is one of the following values: ${values}`;
        const validFunc = (val: any) => {
            if (!val) {
                return true;
            }
            if (!Array.isArray(val)) {
                return false;
            }
            for (const item of val) {
                if (typeof item !== "string") {
                    return false;
                }
                if (!values.includes(item)) {
                    return false;
                }
            }
            return true;
        };
        super(errorMessage, validFunc);
    }
}

export class ArrayOfTypeValidator extends Validator {
    constructor(typeValidator: Map<string, Validator[]>, typeName: string) {
        const errorMessage = `Field must be an array of type ${typeName} where each element passes validation for that type.`;
        const validFunc = (val: any) => {
            if (!val) {
                return true;
            }
            if (!Array.isArray(val)) {
                return false;
            }
            for (const item of val) {
                if (typeof item !== "object") {
                    return false;
                }
                for (const [fieldName, fieldValidators] of typeValidator) {
                    for (const fieldValidator of fieldValidators) {
                        if (!fieldValidator.validator(item[fieldName])) {
                            return false;
                        }
                    }
                }
            }
            return true;
        };
        super(errorMessage, validFunc);
    }
}

export const StringValidator = new TypeValidator("string");
export const NumberValidator = new TypeValidator("number");
export const BoolValidator = new TypeValidator("boolean");
export const IntValidator = new Validator(
    "Field must be an integer",
    (val: any) => val ? Number.isInteger(val) : true);
export const DateValidator = new Validator(
    "Field must be a parsable date/datetime string",
    (val: any) => {
        if (!val) {
            return true; // allow empty values, RequiredValidator necessary if values cannot be empty
        }
        if (typeof val !== "string") {
            return false;
        }
        if (val.length === 0 || val.length > 30) {
            return false;
        }
        const m = moment(val);
        return m.isValid();
    });

export const EmailValidator = new Validator(
    "Field must be a valid email address",
    (val: any) => val ? validator.isEmail(val) : true);

export function UrlValidator(protocols:string[] = ["https"]): Validator  {
    return new Validator(
        "Field must be a valid URL",
        (val: any) => {
            if (val) {
                return validator.isURL(val, {
                    protocols: protocols,
                    require_tld: false,
                });
            }
            return true;
        })
};

export const RequiredValidator = new Validator(
    "Field requires a value and cannot be undefined or null",
    (val: any) => val !== undefined && val !== null && val !== '');
export const StateArrayValidator = new Validator(
    "Field must be an array of strings with two characters each, with at most 50 elements",
    (val: any) => {
        if (!Array.isArray(val)) {
            return false;
        }
        if (val.length > 50) {
            return false;
        }
        for (const state of val) {
            if (typeof state !== "string") {
                return false;
            }
            if (state.length != 2) {
                return false;
            }
        }
        return true;
    }
);

export const StringArrayValidator = new Validator(
    "Field must be an array of strings",
    (val: any) => {
        if (!Array.isArray(val)) {
            return false;
        }
        if (val.find(f => typeof f !== "string")) {
            return false;
        }
        return true;
    }
);


export const String255Validator = [StringValidator, new MaxLengthValidator(255)];
export const String100Validator = [StringValidator, new MaxLengthValidator(100)];
export const String50Validator = [StringValidator, new MaxLengthValidator(50)];
export const String25Validator = [StringValidator, new MaxLengthValidator(25)];
