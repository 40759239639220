import {fromEvent, Subscription} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {ElementRef} from "@angular/core";

export class SearchBase<SearchData extends SearchDataBase> {
  constructor(private defaultSortField = "name",
              private defaultSortOrder = "asc") {
  }

  loading: boolean;
  searchUpdated: Date; // hack in to force a pipe to update
  searchSubscription: Subscription = null;
  searchRegexs: RegExp[];
  searchTokens: string[];
  searchData: SearchData = null;

  afterViewInit(searchControl: ElementRef): void {
    this.searchSubscription = fromEvent(searchControl.nativeElement, 'keydown').pipe(
      debounceTime(100)
    ).subscribe(() => {
      this.updateSearch();
    });
  }

  updateSearch() {
    setTimeout(() => {
      const {tokens, regexes} = this.parseSearch(this.searchData.searchValue);
      this.searchTokens = tokens;
      this.searchRegexs = regexes;
      this.searchUpdated = new Date();
    });
  }

  parseSearch(searchValue: string): { tokens: string[], regexes: RegExp[] } {
    let tokens: string[] = [];
    let regexes: RegExp[] = [];
    if (searchValue) {
      tokens = searchValue.match(/\S+/g);
      regexes = tokens.map(t => new RegExp(t, 'i'));
    }
    return {
      tokens,
      regexes
    };
  }

  resetSearchData() {
    this.searchData = <any> {
      sortField: this.defaultSortField,
      sortOrder: this.defaultSortOrder
    };
  }

  clearForm(clearBtn) {
    clearBtn.disabled = true;
    setTimeout(() => {
      this.resetSearchData();
      this.updateSearch();
      clearBtn.disabled = false;
    });
  }

  testSearch(val): boolean {
    let found = true;
    if (this.searchData.searchValue) {
      let hasVal = false;
      if (val) {
        hasVal = this.searchRegexs.some(r => r.test(val));
      }
      found = found && (hasVal);
    }
    return found;
  }
}

export interface SearchDataBase {
  searchValue: string;
  sortField: string;
  sortOrder: string;
}
