<div class="container-fluid">
  <ul class="breadcrumb">
    <li class="breadcrumb-item active">Appointment Video Monitoring</li>
    <li class="breadcrumb-item" *ngIf="loading">
      <portals-loading-icon></portals-loading-icon>
    </li>
  </ul>
  <form #searchForm="ngForm">
    <button class="btn btn-primary float-end ms-3"
            (click)="loadMeetings()"
            [disabled]="syncingWithZoom || loading">
      <i class="fas fa-sync-alt fa-fw me-2"
         [class.fa-spin]="syncingWithZoom"></i>
      Refresh Zoom
      <div class="d-inline-block" style="width: 2em;">
        <ng-container *ngIf="!syncingWithZoom && !loading">
          ({{countdown | number:"1.0"}}s)
        </ng-container>
      </div>
    </button>
    <div class="float-end ms-5">
      <select name="userTimezone"
              class="form-select"
              (change)="updateSearch()"
              [(ngModel)]="userTimezone"
              id="userTimezone">
        <option *ngFor="let tz of TimeZoneList" [value]="tz[0]">{{tz[1]}}</option>
      </select>
    </div>
    <fieldset [disabled]="loading">
      <div class="container-fluid mb-3">
        <div class="input-group flex-nowrap">
          <button class="btn btn-secondary dropdown-toggle"
                  ngbTooltip="Filter Results"
                  (click)="$event.stopPropagation(); searchDrop.open();">
            <i class="fas fa-sliders-h"></i>
          </button>
          <div ngbDropdown #searchDrop="ngbDropdown" [autoClose]="true"
               placement="bottom-left">
            <div ngbDropdownMenu class="p-2">
              <div class="clearfix">
                <button type="button" class="btn btn-sm btn-outline-info float-start" #clearBtn
                        (click)="clearForm(clearBtn); searchDrop.close();">
                  <i class="fas fa-eraser"></i>
                </button>
                <button type="button" class="btn-close float-end" #clearBtn
                        (click)="searchDrop.close()">
                </button>
              </div>
              <h6>Filter</h6>
              <div ngbTooltip="Show only appointments from facilities that are in the selected states">
                <label class="small m-0 form-label" for="stateFilter">By State</label>
                <ng-select [multiple]="true" style="width: 15em;"
                           [items]="statesList"
                           name="stateFilter"
                           (change)="updateSearch()"
                           [(ngModel)]="searchData.stateFilter"
                           id="stateFilter">
                </ng-select>
              </div>
              <div ngbTooltip="Show only alerted appointments that match the selected type of alert">
                <label class="small m-0 form-label" for="alertType">By Alert Type</label>
                <select name="alertType"
                        class="form-select"
                        (change)="updateSearch()"
                        [(ngModel)]="searchData.alertType"
                        id="alertType">
                  <option value=""></option>
                  <option value="warning">Warning</option>
                  <option value="danger">Danger</option>
                  <option value="both">Warning or Danger</option>
                </select>
              </div>
              <div>
                <div class="form-check form-switch"
                     ngbTooltip="Show only sessions with one participant">
                  <input type="checkbox" class="form-check-input"
                         id="misingParty"
                         name="misingParty"
                         (change)="updateSearch()"
                         [(ngModel)]="searchData.missingParty">
                  <label class="small form-check-label text-nowrap" for="misingParty">
                    Missing Provider or Patient
                  </label>
                </div>
                <div class="form-check form-switch"
                     ngbTooltip="Show only sessions with three or more participants">
                  <input type="checkbox" class="form-check-input"
                         id="overloaded"
                         name="overloaded"
                         (change)="updateSearch()"
                         [(ngModel)]="searchData.overloaded">
                  <label class="small form-check-label text-nowrap" for="overloaded">
                    Overloaded
                  </label>
                </div>
                <div class="form-check form-switch"
                     ngbTooltip="Show only sessions with at least one participant outside the range of scheduled appointments">
                  <input type="checkbox" class="form-check-input"
                         id="unscheduled"
                         name="unscheduled"
                         (change)="updateSearch()"
                         [(ngModel)]="searchData.unscheduled">
                  <label class="small form-check-label text-nowrap" for="unscheduled">
                    Unscheduled
                  </label>
                </div>
                <div class="form-check form-switch"
                     ngbTooltip="Hide sessions with no participants whose last scheduled appointment is over">
                  <input type="checkbox" class="form-check-input"
                         id="complete"
                         name="complete"
                         (change)="updateSearch()"
                         [(ngModel)]="searchData.complete">
                  <label class="small form-check-label text-nowrap" for="complete">
                    Hide Complete Sessions
                  </label>
                </div>
              </div>
              <h6>Sort</h6>
              <div>
                <label class="small form-label m-0" for="sortField">By Field</label>
                <select name="sortField"
                        class="form-select"
                        (change)="updateSearch()"
                        [(ngModel)]="searchData.sortField"
                        id="sortField">
                  <option value="name" selected>Name</option>
                  <option value="state">State</option>
                  <option value="firstAppointmentUtc">Start Time</option>
                  <option value="lastAppointmentUtc">End Time</option>
                </select>
              </div>
              <div>
                <label class="small form-label m-0" for="sortOrder">Direction</label>
                <select name="sortOrder"
                        class="form-select"
                        (change)="updateSearch()"
                        [(ngModel)]="searchData.sortOrder"
                        id="sortOrder">
                  <option value="asc" selected>Ascending</option>
                  <option value="desc">Descending</option>
                </select>
              </div>
            </div>
          </div>
          <input type="search" class="form-control" placeholder="Search by Facility Name"
                 #searchControl
                 (keydown)="updateSearch()"
                 name="search"
                 [disabled]="loading || syncingWithZoom"
                 [(ngModel)]="searchData.searchValue">
            <button class="btn btn-secondary z-0"
                    [disabled]="loading"
                    (click)="updateSearch()">
              <i class="fas fa-search"></i>
            </button>
        </div>
      </div>
    </fieldset>
  </form>
  <p class="lead text-center" *ngIf="loading">
    <i class="fas fa-spinner fa-spin"></i>
    Loading...
  </p>
</div>

<div class="workspace" *ngIf="!loading"
     [class.show-key]="showKey"
     [class.show-unmapped]="showUnmapped"
     [class.open]="showUnmapped || showKey">
  <div class="sidebar position-relative">
    <i class="far fa-window-close cursor-pointer position-absolute text-primary"
       style="top: 0; right: 0"
       (click)="showUnmapped = false; showKey = false;"></i>
    <div class="header">
      <div class="unmapped-icon cursor-pointer text-warning"
           [ngbTooltip]="(unmappedSessions?.length || 0) + ' unexpected Zoom sessions detected'"
           placement="right" container="body"
           [class.animated]="unmappedSessions?.length > 0"
           [class.tada]="unmappedSessions?.length > 0"
           (click)="showKey = false; showUnmapped = true">
        <span class="fa-stack">
          <i class="fas fa-video fa-fw fa-stack-2x"></i>
          <i class="fas fa-question fa-fw fa-stack-1x text-white"></i>
          <span class="badge rounded-pill bg-danger text-white">
            {{unmappedSessions?.length || 0}}
          </span>
        </span>
      </div>
      <div class="key-icon cursor-pointer"
           ngbTooltip="Session State Color Meaning"
           placement="right" container="body"
           (click)="showKey = true; showUnmapped = false">
        <svg height="40px" width="40px"
             style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round"
             xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" xml:space="preserve"
             version="1.1" viewBox="0 0 344 344">
        <defs></defs>
          <g id="Untitled">
            <path stroke="#000000" stroke-width="3" class="key-primary"
                  d="M170.705+170.705L1.00001+171.055C0.968836+77.3966+76.6971+1.35527+170.355+1.00001L170.705+170.705Z"
                  fill="#cc2222" stroke-linecap="butt" opacity="1" stroke-linejoin="miter"/>
            <path stroke="#000000" stroke-width="3" class="key-success"
                  d="M170.705+170.705L170.355+1.00001C264.014+0.968825+340.055+76.6971+340.411+170.355L170.705+170.705Z"
                  fill="#ffff55" stroke-linecap="butt" opacity="1" stroke-linejoin="miter"/>
            <path stroke="#000000" stroke-width="3" class="key-danger"
                  d="M170.705+170.705L171.055+340.411C264.713+340.055+340.442+264.014+340.411+170.355L170.705+170.705Z"
                  fill="#228844" stroke-linecap="butt" opacity="1" stroke-linejoin="miter"/>
            <path stroke="#000000" stroke-width="3" class="key-warning"
                  d="M170.705+170.705L1.00001+171.055C1.3553+264.713+77.3966+340.442+171.055+340.411L170.705+170.705Z"
                  fill="#223399" stroke-linecap="butt" opacity="1" stroke-linejoin="miter"/>
          </g>
          <g style="overflow:hidden; text-anchor: middle; font-size:250px; font-family: Times New Roman, sans-serif">
            <defs>
              <filter id="glow" x="-50%" y="-50%" width="190%" height="190%">
                <feGaussianBlur stdDeviation="10 10" result="glow"/>
                <feMerge>
                  <feMergeNode in="glow"/>
                  <feMergeNode in="glow"/>
                  <feMergeNode in="glow"/>
                </feMerge>
              </filter>
            </defs>
            <text style="filter: url(#glow); fill: black" x="50%" y="75%">?</text>
            <text x="50%" y="75%" style="fill: white">?</text>
          </g>
        </svg>
      </div>
    </div>
    <div class="details">
      <div class="key-details animated fadeInLeft">
        <h5 class="text-center small font-italic mb-3">Zoom Session State Color Key</h5>
        <div class="row mt-3 ms-2">
          <div class="col-1 session-key bg-primary"></div>
          <div class="col small">
            This session has not started yet, with no current anomalies detected.
          </div>
        </div>
        <div class="row mt-3 ms-2">
          <div class="col-1 session-key bg-success"></div>
          <div class="col small">
            This session is in progress, with no current anomalies detected.
          </div>
        </div>
        <div class="row mt-3 ms-2">
          <div class="col-1 session-key bg-secondary"></div>
          <div class="col small">
            This session has been completed or is in the past, with no current anomalies detected.
          </div>
        </div>
        <div class="row mt-3 ms-2">
          <div class="col-1 session-key bg-warning"></div>
          <div class="col small">
            A minor anomaly has been detected with this session, such as:
            <ul style="padding-inline-start: 20px;">
              <li>The session should be occurring at this time, but less than two participants are connected.</li>
              <li>There are three or more participants.</li>
              <li>The session has two participants, but appears to be occurring outside of its scheduled time.</li>
            </ul>
          </div>
        </div>
        <div class="row mt-3 ms-2">
          <div class="col-1 session-key bg-danger"></div>
          <div class="col small">
            A serious anomaly has been detected with this session, such as:
            <ul style="padding-inline-start: 20px;">
              <li>
                The session is in the "late start" state. One party is missing, and the current time
                is between 5 and 10 minutes after the expected start.
              </li>
              <li>
                The session has one participant and is occurring outside of a scheduled time.
                This could indicate zoom was left on and forgotten by the client/facility.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="unmapped-details animated fadeInLeft">
        <h5 class="text-center small font-italic">Unexpected Zoom Sessions</h5>
        <ul class="list-group">
          <li class="list-group-item small"
              *ngFor="let session of unmappedSessions">
            <a [href]="getZoomLink(session)"
               ngbTooltip="View on Zoom Website"
               target="_blank">
              {{session.host}}
            </a><br/>
            <span class="me-2">
              <i class="fas fa-video fa-fw"></i>
              {{session.participants || 0}}
            </span>
            {{getMeetingTime(session.start_time)}}
            <small>({{getMeetingDuration(session.start_time)}})</small>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="w-100">
    <div class="text-center" *ngIf="zoomSessions && zoomSessions.length === 0">
      <em>No Appointments Today</em>
    </div>
    <div class="d-flex flex-wrap justify-content-center">
      <div class="m-3 border border-primary video-session"
           *ngFor="let session of zoomSessions | sort:searchData.sortField:searchData.sortOrder | filterFunc:searchFilter:searchUpdated"
           [class.border-warning]="session.isWarning"
           [class.border-danger]="session.isDanger"
           [class.border-success]="session.isCurrent"
           [class.border-secondary]="session.isComplete">
        <div class="video-session-left border-end border-primary"
             [class.border-warning]="session.isWarning"
             [class.border-danger]="session.isDanger"
             [class.border-success]="session.isCurrent"
             [class.border-secondary]="session.isComplete">
          <portals-athena-icon ngbTooltip="This appointment originated in Athena"></portals-athena-icon>
        </div>
        <div class="video-session-main">
          <div class="text-truncate text-center font-italic" [ngbTooltip]="session.name">
            <span [innerHTML]="session.name | markResult:searchRegexs"></span>
          </div>
          <div class="row">
            <div class="col-6">
              <div>
                <i class="fas fa-calendar-check fa-fw"></i>
                {{session.appointments?.length || 0}}
              </div>
              <div class="ml-0 p-0 mr-0"
                   style="font-size: 75%;">
                <div>First: {{getAppointmentTime(session.firstAppointment) }}</div>
                <div>Last: {{getAppointmentTime(session.lastAppointment) }}</div>
              </div>
              <!--              <ng-template #appointmentList>-->

              <!--                tooltipClass="zoom-appointment-tooltip"-->
              <!--                placement="bottom"-->
              <!--                [ngbTooltip]="appointmentList"-->

              <!--                <div *ngIf="session.appointments && session.sessionAppointments.length > 0">-->
              <!--                  <div class="row" *ngFor="let appt of session.sessionAppointments">-->
              <!--                    <div class="col p-0">-->
              <!--                      {{getAppointmentTime(appt.start) }} - -->
              <!--                      {{getAppointmentTime(appt.end) }}-->
              <!--                    </div>-->
              <!--                    <div class="col p-0">{{appt.providerName}}</div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </ng-template>-->
            </div>
            <div class="col-6">
              <div>
                <i class="fas fa-video fa-fw"></i>
                {{session?.zoomMeeting?.participants || 0}}
              </div>
              <div *ngIf="session.zoomMeeting"
                   style="font-size: 75%;">
                Began:
                <a [href]="getZoomLink(session.zoomMeeting)"
                   ngbTooltip="View on Zoom Website"
                   placement="bottom"
                   target="_blank">
                  {{getMeetingTime(session.zoomMeeting?.start_time) }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="video-session-right bg-primary border-primary text-white"
             [class.bg-warning]="session.isWarning"
             [class.border-warning]="session.isWarning"
             [class.bg-danger]="session.isDanger"
             [class.border-danger]="session.isDanger"
             [class.border-success]="session.isCurrent"
             [class.bg-success]="session.isCurrent"
             [class.bg-secondary]="session.isComplete"
             [class.border-secondary]="session.isComplete">
          {{session.state || "??"}}
        </div>
      </div>
    </div>
  </div>
</div>

