import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from "@angular/forms";

@Directive({
  selector: '[functionValidator]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: FunctionValidatorDirective,
    multi: true
  }]
})
export class FunctionValidatorDirective implements Validator {

  @Input('func') func: (control: AbstractControl) => ValidationErrors | null;

  constructor() {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if(this.func) {
      return this.func(control);
    } else {
      return null;
    }
  }
}
