import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'portals-unauthorized-route',
  templateUrl: './unauthorized-route.component.html',
  styleUrls: ['./unauthorized-route.component.scss']
})
export class UnauthorizedRouteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
