import {Pipe, PipeTransform} from '@angular/core';
import _ from "lodash";

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {

  transform<T>(items: T[], field: string, order?: string): T[] {
    if (!items) {
      return items;
    }
    if (!field) {
      return items;
    }
    if (!order) {
      order = 'asc';
    }
    const sorter = f => _.get(f, field)?.toLowerCase();
    return _.orderBy(items, [sorter], [order]);
  }
}
