import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'portals-microsoft-icon',
  templateUrl: './microsoft-icon.component.html',
  styleUrls: ['./microsoft-icon.component.scss']
})
export class MicrosoftIconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
