<div class="container-fluid" xmlns="http://www.w3.org/1999/html">
  <ul class="breadcrumb">
    <li class="breadcrumb-item active">Automated Task Logs</li>
    <li class="breadcrumb-item" *ngIf="loading">
      <portals-loading-icon></portals-loading-icon>
    </li>
  </ul>
  <ul ngbNav #tabs="ngbNav" class="nav-tabs" [animation]="false">
    <li ngbNavItem="1" class="ms-4">
      <a ngbNavLink (click)="loadTaskTab(TaskTypes.AthenaSync)">Athena Sync</a>
      <ng-template ngbNavContent>
        <div class="row mt-3">
          <div class="col-7">
            <h4>Automated Task <em>Athena Sync</em></h4>
          </div>
          <div class="col-5" *ngIf="!loading">
            <h4>Task Notes</h4>
          </div>
        </div>
      </ng-template>
    </li>
    <li ngbNavItem="5" class="ms-4">
      <a ngbNavLink (click)="loadTaskTab(TaskTypes.SalesforceClaimGeneration)">Salesforce Claim Generation</a>
      <ng-template ngbNavContent>
        <div class="row mt-3">
          <div class="col-7">
            <h4>Automated Task <em>Salesforce Claim Generation</em></h4>
          </div>
          <div class="col-5" *ngIf="!loading">
            <h4>Task Notes</h4>
          </div>
        </div>
      </ng-template>
    </li>
    <li ngbNavItem="6" class="ms-4">
      <a ngbNavLink (click)="loadTaskTab(TaskTypes.SalesforcePatientVerification)">Salesforce Patient Verification</a>
      <ng-template ngbNavContent>
        <div class="row mt-3">
          <div class="col-7">
            <h4>Automated Task <em>Salesforce Patient Verification</em></h4>
          </div>
          <div class="col-5" *ngIf="!loading">
            <h4>Task Notes</h4>
          </div>
        </div>
      </ng-template>
    </li>
    <li ngbNavItem="2" class="ms-4">
      <a ngbNavLink (click)="loadTaskTab(TaskTypes.FacilityScheduleReminder)">Facility Schedule Emails (Athena)</a>
      <ng-template ngbNavContent>
        <div class="row mt-3">
          <div class="col-7">
            <h4>Automated Task <em>Facility Schedule Emails (Athena)</em></h4>
          </div>
          <div class="col-5" *ngIf="!loading">
            <h4>Task Notes</h4>
          </div>
        </div>
      </ng-template>
    </li>
    <li ngbNavItem="4" class="ms-4">
      <a ngbNavLink (click)="loadTaskTab(TaskTypes.AthenaFollowupAppointments)">Athena Followup Appointments</a>
      <ng-template ngbNavContent>
        <div class="row mt-3">
          <div class="col-7">
            <h4>Automated Task <em>Athena Followup Appointments</em></h4>
          </div>
          <div class="col-5" *ngIf="!loading">
            <h4>Task Notes</h4>
          </div>
        </div>
      </ng-template>
    </li>
    <li ngbNavItem="7" class="ms-4" *ngIf="showSingleRunTask">
      <a ngbNavLink (click)="loadTaskTab(TaskTypes.SingleRunTask)">Single Run</a>
      <ng-template ngbNavContent>
        <div class="row mt-3">
          <div class="col-7">
            <h4>Automated Task <em>Single Run</em></h4>
          </div>
          <div class="col-5" *ngIf="!loading">
            <h4>Task Notes</h4>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="tabs"></div>
  <div class="row">
    <div class="col-7">
      <table class="table w-100 table-striped">
        <thead class="table-light">
        <tr>
          <th colspan="5" class="p-0 m-0 h-auto">
            <div class="row m-0">
              <div class="col-1 p-0">
                <button class="btn btn-sm btn-secondary"
                        (click)="searchDrop.close(); previous()"
                        [disabled]="loading || disablePrevious()">
                  <i class="fas fa-arrow-left"></i>
                </button>
              </div>
              <div class="col-10">
                <div class="d-inline-block ms-3" ngbDropdown #searchDrop="ngbDropdown" [autoClose]="false"
                     placement="bottom-left">
                  <button class="btn btn-secondary btn-sm"
                          placement="bottom"
                          ngbDropdownToggle
                          (click)="startSearch(searchDrop)"
                          [disabled]="searchDrop.isOpen()"
                          popoverTitle="Filter Results">
                    <i class="fas fa-search"></i>
                  </button>
                  <div ngbDropdownMenu class="p-2" style="width: 40em;">
                    <form #searchForm="ngForm"
                          functionValidator
                          [func]="validateDateRanges">
                      <div class="clearfix text-center">
                        <button type="button"
                                (click)="clearSearch(); searchDrop.close(); search();"
                                class="btn btn-sm btn-outline-info float-start">
                          <i class="fas fa-eraser"></i>
                        </button>
                        <span>{{getSearchDescription(searchModel)}}</span>
                        <button type="button" class="btn-close float-end"
                                (click)="searchDrop.close()">
                        </button>
                      </div>
                      <h6>Filter</h6>
                      <div class="row">
                        <div class="col-4">
                          <label class="small form-label m-0 d-block">Task Started</label>
                          <div class="btn-group d-block">
                            <label class="btn btn-sm form-label btn-outline-secondary"
                                   [class.active]="searchModel.dateRangeOption === DateRangeOptions.before">
                              <input type="radio" class="d-none" name="dateRangeOptions"
                                     [(ngModel)]="searchModel.dateRangeOption"
                                     [value]="DateRangeOptions.before"/>
                              Before
                            </label>
                            <label class="btn btn-sm form-label btn-outline-secondary"
                                   [class.active]="searchModel.dateRangeOption === DateRangeOptions.after">
                              <input type="radio" class="d-none" name="dateRangeOptions"
                                     [(ngModel)]="searchModel.dateRangeOption"
                                     [value]="DateRangeOptions.after"/>
                              After
                            </label>
                            <label class="btn btn-sm form-label btn-outline-secondary"
                                   [class.active]="searchModel.dateRangeOption === DateRangeOptions.between">
                              <input type="radio" class="d-none" name="dateRangeOptions"
                                     [(ngModel)]="searchModel.dateRangeOption"
                                     [value]="DateRangeOptions.between"/>
                              Between
                            </label>
                          </div>
                        </div>
                        <div class="col-8">
                          <div
                            *ngIf="searchModel.dateRangeOption === DateRangeOptions.before || searchModel.dateRangeOption === DateRangeOptions.after"
                            class="row">
                            <div class="col-6">
                              <label class="small form-label m-0 d-block">
                                <span *ngIf="searchModel.dateRangeOption === DateRangeOptions.before">Before</span>
                                <span *ngIf="searchModel.dateRangeOption === DateRangeOptions.after">After</span>
                              </label>
                              <div class="input-group d-flex flex-nowrap">
                                <input class="form-control form-control-sm" placeholder="yyyy-mm-dd"
                                       name="dateRangeSingle"
                                       [(ngModel)]="searchModel.dateRangeSingle"
                                       required
                                       ngbDatepicker outsideDays="hidden"
                                       #singleDate="ngbDatepicker">
                                <div>
                                  <button class="btn btn-outline-secondary btn-sm"
                                          (click)="singleDate.toggle()"
                                          type="button">
                                    <i class="fas fa-calendar-alt"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="searchModel.dateRangeOption === DateRangeOptions.between">
                            <div class="row">
                              <div class="col-6">
                                <label class="small form-label m-0 d-block">From</label>
                                <div class="input-group d-flex flex-nowrap">
                                  <input class="form-control form-control-sm" placeholder="yyyy-mm-dd"
                                         name="dateRangeStart"
                                         [(ngModel)]="searchModel.dateRangeStart"
                                         required
                                         ngbDatepicker outsideDays="hidden"
                                         #startDate="ngbDatepicker">

                                  <div>
                                    <button class="btn btn-outline-secondary btn-sm"
                                            (click)=" endDate.close(); startDate.toggle();"
                                            type="button">
                                      <i class="fas fa-calendar-alt"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div class="col-6">
                                <label class="small form-label m-0 d-block">To</label>
                                <div class="input-group d-flex flex-nowrap">
                                  <input class="form-control form-control-sm" placeholder="yyyy-mm-dd"
                                         name="dateRangeEnd"
                                         [(ngModel)]="searchModel.dateRangeEnd"
                                         required
                                         ngbDatepicker outsideDays="hidden"
                                         #endDate="ngbDatepicker">
                                  <div>
                                    <button class="btn btn-outline-secondary btn-sm"
                                            (click)=" startDate.close(); endDate.toggle();"
                                            type="button">
                                      <i class="fas fa-calendar-alt"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="searchForm.errors?.['invalidDateRange']"
                                 class="position-relative">
                              <div class="position-absolute text-danger font-italic small">Invalid Date Range</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <label class="small form-label m-0 d-block">Status</label>
                      <div class="btn-group d-block">
                        <label class="btn btn-sm form-label btn-outline-secondary"
                               [class.active]="searchModel.statusOption === TaskStatusTypes.Complete">
                          <input type="radio" class="d-none" name="statusOptions"
                                 [(ngModel)]="searchModel.statusOption"
                                 [value]="TaskStatusTypes.Complete"/>
                          Complete
                        </label>
                        <label class="btn btn-sm form-label btn-outline-secondary"
                               [class.active]="searchModel.statusOption === TaskStatusTypes.Error">
                          <input type="radio" class="d-none" name="statusOptions"
                                 [(ngModel)]="searchModel.statusOption"
                                 [value]="TaskStatusTypes.Error"/>
                          Error
                        </label>
                      </div>
                      <div *ngIf="currentTask === TaskTypes.AthenaFollowupAppointments">
                        <hr/>
                        <h6><em>Metadata Search</em></h6>
                        <div class="btn-group d-block">
                          <label class="btn btn-sm form-label btn-outline-secondary d-block"
                                 ngbTooltip="Show only task log entries that have at least one appointment that was skipped due to no slots being available"
                                 [class.active]="searchModel.metaField === AthenaFollowupMetaFieldOptions.hasSkippedNoSlotsAvailable">
                            <input type="radio" class="d-none" name="athenaFollowupMetadataOptions"
                                   [(ngModel)]="searchModel.metaField"
                                   [value]="AthenaFollowupMetaFieldOptions.hasSkippedNoSlotsAvailable"/>
                            Has "No Slots Available" Unprocessed Appointments
                          </label>
                          <label class="btn btn-sm form-label btn-outline-secondary d-block"
                                 ngbTooltip="Show only task log entries that have at least one processed appointment"
                                 [class.active]="searchModel.metaField === AthenaFollowupMetaFieldOptions.hasProcessedAppointments">
                            <input type="radio" class="d-none" name="athenaFollowupMetadataOptions"
                                   [(ngModel)]="searchModel.metaField"
                                   [value]="AthenaFollowupMetaFieldOptions.hasProcessedAppointments"/>
                            Has Processed Appointments
                          </label>
                          <label class="btn btn-sm form-label btn-outline-secondary d-block"
                                 ngbTooltip="Show only task log entries that have metadata pertaining to Athena Events Re-subscription"
                                 [class.active]="searchModel.metaField === AthenaFollowupMetaFieldOptions.hasSubscription">
                            <input type="radio" class="d-none" name="athenaFollowupMetadataOptions"
                                   [(ngModel)]="searchModel.metaField"
                                   [value]="AthenaFollowupMetaFieldOptions.hasSubscription"/>
                            Has Subscription Metadata
                          </label>
                        </div>
                      </div>
                      <div *ngIf="currentTask === TaskTypes.SalesforceClaimGeneration">
                        <hr/>
                        <h6><em>Metadata Search</em></h6>
                        <div class="btn-group d-block">
                          <label class="btn btn-sm form-label btn-outline-secondary d-block"
                                 ngbTooltip="Show only task log entries that have at least one successfully generated Claim"
                                 [class.active]="searchModel.metaField === SalesforceClaimMetaFieldOptions.hasClaimsGenerated">
                            <input type="radio" class="d-none" name="salesforceClaimMetadataOptions"
                                   [(ngModel)]="searchModel.metaField"
                                   [value]="SalesforceClaimMetaFieldOptions.hasClaimsGenerated"/>
                            Has Claims Generated
                          </label>
                          <label class="btn btn-sm form-label btn-outline-secondary d-block"
                                 ngbTooltip="Show only task log entries that have at least one error message"
                                 [class.active]="searchModel.metaField === SalesforceClaimMetaFieldOptions.hasErrors">
                            <input type="radio" class="d-none" name="salesforceClaimMetadataOptions"
                                   [(ngModel)]="searchModel.metaField"
                                   [value]="SalesforceClaimMetaFieldOptions.hasErrors"/>
                            Has Sync Errors
                          </label>
                        </div>
                      </div>
                      <div *ngIf="currentTask === TaskTypes.SalesforcePatientVerification">
                        <hr/>
                        <h6><em>Metadata Search</em></h6>
                        <div class="btn-group d-block">
                          <label class="btn btn-sm form-label btn-outline-secondary d-block"
                                 ngbTooltip="Show only task log entries that have at least one successfully verified Patient"
                                 [class.active]="searchModel.metaField === SalesforcePatientVerificationMetaFieldOptions.hasVerified">
                            <input type="radio" class="d-none" name="salesforcePatientMetadataOptions"
                                   [(ngModel)]="searchModel.metaField"
                                   [value]="SalesforcePatientVerificationMetaFieldOptions.hasVerified"/>
                            Has Verified Patients
                          </label>
                          <label class="btn btn-sm form-label btn-outline-secondary d-block"
                                 ngbTooltip="Show only task log entries that have at least one error message"
                                 [class.active]="searchModel.metaField === SalesforcePatientVerificationMetaFieldOptions.hasErrors">
                            <input type="radio" class="d-none" name="salesforcePatientMetadataOptions"
                                   [(ngModel)]="searchModel.metaField"
                                   [value]="SalesforcePatientVerificationMetaFieldOptions.hasErrors"/>
                            Has Sync Errors
                          </label>
                        </div>
                      </div>
                      <div *ngIf="currentTask === TaskTypes.AthenaSync">
                        <hr/>
                        <h6><em>Metadata Search</em></h6>
                        <div class="btn-group d-block">
                          <label class="btn btn-sm form-label btn-outline-secondary d-block"
                                 ngbTooltip="Show only Athena Synchronizations that resulted in new providers being added to the Portal System"
                                 [class.active]="searchModel.metaField === AthenaSyncMetaFieldOptions.hasNewProviders">
                            <input type="radio" class="d-none" name="athenaSyncMetadataOptions"
                                   [(ngModel)]="searchModel.metaField"
                                   [value]="AthenaSyncMetaFieldOptions.hasNewProviders"/>
                            Has New Providers
                          </label>
                        </div>
                        <div class="btn-group d-block">
                          <label class="btn btn-sm form-label btn-outline-secondary d-block"
                                 ngbTooltip="Show only Athena Synchronizations that resulted in providers being deleted from the Portal System"
                                 [class.active]="searchModel.metaField === AthenaSyncMetaFieldOptions.hasDeletedProviders">
                            <input type="radio" class="d-none" name="athenaSyncMetadataOptions"
                                   [(ngModel)]="searchModel.metaField"
                                   [value]="AthenaSyncMetaFieldOptions.hasDeletedProviders"/>
                            Has Deleted Providers
                          </label>
                        </div>
                        <div class="btn-group d-block">
                          <label class="btn btn-sm form-label btn-outline-secondary d-block"
                                 ngbTooltip="Show only Athena Synchronizations that resulted in new facilities being added to the Portal System"
                                 [class.active]="searchModel.metaField === AthenaSyncMetaFieldOptions.hasNewDepartments">
                            <input type="radio" class="d-none" name="athenaSyncMetadataOptions"
                                   [(ngModel)]="searchModel.metaField"
                                   [value]="AthenaSyncMetaFieldOptions.hasNewDepartments"/>
                            Has New Facilities
                          </label>
                        </div>
                        <div class="btn-group d-block">
                          <label class="btn btn-sm form-label btn-outline-secondary d-block"
                                 ngbTooltip="Show only Athena Synchronizations that resulted in facilities being deleted from the Portal System"
                                 [class.active]="searchModel.metaField === AthenaSyncMetaFieldOptions.hasDeletedDepartments">
                            <input type="radio" class="d-none" name="athenaSyncMetadataOptions"
                                   [(ngModel)]="searchModel.metaField"
                                   [value]="AthenaSyncMetaFieldOptions.hasDeletedDepartments"/>
                            Has Deleted Facilities
                          </label>
                        </div>
                      </div>
                      <div class="text-center">
                        <button class="btn btn-primary"
                                [disabled]="searchForm.pristine || !searchForm.valid"
                                (click)="searchDrop.close(); updateSearch()">
                          <i class="fas fa-search me-2"></i>
                          Update Results
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div
                  class="d-inline-block ms-5">{{getSearchDescription(lastSearchModel, currentLogs?.length || 20)}}</div>
              </div>
              <div class="col-1 p-0 text-end">
                <button class="btn btn-sm btn-secondary"
                        (click)="searchDrop.close(); next()"
                        [disabled]="loading || disableNext()">
                  <i class="fas fa-arrow-right"></i>
                </button>
              </div>
            </div>
          </th>
        </tr>
        <tr *ngIf="!loading">
          <th scope="col" class="align-middle">Start Time</th>
          <th scope="col" class="align-middle">End Time</th>
          <th scope="col" class="align-middle">Status</th>
          <th scope="col" class="align-middle">Triggered By</th>
          <th></th>
        </tr>
        </thead>
        <tbody *ngIf="!loading && currentLogs && currentLogs.length > 0">
        <tr *ngFor="let log of currentLogs" [class.table-primary]="log.log_id === currentDetail?.log_id">
          <td>{{log.start_time | date:"M/d/yy h:mm:ss a"}}</td>
          <td>{{log.end_time | date:"M/d/yy h:mm:ss a"}}</td>
          <td>{{log.status}}</td>
          <td>
            <div *ngIf="log?.trigger_by?.user_id">
              {{log.trigger_by.user_name}}
              ({{log.trigger_by.user_id}})
            </div>
            <div *ngIf="!log?.trigger_by?.user_id">{{log.trigger_by}}</div>
          </td>
          <td>
            <button *ngIf="log.error_data || log.meta_data"
                    class="btn btn-link" [disabled]="loading" (click)="currentDetail = log">
              details
            </button>
          </td>
        </tr>
        </tbody>
      </table>
      <portals-loading-icon *ngIf="loading"></portals-loading-icon>
    </div>
    <div class="col-5" *ngIf="!loading">
      <div class="alert alert-info mb-4">
        <div *ngIf="currentTask === TaskTypes.AthenaSync">
          <p>
            This automated task pulls important synchronization information about Providers, Facilities/Departments,
            and the mappings between them, from Athena and into the Forefront Portals system.
          </p>
          <p>
            In the production environment, this task is configured to execute every morning at 4am Eastern.
          </p>
        </div>
        <div *ngIf="currentTask === TaskTypes.SalesforceClaimGeneration">
          <p>
            This automated task pulls completed ED Med surge "Consults" and Hospital "Daily Round" records from
            Salesforce and generates claims based on them in Athena.
          </p>
          <p>
            In the production environment, this task is configured to execute every 2 minutes.
          </p>
        </div>
        <div *ngIf="currentTask === TaskTypes.SalesforcePatientVerification">
          <p>
            This automated task scans new patients in Salesforce ED Med Surge and Hospital Daily Round records and
            verifies the accuracy of the patient's Athena Enterprise ID (EID) that was entered into Salesforce.
          </p>
          <p>
            In the production environment, this task is configured to execute every 2 minutes.
          </p>
        </div>
        <div *ngIf="currentTask === TaskTypes.AthenaFollowupAppointments">
          <p>
            This automated task checks for recently checked out appointments in Athena and automatically schedules a
            followup appointment for the Patient. Followup frequency is determined by Facility/Department, and only
            Facilities departments explicitly registered to participate in followup appointment workflows will receive
            automated followupappointment scheduling features. See the Clients/Facilities tab in Admin portal to set up
            Client Facility/Departments for automated followup.
          </p>
          <p></p>
          <p>
            In the production environment, this task is configured to execute every 2 minutes.
          </p>
        </div>
        <div *ngIf="currentTask === TaskTypes.FacilityScheduleReminder">
          <p>
            This automated task generates reminder emails for Facilities/Departments that have been migrated to Athena,
            generating draft emails for contacts of Facilities/Departments in the Facility Scheduler email Exchange
            account.
          </p>
          <p>
            In the production environment, this task is configured to execute every morning at 4am Eastern
          </p>
        </div>
        <div *ngIf="currentTask === TaskTypes.SingleRunTask">
          <h4 class="bg-danger text-white p-2">
            DO NOT USE THIS TASK.
          </h4>
          <p>
            Normally, this task tab doesn't even show up in Admin Portal. If it's here, a special deployment
            was made to execute a single-run task in production. If this tab is here, ignore it, and seriously, don't
            press that button!

            If this tab isn't here, then you never saw this anyway.
          </p>
        </div>
      </div>
      <div class="m-4">
        You can manually force this task to execute right now by clicking the button below. Please note that the task
        executes asynchronously, and <em>a log entry is not generated until the task completes or errors</em>, so you
        may have to refresh this page before you will see a log entry for the execution you started.
      </div>
      <div class="ms-5" *ngIf="averageTaskTime">
        Based on your current search results, this task takes an average of
        <span class="stats">{{averageTaskTime}}</span>
        to complete, but could take as long as
        <span class="stats">{{longestTaskTime}}</span>. Please consider waiting at least that long before checking for a
        manually triggered task result.
      </div>
      <div class="m-4 d-inline-block"
           ngbTooltip="You recently started this task, so there's no need to start it again. It's probably just executing in the background. If you disagree, refresh the page."
           [disableTooltip]="!disableStartTask">
        <button class="btn btn-primary"
                (click)="startTaskManually()"
                [disabled]="loading || disableStartTask">
          Manually Start Task
        </button>
      </div>
      <div *ngIf="currentDetail">
        <h5>Details</h5>
        <ul ngbNav #detailNav="ngbNav" class="nav-tabs">
          <li *ngIf="currentDetail.meta_data"
              ngbNavItem="meta" class="ms-4">
            <a ngbNavLink>Metadata</a>
            <ng-template ngbNavContent>
              <i class="fas fa-copy ms-3 cursor-pointer"
                 (click)="copyErrorData(currentDetail.meta_data, 'Metadata')"></i>
              <ngx-json-viewer [json]="currentDetail.meta_data" [depth]="2"></ngx-json-viewer>
              <!--              <pre class="user-select-text">{{stringify(currentDetail.meta_data)}}</pre>-->
            </ng-template>
          </li>
          <li *ngIf="currentDetail.error_data"
              ngbNavItem="errors" class="ms-4">
            <a ngbNavLink>Error Data</a>
            <ng-template ngbNavContent>
              <i class="fas fa-copy ms-3 cursor-pointer"
                 (click)="copyErrorData(currentDetail.error_data)"></i>
              <ngx-json-viewer [json]="currentDetail.error_data" [depth]="2"></ngx-json-viewer>
              <!--              <pre class="user-select-text">{{stringify(currentDetail.error_data)}}</pre>-->
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="detailNav"></div>
      </div>
    </div>
  </div>
</div>

