import {CUSTOM_ELEMENTS_SCHEMA, InjectionToken, NgModule, Provider} from '@angular/core';
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {UserBadgeComponent} from "./components/user-badge/user-badge.component";
import {SharedStylesComponent} from "./components/shared-styles/shared-styles.component";
import {ThemeSwitcherService} from "./services/theme-switcher.service";
import {LoginSplashRouteComponent} from "./routes/login-splash-route/login-splash-route.component";
import {LoadingIconComponent} from './components/loading-icon/loading-icon.component';
import {LoginContinueRouteComponent} from "./routes/login-continue-route/login-continue-route.component";
import {UnauthorizedRouteComponent} from './routes/unauthorized-route/unauthorized-route.component';
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {PsygnalHttpInterceptorService} from "./services/psygnal-http-interceptor.service";
import {PortalLibConfig, PortalLibConfigToken} from "./PortalLibConfig";
import {FilterFuncPipe} from "./pipes/filter-func.pipe";
import {MarkResultPipe} from "./pipes/mark-result.pipe";
import {SortPipe} from "./pipes/sort.pipe";
import {TimezoneIconComponent} from './components/timezone-icon/timezone-icon.component';
import {VerifiedIconComponent} from './components/verified-icon/verified-icon.component';
import {PccIconComponent} from './components/pcc-icon/pcc-icon.component';
import {PasswordIconComponent} from './components/password-icon/password-icon.component';
import {MicrosoftIconComponent} from './components/microsoft-icon/microsoft-icon.component';
import {NavbarProfileMenuComponent} from './components/navbar-profile-menu/navbar-profile-menu.component';
import {NavbarProfileItemComponent} from './components/navbar-profile-item/navbar-profile-item.component';
import {NavbarProfileComponent} from './components/navbar-profile/navbar-profile.component';
import {ForefrontIconComponent} from './components/forefront-icon/forefront-icon.component';
import {FunctionValidatorDirective} from './function-validator.directive';
import {AthenaIconComponent} from "./components/athena-icon/athena-icon.component";


@NgModule({
  declarations: [
    FilterFuncPipe,
    MarkResultPipe,
    SortPipe,
    UserBadgeComponent,
    SharedStylesComponent,
    LoginSplashRouteComponent,
    LoadingIconComponent,
    LoginContinueRouteComponent,
    UnauthorizedRouteComponent,
    TimezoneIconComponent,
    AthenaIconComponent,
    VerifiedIconComponent,
    PccIconComponent,
    PasswordIconComponent,
    MicrosoftIconComponent,
    NavbarProfileMenuComponent,
    NavbarProfileItemComponent,
    NavbarProfileComponent,
    ForefrontIconComponent,
    FunctionValidatorDirective
  ],
  imports: [
    NgbModule,
    FormsModule,
    BrowserModule
  ],
  exports: [
    FilterFuncPipe,
    MarkResultPipe,
    SortPipe,
    UserBadgeComponent,
    SharedStylesComponent,
    LoginSplashRouteComponent,
    LoadingIconComponent,
    UnauthorizedRouteComponent,
    LoginContinueRouteComponent,
    TimezoneIconComponent,
    AthenaIconComponent,
    VerifiedIconComponent,
    PccIconComponent,
    PasswordIconComponent,
    MicrosoftIconComponent,
    NavbarProfileMenuComponent,
    NavbarProfileItemComponent,
    NavbarProfileComponent,
    ForefrontIconComponent,
    FunctionValidatorDirective
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PortalLibModule {
  // injecting ThemeSwitcherService here enables the service to execute its constructor logic
  // early in the bootstrapping cycle, hooking the header earlier
  constructor(private themeSwitcher: ThemeSwitcherService) {
  }
}

export function ProvidePortalLibConfig(config: PortalLibConfig): Provider {
  return {
    provide: PortalLibConfigToken,
    useValue: config
  };
}

export function ProvidePsygnalHttpInjector() {
  return {
    provide: HTTP_INTERCEPTORS,
    useClass: PsygnalHttpInterceptorService,
    multi: true
  };
}
