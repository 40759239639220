import {Inject, Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {PortalLibConfig, PortalLibConfigToken} from "../PortalLibConfig";
import {AuthService} from "./auth.service";


function GetUrlHostWithPort(parsedUrl: URL) {
  if(parsedUrl.port) {
    return `${parsedUrl.hostname}:${parsedUrl.port}`;
  }
  return parsedUrl.hostname;
}


@Injectable({
  providedIn: 'root'
})
export class PsygnalHttpInterceptorService implements HttpInterceptor {
  private readonly hostExceptionsMap: Map<string, string[]>;

  constructor(@Inject(PortalLibConfigToken) private readonly config: PortalLibConfig,
              private readonly auth: AuthService) {
    const map = new Map<string, string[]>();
    if (config.httpIntercept && config.httpIntercept.length > 0) {
      config.httpIntercept.forEach(i => map.set(i.interceptHost, i.exceptPaths));
    }
    this.hostExceptionsMap = map;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.shouldAddAuthHeader(req)) {
      const token = this.auth.getAccessToken();
      const newRequest = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
      return next.handle(newRequest);
    } else {
      return next.handle(req);
    }
  }

  private shouldAddAuthHeader(req: HttpRequest<any>): boolean {
    // don't add auth headers if the token doesn't exist or is expired.
    // the call to isAuthenticated() also checks expiration.
    if (!this.auth.isAuthenticated()) {
      return false;
    }

    const reqUrl = new URL(req.url);
    const reqHost = GetUrlHostWithPort(reqUrl);

    if (!this.hostExceptionsMap.has(reqHost)) {
      return false;
    }
    const exceptPaths = this.hostExceptionsMap.get(reqHost);
    if (exceptPaths && exceptPaths.length > 0) {
      return !exceptPaths.includes(reqUrl.pathname);
    }
    return true;
  }
}
