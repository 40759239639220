import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {AuthService} from "./auth.service";
import {PortalLibConfig, PortalLibConfigToken} from "../PortalLibConfig";

@Injectable({
  providedIn: 'root'
})
export class RouteAuthorizationService implements CanActivate {
  constructor(@Inject(PortalLibConfigToken) private config: PortalLibConfig,
              private auth: AuthService,
              private router: Router) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    // if no data specified, allow anonymous/unauthenticated
    if (!route.data) {
      return true;
    }
    if (!route.data.routeAuthorization) {
      return true;
    }
    const requiredPermissions = route.data.routeAuthorization.permissions as [];
    const requireAll = !!route.data.routeAuthorization.requireAll;

    if (!requiredPermissions) {
      return true;
    }
    if (!(requiredPermissions instanceof Array)) {
      return true;
    }

    // a user is authenticated if we have a token for them to use that is not expired.
    if (!this.auth.isAuthenticated()) {
      this.redirect(this.config.portal.loginRoute);
      return false;
    }

    //  if no permissions are required, the route only needs authentication to activate
    if (requiredPermissions.length === 0) {
      return true;
    }

    if(requireAll) {
      return this.requireAll(requiredPermissions);
    }
    return this.requireAny(requiredPermissions);

  }

  private requireAny(permisions: []): boolean {
    for (const perm of permisions) {
      if (this.auth.hasPermission(perm)) {
        return true;
      }
    }

    // if we get through the loop, user has no permissions
    this.redirect(this.config.portal.unauthorizedRoute);
    return false;
  }

  private requireAll(permisions: []): boolean {
    for (const perm of permisions) {
      if (!this.auth.hasPermission(perm)) {
        this.redirect(this.config.portal.unauthorizedRoute);
        return false;
      }
    }
    // if we get through the loop, user has all permissions
    return true;

  }


  private redirect(route: string, params = null) {
    setTimeout(() => {
      this.router.navigate([route]);
    }, 10);
  }
}
