import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from "./routes/dashboard/dashboard.component";
import {RoutePaths, RoutingPaths} from "./routes/RoutePaths";
import {ApiPermissions} from "#psygnal-model/shared/ApiPermissions";
import {UsersComponent} from "./routes/users/users.component";
import {AppointmentVideoMonitoringRouteComponent} from "./routes/appointment-video-monitoring-route/appointment-video-monitoring-route.component";
import {LoginContinueRouteComponent, LoginSplashRouteComponent, UnauthorizedRouteComponent} from "portal-lib";
import {RouteAuthorizationService} from "portal-lib";
import {ClientsComponent} from "./routes/clients/clients.component";
import {ProvidersComponent} from "./routes/providers/providers.component";
import {PortalContentManagementComponent} from "./routes/portal-content-management/portal-content-management.component";
import {TaskLogsComponent} from "./routes/task-logs/task-logs.component";


const routes: Routes = [
  // Root
  {
    path: RoutePaths.Dashboard,
    component: DashboardComponent,
    canActivate: [RouteAuthorizationService],
    data: {
      routeAuthorization: {
        requireAll: false,
        permissions: [
          ApiPermissions.AccessAdminPortal
        ]
      }
    }
  },
  // Auth
  {
    path: RoutePaths.Login,
    component: LoginSplashRouteComponent,
    canActivate: [RouteAuthorizationService],
    // allow anonymous by not including routeAuthorization data
  },
  {
    path: RoutePaths.LoginContinue,
    component: LoginContinueRouteComponent,
    canActivate: [RouteAuthorizationService]
    // allow anonymous by not including routeAuthorization data
  },
  {
    path: RoutePaths.Unauthorized,
    component: UnauthorizedRouteComponent,
    canActivate: [RouteAuthorizationService],
    data: {
      routeAuthorization: {
        requireAll: false,
        permissions: [] // require authentication only
      }
    }
  },
  //region System Admin
  {
    path: RoutePaths.Users,
    component: UsersComponent,
    canActivate: [RouteAuthorizationService],
    data: {
      routeAuthorization: {
        requireAll: true,
        permissions: [
          ApiPermissions.AccessAdminPortal,
          ApiPermissions.AdminManageUsers
        ]
      }
    }
  },
  {
    path: RoutePaths.VideoMonitoring,
    component: AppointmentVideoMonitoringRouteComponent,
    canActivate: [RouteAuthorizationService],
    data: {
      routeAuthorization: {
        requireAll: true,
        permissions: [
          ApiPermissions.AccessAdminPortal,
          ApiPermissions.AdminMonitorVideo
        ]
      }
    }
  },
  //endregion
  //region Client Management
  {
    path: RoutePaths.Clients,
    component: ClientsComponent,
    canActivate: [RouteAuthorizationService],
    data: {
      routeAuthorization: {
        requireAll: true,
        permissions: [
          ApiPermissions.AccessAdminPortal,
          ApiPermissions.AdminManageClients,
        ]
      }
    }
  },
  {
    path: RoutePaths.Providers,
    component: ProvidersComponent,
    canActivate: [RouteAuthorizationService],
    data: {
      routeAuthorization: {
        requireAll: false,
        permissions: [
          ApiPermissions.AccessAdminPortal,
          ApiPermissions.AdminManageClients
        ]
      }
    }
  },
  //endregion
  //region Content Management
  {
    path: RoutePaths.ContentManagement,
    component: PortalContentManagementComponent,
    canActivate: [RouteAuthorizationService],
    data: {
      routeAuthorization: {
        requireAll: false,
        permissions: [
          ApiPermissions.AccessAdminPortal,
          ApiPermissions.AdminManagePortalContent,
        ]
      }
    }
  },
  //endregion
  //region Daily Tasks
  {
    path: RoutePaths.TaskLogs,
    component: TaskLogsComponent,
    canActivate: [RouteAuthorizationService],
    data: {
      routeAuthorization: {
        requireAll: true,
        permissions: [
          ApiPermissions.AccessAdminPortal,
          ApiPermissions.AdminManageDailyTasks,
        ]
      }
    }
  },
  //endregion
  {
    path: RoutePaths.Root,
    redirectTo: RoutePaths.Dashboard,
    pathMatch: "full"
  },
  {
    path: "**",
    redirectTo: RoutePaths.Dashboard,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
