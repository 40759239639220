import {AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {PortalToasterService} from "../../services/portal.toaster.service";
import {NgbActiveModal, NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {MyProfileRestService} from "../../services/my-profile-rest.service";
import {AuthService} from "../../services/auth.service";
import {PortalLibConfig, PortalLibConfigToken} from "../../PortalLibConfig";

/***** Imported from TimezoneLookups.ts *****/
const TimeZoneCodeToNameLookup = Object.freeze({
  "America/New_York": "Eastern",
  "America/Chicago": "Central",
  "America/Denver": "Mountain",
  "America/Phoenix": "Arizona Mountain (No DST)",
  "America/Los_Angeles": "Pacific",
  "America/Anchorage": "Alaska",
  "Pacific/Honolulu": "Hawaii",
});

const TimeZoneNameToCodeLookup = Object.freeze({
  "Eastern": "America/New_York",
  "Central": "America/Chicago",
  "Mountain": "America/Denver",
  "Arizona Mountain (No DST)": "America/Phoenix",
  "Pacific": "America/Los_Angeles",
  "Alaska": "America/Anchorage",
  "Hawaii": "Pacific/Honolulu",
});

const TimeZoneList = Object.freeze(Object.entries(TimeZoneCodeToNameLookup));

function MapTimezoneCodeToName(code: string): string {
  if (!code) {
    return null;
  }
  return (TimeZoneCodeToNameLookup as any)[code];
}

function MapTimezoneNameToCode(code: string): string {
  if (!code) {
    return null;
  }
  return (TimeZoneCodeToNameLookup as any)[code];
}

function waitFor(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

@Component({
  selector: 'navbar-profile',
  templateUrl: './navbar-profile.component.html',
  styleUrls: ['./navbar-profile.component.scss']
})
export class NavbarProfileComponent {

  userProfile: any;
  editPasswordValue: string;

  editPasswordModalRef: NgbModalRef;
  profileModalRef: NgbModalRef;
  editProfileModalRef: NgbModalRef;
  @ViewChild('editPasswordModal') editPasswordModal;
  @ViewChild('profileModal') profileModal;
  @ViewChild('editProfileModal') editProfileModal;
  @ViewChild('loadingModal') loadingModal;
  // @ViewChild('picture') picture: HTMLInputElement;

  TimeZoneList = TimeZoneList;
  MapTimezoneCodeToName = MapTimezoneCodeToName;

  constructor(
    @Inject(PortalLibConfigToken) private config: PortalLibConfig,
    private modalService: NgbModal,
    private rest: MyProfileRestService,
    private auth: AuthService,
    private toast: PortalToasterService) {
    this.userProfile = {};
  }

  async showProfile() {
    this.userProfile = {};
    const loading = this.openLoadingModal();
    try {
      this.userProfile = await this.rest.getProfile();
      loading.close();
      this.profileModalRef = this.modalService.open(this.profileModal, {
        backdrop: true
      });

    } catch (e) {
      console.log(e);
      this.toast.showError("Error Loading Profile Information.");
      loading.close();
    }
  }

  // User profile modal functions
  async showEditProfile() {
    this.profileModalRef?.close();
    this.profileModalRef = null;
    await waitFor(modalSwitchDelay);
    this.editProfileModalRef = this.modalService.open(this.editProfileModal, {
      backdrop: "static"
    });
  }

  showEditPassword() {
    this.editPasswordValue = null;
    this.editPasswordModalRef = this.modalService.open(this.editPasswordModal, {
      backdrop: "static"
    });
  }

  async saveUserProfile() {
    const loading = this.openLoadingModal();
    const request = {
      fields: [
        {
          fieldName: "name",
          value: this.userProfile.name
        },
        {
          fieldName: "timezone",
          value: this.userProfile.timezone
        },
        // {
        //   fieldName: "email",
        //   value: this.userProfile.email ?? null
        // },
        {
          fieldName: "phone",
          value: this.userProfile.phone ?? null
        },
        {
          fieldName: "picture",
          value: this.userProfile.picture ?? null
        },
      ]
    };
    try {
      const newProfile = await this.rest.patchProfile(request);
      this.userProfile = newProfile;
      this.auth.updateProfile(newProfile);
      this.toast.showSuccess("User Profile Updated.");
      loading.close();
      await this.cancelEditUserProfile();
    } catch (err) {
      console.log(err);
      this.toast.showError("Error Updating User Profile.");
      loading.close();
      await this.cancelEditUserProfile();
    }
  }

  async cancelEditUserProfile() {
    this.editProfileModalRef?.close();
    this.editProfileModalRef = null;
    await waitFor(modalSwitchDelay);
    await this.showProfile();
  }

  // Password modal function
  async savePassword() {
    const request = {
      password: this.editPasswordValue
    };
    const loading = this.openLoadingModal();
    try {
      await this.rest.updatePassword(request);
      this.toast.showSuccess("Forefront Portals Password Updated.");
      loading.close();
      this.editPasswordModalRef?.dismiss();
    } catch (err) {
      console.log(err);
      this.toast.showError("Error Updating Password.");
      loading.close();
      this.editPasswordModalRef?.dismiss();
    }
  }

  private openLoadingModal(): NgbModalRef {
    return this.modalService.open(this.loadingModal, {
      size: "sm",
      backdrop: "static"
    });
  }

  isPcc(): boolean {
    return this.userProfile?.credential_integrations?.includes("pcc");
  }

  isPassword(): boolean {
    return this.userProfile?.credential_integrations?.includes("password");
  }

  isO365(): boolean {
    return this.userProfile?.credential_integrations?.includes("o365");
  }

  handleFileSelect(fileEvent: Event, model: any) {
    const fileInput = fileEvent.target as HTMLInputElement;
    const files = fileInput.files;
    if (!files || files.length === 0) {
      return; // didn't receive an HTML Input change event, or there are no files selected
    }
    const file = files[0]; // ignore multiply selected files in favor of the first.
    if (!file) {
      return; // still don't have one? Skip to my lou
    }
    if (file.size > this.config.portal.profilePicMaxSize) {
      this.toast.showWarning("The file you select must be no larger than 55kb");
      model.picture = null;
      fileInput.value = null;
      return;
    }

    const mimeType = file.type;
    if (!mimeType.includes("image")) {
      this.toast.showWarning("The file you select must be an image");
      model.picture = null;
      fileInput.value = null;
      return;
    }
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      model.picture = reader.result as string; // call to readAsDataUrl should return string
    });
    reader.readAsDataURL(file);

  }

  clearProfilePicture(fileElement:HTMLInputElement) {
    this.userProfile.picture = null;
    fileElement.value = null;
  }
}

const modalSwitchDelay = 250;
