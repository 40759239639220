import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../services/auth.service";
import {PortalLibConfig, PortalLibConfigToken} from "../../PortalLibConfig";

@Component({
  selector: 'portals-login-continue-route',
  templateUrl: './login-continue-route.component.html',
  styleUrls: ['./login-continue-route.component.scss']
})
export class LoginContinueRouteComponent implements OnInit {

  hasCode = false;
  showError = false;
  dashboardRoute = ["/"];
  loginRoute = ["/login"]
  constructor(@Inject(PortalLibConfigToken) private config: PortalLibConfig,
              private route: ActivatedRoute,
              private router: Router,
              private auth: AuthService) {
  }

  async ngOnInit() {
    this.showError = false;
    this.hasCode = this.route.snapshot.queryParamMap.has("code");
    if(!this.hasCode) {
      return;
    }
    const code = this.route.snapshot.queryParamMap.get("code");
    try {
      await this.auth.downloadAccessToken(code);
      return this.router.navigate(this.dashboardRoute);
    }
    catch (e) {
      console.log("Error fetching token");
      console.log(e);
      this.showError = true;
    }
  }
}
