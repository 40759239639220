export enum RoutePaths {
  Root = "",
  Unauthorized = "unauthorized",
  Login = "login",
  LoginContinue = "login_continue",
  Dashboard = "dashboard",
  VideoMonitoring = "video-monitoring",
  Clients = "clients",
  Providers = "providers",
  ContentManagement = "content",
  TaskLogs = "task-logs",
  Users = "users",
}

export namespace RoutingPaths {
  export const Dashboard = [`/${RoutePaths.Dashboard}`];
  export const Unauthorized = [`/${RoutePaths.Unauthorized}`];
  export const Login = [`/${RoutePaths.Login}`];
  export const Users = [`/${RoutePaths.Users}`];
  export const VideoMonitoring = [`/${RoutePaths.VideoMonitoring}`];
  export const Clients = [`/${RoutePaths.Clients}`];
  export const TaskLogs = [`/${RoutePaths.TaskLogs}`];
  export const Providers = [`/${RoutePaths.Providers}`];
  export const ContentManagement = [`/${RoutePaths.ContentManagement}`];

}
