<div class="container-fluid">
  <h4 class="pt-3">Welcome to the Forefront Telecare Admin Portal!</h4>

  <ul class="list-group w-25">
    <li *ngIf="isClientManager()" class="list-group-item" [routerLinkActive]="['active']">
      <a class="list-group-item-action" [routerLink]="routes.Clients">Manage Athena Departments</a>
    </li>
    <li *ngIf="isClientManager() || isUserManager()" class="list-group-item" [routerLinkActive]="['active']">
      <a class="list-group-item-action" [routerLink]="routes.Providers">View Providers</a>
    </li>
    <li *ngIf="isUserManager()" class="list-group-item" [routerLinkActive]="['active']">
      <a class="list-group-item-action" [routerLink]="routes.Users">Manage Portal Users</a>
    </li>
    <li *ngIf="isZoomVideoMonitor()" class="list-group-item" [routerLinkActive]="['active']">
      <a class="list-group-item-action" [routerLink]="routes.VideoMonitoring">Monitor Appointment Video Connections</a>
    </li>
    <li *ngIf="isContentManager()" class="list-group-item" [routerLinkActive]="['active']">
      <a class="list-group-item-action" [routerLink]="routes.ContentManagement">Manage Portal Content</a>
    </li>
    <li *ngIf="isTaskManager()" class="list-group-item" [routerLinkActive]="['active']">
      <a class="list-group-item-action" [routerLink]="routes.TaskLogs">Task Logs</a>
    </li>
  </ul>
</div>
