import {
    BoolValidator,
    MaxLengthValidator, NumberValidator,
    RequiredValidator,
    String255Validator,
    StringValidator,
    Validator
} from "#psygnal-model/util/validation/Validator";

export interface CmsNewItemRequest {
    cms_key: string;
    body: string;
}

export interface CmsChangeVersionRequest {
    body: string;
}

export interface CmsSetVersionRequest {
    version_id: number;
}

export const MaxCmsBodySize = 1000000;

const bodyValidator = [StringValidator, new MaxLengthValidator(MaxCmsBodySize)];

const validator = new Map<string, Validator[]>();
validator.set("cms_key", [RequiredValidator, ...String255Validator]);
validator.set("body", bodyValidator);

export const CmsNewItemRequestValidator = validator;

const versionValidator = new Map<string, Validator[]>();
versionValidator.set("body", bodyValidator);

export const CmsChangeVersionRequestValidator = versionValidator;

const setVersionValidator = new Map<string, Validator[]>();
setVersionValidator.set("version_id", [RequiredValidator, NumberValidator]);

export const CmsSetVersionRequestValidator = setVersionValidator;
