import {EnvironmentConfig} from "./EnvironmentConfig";

export const environment: EnvironmentConfig = {
  production: false,
  portal: {
    name: "psygnal-admin",
    apiUrl: "https://psygnal-dev.fftcare.com",
    facilityEmailLoginUrl: "https://psygnal-dev.fftcare.com/auth/facility-email",
    credentialIntegrationUrl: "https://psygnal-auth-dev.fftcare.com/auth/credential_integration",
    profilePicMaxSize: 56320,
  },
  auth: {
    loginStartUrl: "https://psygnal-auth-dev.fftcare.com/auth/login",
    tokenUrl: "https://psygnal-dev.fftcare.com/auth/token",
    revokeUrl: "https://psygnal-dev.fftcare.com/auth/revoke",
    profileUrl: "https://psygnal-dev.fftcare.com/my/profile",
  },
  httpIntercept: [
    {
      interceptHost: "psygnal-dev.fftcare.com",
      exceptPaths: ["/auth/token"],
    }
  ]
};
