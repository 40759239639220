/**
 * Enumerates the types of credential integrations available to Psygnal
 */
export enum PortalAuthProviders {
    // NOTE: Due to database specifications, enumeration values must be 25 characters or less.
    PsygnalPassword="password",
    PointClickCare="pcc",
    Office365="o365",
    // Zoom="zoom",  // not yet
}
