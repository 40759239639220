<div class="container-fluid">
  <ul class="breadcrumb">
    <li class="breadcrumb-item active">Portal Users</li>
    <li class="breadcrumb-item">
      <portals-loading-icon *ngIf="loading || bgLoading"></portals-loading-icon>
    </li>
  </ul>
  <form #searchForm="ngForm">
    <button class="btn btn-primary float-end ms-3"
            (click)="handleAddUserClick()">
      <i class="fas fa-plus fa-fw me-2"></i>
      Add User
    </button>
    <fieldset [disabled]="loading">
      <div class="container-fluid mb-3">
        <div class="input-group flex-nowrap">
          <button class="btn btn-secondary dropdown-toggle"
                  ngbTooltip="Filter Results"
                  (click)="$event.stopPropagation(); searchDrop.open();">
            <i class="fas fa-sliders-h"></i>
          </button>
          <div ngbDropdown #searchDrop="ngbDropdown" [autoClose]="true"
               placement="bottom-left">
            <div ngbDropdownMenu class="p-2" style="width: 15em;">
              <div class="clearfix">
                <button type="button" class="btn btn-sm btn-outline-info float-start"
                        (click)="clearForm(clearBtn); searchDrop.close();"
                        #clearBtn>
                  <i class="fas fa-eraser"></i>
                </button>
                <button type="button" class="btn-close float-end" #clearBtn
                        (click)="searchDrop.close()">
                </button>
              </div>
              <h6>Sort</h6>
              <div>
                <label class="small form-label m-0" for="sortField">By Field</label>
                <select name="sortField"
                        class="form-select"
                        (change)="updateSearch()"
                        [(ngModel)]="searchData.sortField"
                        id="sortField">
                  <option value="name" selected>Name</option>
                </select>
              </div>
              <div>
                <label class="small form-label m-0" for="sortOrder">Direction</label>
                <select name="sortOrder"
                        class="form-select"
                        (change)="updateSearch()"
                        [(ngModel)]="searchData.sortOrder"
                        id="sortOrder">
                  <option value="asc" selected>Ascending</option>
                  <option value="desc">Descending</option>
                </select>
              </div>
              <h6>Filter</h6>
              <div ngbTooltip="Show only Users that have the selected credential type">
                <label class="small form-label m-0" for="credentialType">By Credential Type</label>
                <select name="credentialType"
                        class="form-select"
                        (change)="updateSearch()"
                        [(ngModel)]="searchData.credentialType"
                        id="credentialType">
                  <option value="" selected></option>
                  <option [value]="authProviders.Office365">Forefront Office 365</option>
<!--                  <option [value]="authProviders.PointClickCare">PointClickCare</option>-->
                  <option [value]="authProviders.PsygnalPassword">Portal Password</option>
                  <option value="--none--">No Credentials Linked</option>
                </select>
              </div>
              <div ngbTooltip="Show only Users that have the selected Role">
                <label class="small form-label m-0" for="otherRole">By Role</label>
                <select name="otherRole"
                        class="form-select"
                        (change)="updateSearch()"
                        [(ngModel)]="searchData.otherRole"
                        id="otherRole">
                  <option value="" selected></option>
                  <option *ngFor="let role of RoleNames.AdminPortalRoleNames" [value]="role.key">Admin
                    Portal: {{role.name}}</option>
<!--                  <option *ngFor="let role of RoleNames.ClientPortalRoleNames" [value]="role.key">Client-->
<!--                    Portal: {{role.name}}</option>-->
                  <option *ngFor="let role of RoleNames.ProviderPortalRoleNames" [value]="role.key">Provider
                    Portal: {{role.name}}</option>
                </select>
              </div>
<!--              <div ngbTooltip="Show only Users that are mapped to the selected Client">-->
<!--                <label class="small form-label m-0" for="clientId">By Mapped Client</label>-->
<!--                <ng-select bindLabel="name"-->
<!--                           class="me-2 w-100"-->
<!--                           name="clientId"-->
<!--                           id="clientId"-->
<!--                           (change)="updateSearch()"-->
<!--                           [(ngModel)]="searchData.clientId"-->
<!--                           bindValue="external_id"-->
<!--                           [items]="allClients"></ng-select>-->
<!--              </div>-->
              <div ngbTooltip="Show only Users that are mapped to the selected Provider">
                <label class="small form-label m-0" for="providerId">By Mapped Provider</label>
                <ng-select bindLabel="display_name"
                           class="me-2 w-100"
                           name="providerId"
                           id="providerId"
                           (change)="updateSearch()"
                           [(ngModel)]="searchData.providerId"
                           bindValue="external_id"
                           [items]="allProviders"></ng-select>
              </div>
            </div>
          </div>
          <input type="search" class="form-control" placeholder="Search by User Name"
                 #searchControl
                 (keydown)="updateSearch()"
                 (search)="updateSearch()"
                 [disabled]="loading"
                 [(ngModel)]="searchData.searchValue"
                 name="search">
          <button class="btn btn-secondary z-0"
                  (click)="updateSearch()"
                  [disabled]="loading">
            <i class="fas fa-search"></i>
          </button>
        </div>
      </div>
    </fieldset>
  </form>
  <div *ngIf="!loading">
    <div class="text-center" *ngIf="users && users.length === 0">
      <em>No Users Registered With System</em>
    </div>
  </div>
  <div class="d-flex flex-wrap justify-content-center">
    <div class="m-2 border border-primary user-panel"
         *ngFor="let user of users | sort:searchData.sortField:searchData.sortOrder | filterFunc:searchFilter:searchUpdated">
      <div class="text-center font-italic text-truncate fw-bold"
           [ngbTooltip]="user.name">
        <button class="btn btn-link shadow-none float-start p-0 m-0 ms-3" (click)="handleEditProfileClick(user)">
          <i class="fas fa-edit"></i>
        </button>
        <span [innerHTML]="user.name | markResult:searchRegexs"></span>
      </div>
      <div class="row ms-1 me-1">
        <div class="col-3 profile-picture">
          <img *ngIf="user.picture" alt="User Avatar"
               [src]="user.picture"/>
          <i *ngIf="!user.picture"
             class="fas fa-user-circle fa-fw text-black-50"></i>
        </div>
        <div class="col-9">
          <div class="row">
            <div class="col-2 small"
                 [class.text-primary]="!!user.timezone"
                 [class.text-black-50]="!user.timezone">
              <portals-timezone-icon></portals-timezone-icon>
            </div>
            <div class="col-10 text-truncate pt-1" placement="left" [ngbTooltip]="MapTimezoneCodeToName(user.timezone)">
              {{MapTimezoneCodeToName(user.timezone) || "(not set)"}}
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              <i class="fas fa-envelope fa-fw"
                 [class.text-primary]="!!user.email"
                 [class.text-black-50]="!user.email"></i>
            </div>
            <div class="col-10 text-truncate" placement="left" tooltipClass="portal-creds-tooltip"
                 [ngbTooltip]="user.email">
              {{user.email || "(not set)"}}
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              <i class="fas fa-mobile-alt fa-fw "
                 [class.text-primary]="!!user.phone"
                 [class.text-black-50]="!user.phone"></i>
            </div>
            <div class="col-10 text-truncate timezone">
              {{user.phone || "(not set)"}}
            </div>
          </div>
        </div>
      </div>
      <div class="ms-1 mb-2 row">
        <div class="col-5">
          <strong class="d-block">Portal Access</strong>
          <div class="ms-2">
            <button class="btn btn-link icon25 shadow-none m-0 p-0"
                    [class.text-primary]="userPortalAccess(user, ApiPermissions.AccessAdminPortal)"
                    [class.text-black-50]="!userPortalAccess(user, ApiPermissions.AccessAdminPortal)"
                    (click)="handleEditAdminPortalClick(user)"
                    [ngbTooltip]="adminPortalTip">
              <i class="fas fa-user-cog fa-fw icon25"></i>
            </button>
            <ng-template #adminPortalTip>
              <div class="text-nowrap">
                {{userPortalAccess(user, ApiPermissions.AccessAdminPortal) ? "Can" : "Cannot"}}
                Access Admin Portal
              </div>
            </ng-template>
            <button class="btn btn-link icon25 shadow-none m-0 p-0 ms-1"
                    [disabled]="bgLoading"
                    [class.text-primary]="userPortalAccess(user, ApiPermissions.AccessProviderPortal)"
                    [class.text-black-50]="!userPortalAccess(user, ApiPermissions.AccessProviderPortal)"
                    [class.text-warning]="userWarnProviderPortal(user)"
                    [ngbTooltip]="providerPortalTip"
                    tooltipClass="portal-creds-tooltip"
                    (click)="handleEditProviderPortalClick(user)">
              <i class="fas fa-user-md fa-fw icon25"></i>
            </button>
            <ng-template #providerPortalTip>
              <div class="text-nowrap">
                {{userPortalAccess(user, ApiPermissions.AccessProviderPortal) ? "Can" : "Cannot"}}
                Access Provider Portal
              </div>
              <div class="text-nowrap mt-2" *ngIf="userWarnProviderPortal(user)">
                <em>Warning: </em>
                User not linked to Forefront Provider
              </div>
            </ng-template>
<!--            <button class="btn btn-link icon25 shadow-none m-0 p-0 ms-1"-->
<!--                    [disabled]="bgLoading"-->
<!--                    [class.text-primary]="userPortalAccess(user, ApiPermissions.AccessClientPortal)"-->
<!--                    [class.text-black-50]="!userPortalAccess(user, ApiPermissions.AccessClientPortal)"-->
<!--                    [class.text-warning]="userWarnClientPortal(user)"-->
<!--                    (click)="handleEditClientPortalClick(user)"-->
<!--                    tooltipClass="portal-creds-tooltip"-->
<!--                    [ngbTooltip]="clientPortalTip">-->
<!--              <i class="fas fa-hospital-user fa-fw icon25"></i>-->
<!--            </button>-->
<!--            <ng-template #clientPortalTip>-->
<!--              <div class="text-nowrap">-->
<!--                {{userPortalAccess(user, ApiPermissions.AccessClientPortal) ? "Can" : "Cannot"}}-->
<!--                Access Client Portal-->
<!--              </div>-->
<!--              <div class="text-nowrap mt-2" *ngIf="userWarnClientPortal(user)">-->
<!--                <em>Warning: </em>-->
<!--                User not linked to Forefront Client-->
<!--              </div>-->
<!--            </ng-template>-->
          </div>
        </div>
        <div class="col-7">
          <strong class="d-block">Linked Credentials</strong>
          <div>
            <button class="btn btn-link ms-2 icon25 m-0 p-0 shadow-none"
                    [disabled]="bgLoading"
                    [class.text-black-50]="!user.ms_integration"
                    [class.inherit-color]="!user.ms_integration"
                    tooltipClass="portal-creds-tooltip"
                    [ngbTooltip]="msAccountTip"
                    (click)="handleAddEditMicrosoftCredentialsClick(user)">
              <portals-microsoft-icon></portals-microsoft-icon>
            </button>
            <ng-template #msAccountTip>
              <div class="text-nowrap">
                User
                <span *ngIf="!user.ms_integration">not</span>
                linked to Forefront Microsoft Account credentials.
              </div>
              <div class="mt-2" *ngIf="user.ms_integration">
                <table>
                  <tr>
                    <td class="text-nowrap text-end">Azure AD Object ID:</td>
                    <td class="text-nowrap text-start ps-2">{{user.ms_integration.integration_sub}}</td>
                  </tr>
                  <tr>
                    <td class="text-nowrap text-end">Linked On:</td>
                    <td class="text-nowrap text-start ps-2">{{user.ms_integration.created | date:'medium'}}</td>
                  </tr>
                </table>
              </div>
            </ng-template>
<!--            <button class="btn btn-link ms-2 icon25 m-0 p-0 shadow-none"-->
<!--                    tooltipClass="portal-creds-tooltip"-->
<!--                    [ngbTooltip]="pccAccountTip"-->
<!--                    [class.text-black-50]="!user.pcc_integration && !user.pcc_pending_integration"-->
<!--                    [class.inherit-color]="!user.pcc_integration && !user.pcc_pending_integration"-->
<!--                    (click)="addEditPccCredentials(user)">-->
<!--              <portals-pcc-icon></portals-pcc-icon>-->
<!--            </button>-->
<!--            <ng-template #pccAccountTip>-->
<!--              <div class="text-nowrap">-->
<!--                User-->
<!--                <span *ngIf="!user.pcc_integration && !user.pcc_pending_integration">not linked</span>-->
<!--                <span *ngIf="user.pcc_pending_integration && !user.pcc_integration">-->
<!--                  has pending link-->
<!--                </span>-->
<!--                <span *ngIf="user.pcc_integration">linked</span>-->
<!--                to PointClickCare-->
<!--                credentials.-->
<!--              </div>-->
<!--              <div class="mt-2" *ngIf="user.pcc_integration">-->
<!--                <table>-->
<!--                  <tr>-->
<!--                    <td class="text-nowrap text-end">PointClickCare User ID:</td>-->
<!--                    <td class="text-nowrap text-start ps-2">{{user.pcc_integration.integration_sub}}</td>-->
<!--                  </tr>-->
<!--                  <tr>-->
<!--                    <td class="text-nowrap text-end">Linked On:</td>-->
<!--                    <td class="text-nowrap text-start ps-2">{{user.pcc_integration.created | date:'medium'}}</td>-->
<!--                  </tr>-->
<!--                </table>-->
<!--              </div>-->
<!--            </ng-template>-->
            <button class="btn btn-link ms-2 icon25 m-0 p-0 text-primary shadow-none"
                    tooltipClass="portal-creds-tooltip"
                    [ngbTooltip]="pwAccountTip"
                    [class.text-primary]="!!user.pw_integration"
                    [class.text-black-50]="!user.pw_integration"
                    (click)="addEditPasswordCredentials(user)">
              <portals-password-icon></portals-password-icon>
            </button>
            <ng-template #pwAccountTip>
              <div class="text-nowrap">
                User
                <span *ngIf="!user.pw_integration">not</span>
                linked to Forefront Password credentials.
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- modals and sub-components -->
<!-- add/edit user modal -->
<ng-template #addEditUserModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      {{ userModel['isEdit'] ? "Edit" : "Add" }}
      User</h4>
    <button (click)="modal.dismiss()" class="btn btn-link"
            type="button">
      <i class="fas fa-lg fa-window-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <form #addUserForm="ngForm">
      <div class="row">
        <div class="col">
          <div>
            <label class="required form-label" for="name">Display Name *</label>
            <div class="input-group">
              <input [(ngModel)]="userModel.name" class="form-control" id="name"
                     name="name"
                     maxlength="255"
                     required
                     type="text">
            </div>
          </div>
          <div>
            <label class="required form-label" for="timezone">Timezone *</label>
            <div class="input-group">
              <select name="timezone"
                      required
                      class="form-select"
                      [(ngModel)]="userModel.timezone"
                      id="timezone">
                <option *ngFor="let entry of TimeZoneList"
                        [value]="entry[0]">{{entry[1]}}</option>
              </select>
            </div>
          </div>
          <div>
            <div>
              <label for="email">
                Email *
              </label>
              <i *ngIf="!!userModel.pw_integration"
                 ngbTooltip="Email field cannot be changed while the User is linked to a Forefront Password"
                 class="fas fa-exclamation-triangle float-end text-warning"></i>
            </div>
            <div class="input-group">
              <input [(ngModel)]="userModel.email" class="form-control" id="email"
                     maxlength="255"
                     name="email"
                     email
                     required
                     [disabled]="!!userModel.pw_integration"
                     type="email">
            </div>
          </div>
          <div>
            <label class="form-label" for="phone">Phone</label>
            <div class="input-group">
              <input [(ngModel)]="userModel.phone" class="form-control" id="phone"
                     maxlength="15"
                     name="phone"
                     type="tel">
            </div>
          </div>
        </div>
        <div class="col">
          <div>
            <label class="required form-label">Profile Picture</label>
            <div class="text-center edit-picture">
              <img *ngIf="userModel.picture"
                   alt="User Avatar"
                   [src]="userModel.picture">
              <i *ngIf="!userModel.picture"
                 class="fas fa-user-circle fa-5x p-0 text-secondary bg-white"></i>
              <label class="cursor-pointer form-label" for="picture">
                <span class="fa-stack edit-icon-overlay cursor-pointer">
                    <i class="fa fa-circle fa-stack-2x edit-icon-background"></i>
                    <i class="far fa-circle fa-stack-2x edit-icon-border"></i>
                    <i class="fa fa-camera fa-stack-1x"></i>
                </span>
                <input type="file" class="d-none" name="picture" id="picture" #picture
                       accept="image/*" data-type='image'
                       (change)="handleFileSelect($event, userModel)">
              </label>
              <div *ngIf="userModel.picture"
                   class="fa-stack clear-icon-overlay cursor-pointer"
                   (click)="handleClearProfilePictureClick(picture);">
                <i class="fa fa-circle fa-stack-2x edit-icon-background"></i>
                <i class="far fa-circle fa-stack-2x edit-icon-border"></i>
                <i class="fa fa-eraser fa-stack-1x"></i>
              </div>
            </div>
          </div>
          <div class="mt-4" *ngIf="!userModel.isEdit">
            <label>Initial Credentials</label>
            <div class="ms-1">
              <div class="form-check">
                <label class="form-check-label shadow-none">
                  <input class="form-check-input me-2" type="radio"
                         name="credentialSelection"
                         [(ngModel)]="userModel.credentialSelection"
                         [value]="authProviders.Office365">
                  <portals-microsoft-icon></portals-microsoft-icon>
                  Forefront Microsoft Account
                </label>
              </div>
<!--              <div class="form-check">-->
<!--                <label class="form-check-label shadow-none">-->
<!--                  <input class="form-check-input me-2" type="radio"-->
<!--                         name="credentialSelection"-->
<!--                         [(ngModel)]="userModel.credentialSelection"-->
<!--                         [value]="authProviders.PointClickCare">-->
<!--                  <portals-pcc-icon></portals-pcc-icon>-->
<!--                  PointClickCare-->
<!--                </label>-->
<!--              </div>-->
              <div class="form-check">
                <label class="form-check-label shadow-none">
                  <input class="form-check-input me-2" type="radio"
                         name="credentialSelection"
                         [(ngModel)]="userModel.credentialSelection"
                         [value]="authProviders.PsygnalPassword">
                  <portals-password-icon></portals-password-icon>
                  Forefront Portals Password
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!userModel.isEdit"
           style="height: 7em; border: 1px solid transparent;">
        <div *ngIf="userModel.credentialSelection === authProviders.PsygnalPassword"
             class="mt-3">
          <div>
            <label class="form-label" for="password">Initial Password</label>
            <div class="input-group">
              <input [(ngModel)]="userModel.password" class="form-control" id="password"
                     maxlength="255"
                     [required]="userModel.credentialSelection === authProviders.PsygnalPassword"
                     name="password"
                     minlength="6"
                     type="text">
            </div>
          </div>
        </div>
        <div *ngIf="userModel.credentialSelection === authProviders.Office365"
             class="mt-3">
          <div>
            <label class=" form-label" for="addUserMsAccount">Azure AD User (disabled items already mapped to a
              User)</label>
            <div class="input-group">
              <ng-select class="w-100"
                         name="addUserMsAccount"
                         id="addUserMsAccount"
                         [(ngModel)]="userModel.msAccountId"
                         bindValue="id"
                         functionValidator
                         [func]="validateMsUser">
                <ng-option *ngFor="let msAccount of msUsers"
                           [disabled]="usersByMsId.has(msAccount.id)"
                           [value]="msAccount.id">
                  {{msAccount.displayName}}
                  ( {{msAccount.userPrincipalName}} )
                </ng-option>
              </ng-select>
            </div>
          </div>
        </div>
<!--        <p *ngIf="userModel.credentialSelection === authProviders.PointClickCare">-->
<!--          <em>Note:</em> Until a server-only API context can be established with PointClickCare,-->
<!--          new PointClickCare Users must link their PointClickCare account with their Forefront Account.-->
<!--          If you select this option, after the account is created you will be presented with a dialog-->
<!--          containing a link that must be emailed to the PointClickCare user.-->
<!--        </p>-->
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button *ngIf="userModel['isEdit']"
            (click)="handleDeleteUserClick(userModel)"
            class="btn btn-danger me-auto" type="button">Delete User
    </button>
    <button (click)="modal.dismiss()"
            ngbAutofocus
            class="btn btn-outline-primary me-4" type="button">Cancel
    </button>
    <button (click)="modal.close('save')" [disabled]="!addUserForm.valid" class="btn btn-secondary" type="button">
      Save
    </button>
  </div>
</ng-template>
<!-- edit credential modal -->
<ng-template #editCredentialModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      User Credential Link
    </h4>
    <button (click)="modal.dismiss()" class="btn btn-link"
            type="button">
      <i class="fas fa-lg fa-window-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <ng-container [ngTemplateOutlet]="credentialModalInfo"
                  [ngTemplateOutletContext]="{cred: credentialModel, user: userModel}"></ng-container>
    <div *ngIf="credentialModel.integration_code">
      <p class="mt-2">Email this link to the user:</p>
      <div class="d-flex">
        <div>
          <button class="btn btn-link m-2" (click)="handleCopyPendingUrlClick(credentialModel.integration_code)">
            <i class="fas fa-fw fa-copy fa-2x"></i> <br>
            <small>(copy)</small>
          </button>
        </div>
        <textarea style="white-space: pre-wrap; width: 75%; height: 6em; resize: none;"
                  readonly (click)="handleCopyPendingUrlClick(credentialModel.integration_code)"
                  class="border p-1 pre" #pendingUrl>{{getPendingUrl(credentialModel.integration_code)}}</textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="handleDeleteCredentialClick(userModel, credentialModel)"
            class="btn btn-danger me-auto" type="button">Unlink User
    </button>
    <button *ngIf="credentialModel.integration_type === authProviders.PsygnalPassword"
            (click)="handleResetPasswordClick(userModel)"
            class="btn btn-outline-warning me-auto" type="button">Change Password
    </button>
    <button (click)="modal.dismiss()"
            ngbAutofocus
            class="btn btn-outline-primary" type="button">Close
    </button>
  </div>
</ng-template>
<!-- credential info modal -->
<ng-template #credentialModalInfo let-user="user" let-cred="cred">
  <div class="row m-1">
    <div class="col-3 text-end">
      User:
    </div>
    <div class="col font-italic">
      {{user.name}}
    </div>
  </div>
  <div class="row m-1 mt-3">
    <div class="col-3 text-end">
      Linked To:
    </div>
    <div class="col">
<!--      <span *ngIf="cred['integration_type'] === authProviders.PointClickCare">-->
<!--        <portals-pcc-icon></portals-pcc-icon>-->
<!--        PointClickCare-->
<!--      </span>-->
      <span *ngIf="cred['integration_type'] === authProviders.PsygnalPassword">
        <portals-password-icon class="text-primary"></portals-password-icon>
        Forefront Portal Password
      </span>
      <span *ngIf="cred['integration_type'] === authProviders.Office365">
        <portals-microsoft-icon></portals-microsoft-icon>
        Forefront Microsoft Account
      </span>
      <span *ngIf="cred['integration_code']">(Pending)</span>
    </div>
  </div>
  <div class="row m-1">
    <div class="col-3 text-end">
      User ID:
    </div>
    <div class="col font-italic text-truncate">
      {{cred['integration_sub']}}
    </div>
  </div>
  <div class="row m-1 mt-4">
    <div class="col-3 text-end">
      Created:
    </div>
    <div class="col">
      {{cred['created'] | date:"short"}}
    </div>
  </div>
</ng-template>
<!-- delete User Modal -->
<ng-template #deleteUserModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Delete User</h4>
    <button (click)="modal.dismiss()" class="btn btn-link"
            type="button">
      <i class="fas fa-lg fa-window-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <p>You are about to delete the following user</p>
    <p class="ms-2 font-italic">{{userModel.name}}</p>
    <p>Once you delete this user it cannot cannot be undone.</p>
    <p>Are you <em>certain</em> you want to delete this user?</p>
  </div>
  <div class="modal-footer">
    <button (click)="modal.dismiss()"
            ngbAutofocus
            class="btn btn-outline-primary me-auto" type="button">Cancel
    </button>
    <button (click)="modal.close('delete')"
            class="btn btn-danger" type="button">Delete User
    </button>
  </div>
</ng-template>
<!-- delete credential modal -->
<ng-template #deleteCredentialModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Unlink User Credential</h4>
    <button (click)="modal.dismiss()" class="btn btn-link"
            type="button">
      <i class="fas fa-lg fa-window-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <ng-container [ngTemplateOutlet]="credentialModalInfo"
                  [ngTemplateOutletContext]="{cred: credentialModel, user: userModel}"></ng-container>
    <p class="mt-3">
      Are you sure you want to unlink the User from this credential integration?
      They will no longer be able to log in with this method.
    </p>
  </div>
  <div class="modal-footer">
    <button (click)="modal.dismiss()"
            ngbAutofocus
            class="btn btn-outline-primary me-auto" type="button">Cancel
    </button>
    <button (click)="modal.close('delete')"
            class="btn btn-danger" type="button">Unlink User
    </button>
  </div>
</ng-template>
<!-- reset password modal modal -->
<ng-template #resetPasswordModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Change Portal Password</h4>
    <button (click)="modal.dismiss()" class="btn btn-link"
            type="button">
      <i class="fas fa-lg fa-window-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <ng-container [ngTemplateOutlet]="credentialModalInfo"
                  [ngTemplateOutletContext]="{cred: credentialModel, user: userModel}"></ng-container>

    <form #resetPasswordForm="ngForm">
      <div class="mt-3">
        <label class=" form-label" for="password">New Password</label>
        <div class="input-group">
          <input [(ngModel)]="userModel.password" class="form-control" id="change_password"
                 maxlength="255"
                 required
                 minlength="6"
                 name="change_password"
                 type="text">
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button (click)="modal.close('save')" [disabled]="!resetPasswordForm.valid" class="btn btn-secondary" type="button">
      Change Password
    </button>
    <button (click)="modal.dismiss()"
            ngbAutofocus
            class="btn btn-outline-primary" type="button">Cancel
    </button>
  </div>
</ng-template>
<!-- loading icon modal -->
<ng-template #loadingModal let-modal>
  <portals-loading-icon class="text-center"
                        style="font-size: 45pt"></portals-loading-icon>
</ng-template>
<!-- edit provider portal modal -->
<ng-template #editProviderPortalModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Provider Portal Integration</h4>
    <button (click)="modal.dismiss()" class="btn btn-link"
            type="button">
      <i class="fas fa-lg fa-window-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-3 text-end">
        User:
      </div>
      <div class="col font-italic">
        {{userModel.name}}
      </div>
    </div>
    <form #editProviderPortalForm="ngForm">
      <div class="row mt-3">
        <div class="col-3 text-end">
          Roles:
        </div>
        <div class="col font-italic">
          <div class="form-check form-switch" *ngFor="let role of RoleNames.ProviderPortalRoleNames">
            <input class="form-check-input" type="checkbox"
                   [checked]="modelHasRole(role.key)"
                   (change)="handleModelRoleChangeEvent(role.key); editProviderPortalForm.form.markAsDirty()"
                   [id]="'AccessProviderPortal_'+role.key">
            <label class="form-check-label" [for]="'AccessProviderPortal_'+role.key">
              {{role.name}}
            </label>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-3 text-end">
          Provider:
        </div>
        <div class="col">
          <div class="input-group">
            <ng-select bindLabel="display_name"
                       class="me-2 w-100"
                       name="userProviderMapping"
                       id="userProviderMapping"
                       [(ngModel)]="userModel.provider_integration_id"
                       functionValidator
                       [func]="validateProvider"
                       bindValue="external_id">
              <ng-option *ngFor="let provider of allProviders"
                         [value]="provider.external_id"
                         [disabled]="provider.user_id">{{provider.display_name}}</ng-option>
            </ng-select>
            <div>
              <em>Note:</em> disabled Providers are already mapped to a user.
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button (click)="modal.close('save')"
            [disabled]="editProviderPortalForm.pristine"
            class="btn btn-secondary" type="button">
      Save
    </button>
    <button (click)="modal.dismiss()"
            ngbAutofocus
            class="btn btn-outline-primary" type="button">
      Cancel
    </button>
  </div>
</ng-template>
<!-- edit client portal  modal -->
<!--<ng-template #editClientPortalModal let-modal>-->
<!--  <div class="modal-header">-->
<!--    <h4 class="modal-title">Client Portal Integration</h4>-->
<!--    <button (click)="modal.dismiss()" class="btn btn-link"-->
<!--            type="button">-->
<!--      <i class="fas fa-lg fa-window-close"></i>-->
<!--    </button>-->
<!--  </div>-->
<!--  <div class="modal-body">-->
<!--    <div class="row">-->
<!--      <div class="col-3 text-end">-->
<!--        User:-->
<!--      </div>-->
<!--      <div class="col font-italic">-->
<!--        {{userModel.name}}-->
<!--      </div>-->
<!--    </div>-->
<!--    <form #editClientPortalForm="ngForm">-->
<!--      <div class="row mt-3">-->
<!--        <div class="col-3 text-end">-->
<!--          Roles:-->
<!--        </div>-->
<!--        <div class="col font-italic">-->
<!--          <div class="form-check form-switch" *ngFor="let role of RoleNames.ClientPortalRoleNames">-->
<!--            <input class="form-check-input" type="checkbox"-->
<!--                   [checked]="modelHasRole(role.key)"-->
<!--                   (change)="handleModelRoleChangeEvent(role.key); editClientPortalForm.form.markAsDirty()"-->
<!--                   [id]="'AccessClientPortal_'+role.key">-->
<!--            <label class="form-check-label" [for]="'AccessClientPortal_'+role.key">-->
<!--              {{role.name}}-->
<!--            </label>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row mt-2">-->
<!--        <div class="col-3 text-end">-->
<!--          Client:-->
<!--        </div>-->
<!--        <div class="col">-->
<!--          <div class="input-group">-->
<!--            <ng-select bindLabel="name"-->
<!--                       class="me-2 w-100"-->
<!--                       name="userClientMapping"-->
<!--                       id="userClientMapping"-->
<!--                       [(ngModel)]="userModel.client_integration_id"-->
<!--                       bindValue="external_id"-->
<!--                       [items]="allClients"></ng-select>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </form>-->
<!--  </div>-->
<!--  <div class="modal-footer">-->
<!--    <button (click)="modal.close('save')"-->
<!--            [disabled]="editClientPortalForm.pristine"-->
<!--            class="btn btn-secondary" type="button">-->
<!--      Save-->
<!--    </button>-->
<!--    <button (click)="modal.dismiss()"-->
<!--            ngbAutofocus-->
<!--            class="btn btn-outline-primary" type="button">-->
<!--      Cancel-->
<!--    </button>-->
<!--  </div>-->
<!--</ng-template>-->
<!-- edit admin portal modal -->
<ng-template #editAdminPortalModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Admin Portal Integration</h4>
    <button (click)="modal.dismiss()" class="btn btn-link"
            type="button">
      <i class="fas fa-lg fa-window-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-3 text-end">
        User:
      </div>
      <div class="col font-italic">
        {{userModel.name}}
      </div>
    </div>
    <form #editAdminPortalForm="ngForm">
      <div class="row mt-2">
        <div class="col-3 text-end">
          Roles:
        </div>
        <div class="col font-italic">
          <div class="form-check form-switch" *ngFor="let role of RoleNames.AdminPortalRoleNames">
            <input class="form-check-input" type="checkbox"
                   [checked]="modelHasRole(role.key)"
                   (change)="handleModelRoleChangeEvent(role.key); editAdminPortalForm.form.markAsDirty()"
                   [id]="'AccessAdminPortal_'+role.key">
            <label class="form-check-label" [for]="'AccessAdminPortal_'+role.key">
              {{role.name}}
            </label>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button (click)="modal.close('save')"
            [disabled]="editAdminPortalForm.pristine"
            class="btn btn-secondary" type="button">
      Save
    </button>
    <button (click)="modal.dismiss()"
            ngbAutofocus
            class="btn btn-outline-primary" type="button">
      Cancel
    </button>
  </div>
</ng-template>
<!-- add password modal -->
<ng-template #addPwModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Link User to Password Credentials</h4>
    <button (click)="modal.dismiss()" class="btn btn-link"
            type="button">
      <i class="fas fa-lg fa-window-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <div class="d-inline-block" style="width: 3em">User:</div>
      <em>{{userModel.name}}</em>
    </div>
    <div>
      <div class="d-inline-block" style="width: 3em">Email:</div>
      <em>{{userModel.email}}</em>
    </div>
    <p class="mt-2">
      <em>Note:</em> You are about to link this user to Password based credentials.
      Enter the user's password below.
    </p>
    <form #addPwModalForm="ngForm">
      <div>
        <label class=" form-label" for="edit_password">User Password</label>
        <div class="input-group">
          <input [(ngModel)]="userModel.password" class="form-control" id="edit_password"
                 maxlength="255"
                 required
                 name="edit_password"
                 minlength="6"
                 type="text">
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button (click)="modal.dismiss()"
            ngbAutofocus
            class="btn btn-outline-primary me-4"
            type="button">
      Cancel
    </button>
    <button (click)="modal.close('save')"
            class="btn btn-secondary"
            [disabled]="addPwModalForm.invalid"
            type="button">
      Save
    </button>
  </div>
</ng-template>
<!-- add PCC credential modal -->
<ng-template #addPccModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Link User to PointClickCare Account</h4>
    <button (click)="modal.dismiss()" class="btn btn-link"
            type="button">
      <i class="fas fa-lg fa-window-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <div class="d-inline-block" style="width: 3em">User:</div>
      <em>{{userModel.name}}</em>
    </div>
    <p class="mt-2">
      <em>Note:</em> You are about to link this User with a pending connection to a
      PointClickCare account. When you click "Link User", a URL will be generated
      that you must email or otherwise give to the User. The User must click on the URL and log in
      with their PointClickCare credentials to complete the link.
    </p>
  </div>
  <div class="modal-footer">
    <button (click)="modal.dismiss()"
            ngbAutofocus
            class="btn btn-outline-primary me-4"
            type="button">Cancel
    </button>
    <button (click)="modal.close('save')"
            class="btn btn-secondary"
            type="button">
      Link User
    </button>
  </div>
</ng-template>
<!-- add o365 modal -->
<ng-template #addO365Modal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Link User to Forefront Microsoft Account</h4>
    <button (click)="modal.dismiss()" class="btn btn-link"
            type="button">
      <i class="fas fa-lg fa-window-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <div class="d-inline-block" style="width: 3em">User:</div>
      <em>{{userModel.name}}</em>
    </div>
    <p class="mt-2">
      <em>Note:</em> You are about to link this User to a Forefront Microsoft Account.
      Select the Forefront Azure AD account to link to this User below.
    </p>
    <form #addO365Form="ngForm">
      <div>
        <label class=" form-label" for="edit_ms_account">Azure AD User (disabled items already mapped to a User)</label>
        <div class="input-group">
          <ng-select class="w-100"
                     name="edit_ms_account"
                     id="edit_ms_account"
                     [(ngModel)]="userModel.msAccountId"
                     bindValue="id"
                     functionValidator
                     [func]="validateMsUser">
            <ng-option *ngFor="let msAccount of msUsers"
                       [disabled]="usersByMsId.has(msAccount.id)"
                       [value]="msAccount.id">
              {{msAccount.displayName}}
              ( {{msAccount.userPrincipalName}} )
            </ng-option>
          </ng-select>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button (click)="modal.dismiss()"
            ngbAutofocus
            class="btn btn-outline-primary me-4" type="button">Cancel
    </button>
    <button (click)="modal.close('save')"
            [disabled]="addO365Form.invalid"
            class="btn btn-secondary"
            type="button">
      Save
    </button>
  </div>
</ng-template>





