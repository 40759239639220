export enum ApiPermissions {
    // NOTE: Due to database specifications, enumeration values must have at most 50 characters

    // Portal Access Roles

    // Allows users to log into and view the dashboard of a particular portal.
    // Individual portal functions may require additional roles to access.
    // Users may have more than one portal role, but some portal roles may require
    // association to one and only one user context, e.g. a client facility or a provider.
    AccessAdminPortal = "AccessAdminPortal",
    AccessClientPortal = "AccessClientPortal",
    AccessProviderPortal = "AccessProviderPortal",

    // Admin Portal Roles
    AdminManageUsers = "AdminManageUsers",
    AdminManageDailyTasks = "AdminManageDailyTasks",
    AdminManageClients = "AdminManageClients",
    AdminReports = "AdminReports",
    AdminMonitorVideo = "AdminMonitorVideo",
    AdminManagePortalContent = "AdminManagePortalContent",

    // Client Portal Roles
    ClientPortalManagePatients = "ClientPortalManagePatients",
    // don't add this until we do implement telehealth tablets
    // AccessTelehealthLauncher = "AccessTelehealthLauncher",

    // Provider Portal Roles
    // Unlikely to ever be used

}


export namespace RoleNames {
    export interface RoleName {
        key: ApiPermissions;
        name: string;
    }

    // NOTE: Adding to these arrays will make them available to pages that need to display roles to humans,
    // for example during user management or search by role.
    export const AdminPortalRoleNames = Object.freeze([
        {key: ApiPermissions.AccessAdminPortal, name: "Access Admin Portal"},
        {key: ApiPermissions.AdminManageClients, name: "Manage Clients"},
        {key: ApiPermissions.AdminManageDailyTasks, name: "Manage Daily Tasks"},
        {key: ApiPermissions.AdminManagePortalContent, name: "Manage Portal Content"},
        {key: ApiPermissions.AdminManageUsers, name: "Manage Users"},
        {key: ApiPermissions.AdminMonitorVideo, name: "Monitor Zoom Sessions"},
        {key: ApiPermissions.AdminReports, name: "View Reports"},
    ] as RoleName[]);

    export const ClientPortalRoleNames = Object.freeze([
        {key: ApiPermissions.AccessClientPortal, name: "Access Client Portal"},
        {key: ApiPermissions.ClientPortalManagePatients, name: "Manage Patients"},

        // don't add this until we do implement telehealth tablets
        //ApiPermissions.AccessTelehealthLauncher, "Access Telehealth Launcher"
    ] as RoleName[]);

    export const ProviderPortalRoleNames = Object.freeze([
        {key: ApiPermissions.AccessProviderPortal, name: "Access Provider Portal"},
    ] as RoleName[]);

    export const AllRoleNames = Object.freeze([
        ...AdminPortalRoleNames,
        ...ClientPortalRoleNames,
        ...ProviderPortalRoleNames
    ] as RoleName[]);
}

export default ApiPermissions;
