<div class="container-fluid">
  <ul class="breadcrumb">
    <li class="breadcrumb-item active">Portal Content Management</li>
    <li class="breadcrumb-item" *ngIf="loading">
      <portals-loading-icon></portals-loading-icon>
    </li>
  </ul>
  <form #searchForm="ngForm">
    <button class="btn btn-primary float-end ms-3"
            [disabled]="loading"
            (click)="handleAddKey()">
      <i class="fas fa-plus fa-fw me-2"></i>
      Add CMS Item Key
    </button>
    <fieldset [disabled]="loading">
      <div class="container-fluid mb-3">
        <div class="input-group flex-nowrap">
          <button class="btn btn-secondary dropdown-toggle"
                  ngbTooltip="Filter Results"
                  (click)="$event.stopPropagation(); searchDrop.open();">
            <i class="fas fa-sliders-h"></i>
          </button>
          <div ngbDropdown #searchDrop="ngbDropdown" [autoClose]="true"
               placement="bottom-left">
            <div ngbDropdownMenu class="p-2">
              <div class="clearfix">
                <button type="button" class="btn btn-sm btn-outline-info float-start"
                        (click)="clearForm(clearBtn); searchDrop.close();"
                        #clearBtn>
                  <i class="fas fa-eraser"></i>
                </button>
                <button type="button" class="btn-close float-end" #clearBtn
                        (click)="searchDrop.close()">
                </button>
              </div>
              <h6>Sort</h6>
              <div>
                <label class="small form-label m-0" for="sortField">By Field</label>
                <select name="sortField"
                        class="form-select"
                        (change)="updateSearch()"
                        [(ngModel)]="searchData.sortField"
                        id="sortField">
                  <option value="cms_key" selected>Last Name</option>
                  <option value="first_name">First Name</option>
                  <option value="display_name">Display Name</option>
                </select>
              </div>
              <div>
                <label class="small form-label m-0" for="sortOrder">Direction</label>
                <select name="sortOrder"
                        class="form-select"
                        (change)="updateSearch()"
                        [(ngModel)]="searchData.sortOrder"
                        id="sortOrder">
                  <option value="asc" selected>Ascending</option>
                  <option value="desc">Descending</option>
                </select>
              </div>
            </div>
          </div>
          <input type="search" class="form-control" placeholder="Search CMS Items by Key"
                 #searchControl
                 (keydown)="updateSearch()"
                 (search)="updateSearch()"
                 [disabled]="loading"
                 [(ngModel)]="searchData.searchValue"
                 name="search">
            <button class="btn btn-secondary z-0"
                    (click)="updateSearch()"
                    [disabled]="loading">
              <i class="fas fa-search"></i>
            </button>
        </div>
      </div>
    </fieldset>
  </form>

  <h4 *ngIf="!loading || cmsItems && cmsItems.length > 0">CMS Items</h4>
  <div class="d-flex flex-wrap align-items-start">
    <div class="text-center" *ngIf="!loading && cmsItems && cmsItems.length === 0">
      <em>No CMS Items have been created yet</em>
    </div>
    <div class="m-3 border border-primary border-2em p-2"
         style="min-width: 10vw;"
         *ngFor="let item of cmsItems | sort:searchData.sortField:'cms_key' | filterFunc:searchFilter:searchUpdated">
      <div><strong>{{item.cms_key}}</strong></div>
      <p class="m-l2">{{item.current_version.body}}</p>
    </div>
  </div>
</div>

<ng-template #addCmsItem let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Add CMS Item</h4>
    <button (click)="modal.dismiss()" class="btn btn-link"
            type="button">
      <i class="fas fa-lg fa-window-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <form #addCmsItemForm="ngForm">
      <div class="row">
        <div class="col-6">
          <div class="alert alert-info">
            <p>
              You are about to add a CMS Item. Each CMS Item key represents a section that will
              be displayed to users on a particular portal. The chosen portal must be modified to
              make use of new keys before they can be displayed to a user.
            </p>
            <p>By convention, keys follow the format <em>PortalName.PageName.UniqueName</em>
            </p>
            <p>Once created, no CMS Item key can be deleted.</p>
          </div>
        </div>
        <div class="col-6">
          <div>
            <label class=" form-label" for="portal_name">Portal</label>
            <select name="portal_name"
                    class="form-select"
                    required
                    [(ngModel)]="addItemModel.portal_name"
                    id="portal_name">
              <option value="" selected></option>
              <option value="provider">Provider Portal</option>
              <option value="client">Client Portal</option>
            </select>
          </div>
          <div>
            <label class="required form-label" for="page_name">Page Name</label>
            <div class="input-group">
              <input [(ngModel)]="addItemModel.page_name"
                     class="form-control"
                     id="page_name"
                     name="page_name"
                     maxlength="50"
                     required
                     type="text"/>
            </div>
          </div>
          <div>
            <label class="required form-label" for="unique_name">Unique Name</label>
            <div class="input-group">
              <input [(ngModel)]="addItemModel.unique_name"
                     class="form-control"
                     id="unique_name"
                     name="unique_name"
                     maxlength="50"
                     required
                     type="text">
            </div>
          </div>
        </div>
      </div>
      <div>
        <label class="required form-label" for="body">Initial Value</label>
        <div class="input-group">
          <textarea [(ngModel)]="addItemModel.body" class="form-control"
                    id="body"
                    name="body"
                    [maxlength]="bodyMax"
                    required
                    rows="7"
                    type="text"></textarea>
        </div>
      </div>
    </form>

  </div>
  <div class="modal-footer">
    <button (click)="modal.dismiss()"
            ngbAutofocus
            class="btn btn-outline-primary me-auto" type="button">Cancel
    </button>
    <button (click)="modal.close('add')"
            [disabled]="!addCmsItemForm.dirty || !addCmsItemForm.valid"
            class="btn btn-danger" type="button">Create CMS Item
    </button>
  </div>
</ng-template>

<ng-template #loadingModal let-modal>
  <portals-loading-icon class="text-center"
                        style="font-size: 45pt"></portals-loading-icon>
</ng-template>
