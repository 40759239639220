import {Component, OnInit, ViewEncapsulation} from '@angular/core';

/**
 * SharedStylesComponent is intended to be a root component added to a portal's
 * main root layout to ensure that shared styles are added to the page
 */
@Component({
  selector: 'portals-shared-styles',
  template: "<!-- non-visible shared styles component -->",
  styleUrls: ['./shared-styles.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SharedStylesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
