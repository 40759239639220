<div class="container-fluid">
  <ul class="breadcrumb">
    <li class="breadcrumb-item active">Providers</li>
    <li class="breadcrumb-item" *ngIf="loading">
      <portals-loading-icon></portals-loading-icon>
    </li>
  </ul>
  <form #searchForm="ngForm">
    <fieldset [disabled]="loading">
      <div class="container-fluid mb-3">
        <div class="input-group flex-nowrap">
          <button class="btn btn-secondary dropdown-toggle"
                  ngbTooltip="Filter Results"
                  (click)="$event.stopPropagation(); searchDrop.open();">
            <i class="fas fa-sliders-h"></i>
          </button>
          <div ngbDropdown #searchDrop="ngbDropdown" [autoClose]="true"
               placement="bottom-left">
            <div ngbDropdownMenu class="p-2">
              <div class="clearfix">
                <button type="button" class="btn btn-sm btn-outline-info float-start"
                        (click)="clearForm(clearBtn); searchDrop.close();"
                        #clearBtn>
                  <i class="fas fa-eraser"></i>
                </button>
                <button type="button" class="btn-close float-end" #clearBtn
                        (click)="searchDrop.close()">
                </button>
              </div>
              <h6>Sort</h6>
              <div>
                <label class="small form-label m-0" for="sortField">By Field</label>
                <select name="sortField"
                        class="form-select"
                        (change)="updateSearch()"
                        [(ngModel)]="searchData.sortField"
                        id="sortField">
                  <option value="last_name" selected>Last Name</option>
                  <option value="first_name">First Name</option>
                  <option value="display_name">Display Name</option>
                </select>
              </div>
              <div>
                <label class="small form-label m-0" for="sortOrder">Direction</label>
                <select name="sortOrder"
                        class="form-select"
                        (change)="updateSearch()"
                        [(ngModel)]="searchData.sortOrder"
                        id="sortOrder">
                  <option value="asc" selected>Ascending</option>
                  <option value="desc">Descending</option>
                </select>
              </div>
              <h6>Filter</h6>
              <div class="form-check form-switch"
                   ngbTooltip="Show only Providers that are not mapped to a Portals User Account">
                <input type="checkbox" class="form-check-input"
                       id="missingUser"
                       name="missingUser"
                       (change)="updateSearch()"
                       [(ngModel)]="searchData.missingUser">
                <label class="small form-check-label text-nowrap" for="missingUser">
                  Missing Portal User Account
                </label>
              </div>
            </div>
          </div>

          <input type="search" class="form-control" placeholder="Search Providers by Name"
                 #searchControl
                 (keydown)="updateSearch()"
                 (search)="updateSearch()"
                 [disabled]="loading"
                 [(ngModel)]="searchData.searchValue"
                 name="search">
          <button class="btn btn-secondary z-0"
                  (click)="updateSearch()"
                  [disabled]="loading">
            <i class="fas fa-search"></i>
          </button>
        </div>
      </div>
    </fieldset>
  </form>
  <div *ngIf="!loading">
    <div class="text-center" *ngIf="providers && providers.length === 0">
      <em>No Providers have been synced from Athena yet</em>
    </div>
  </div>
  <div class="d-flex flex-wrap justify-content-center">
    <div class="m-3 border border-primary border-2em p-2"
         style="width: 17vw;"
         *ngFor="let provider of providers | sort:searchData.sortField:searchData.sortOrder | filterFunc:searchFilter:searchUpdated">
      <div class="fw-bold">
        <span class="me-2"
              [ngbPopover]="popContent"
              placement="right"
              triggers="mouseenter:mouseleave"
              popoverClass="providerProfile"
              [openDelay]="200" [closeDelay]="500"
              *ngIf="provider.provider_user_profile">
          <i class="fas fa-user-md text-primary"></i>
          <ng-template #popContent>
            <div class="provider-profile-header">
              <img *ngIf="provider.provider_user_profile.picture"
                   alt="User Avatar"
                   [src]="provider.provider_user_profile.picture"/>
              <i *ngIf="!provider.provider_user_profile.picture"
                 class="fas fa-user-circle fa-fw text-black-50"></i>
            </div>
            <div class="provider-profile-body">
              <div class="font-italic">
                {{provider.provider_user_profile.name}}
              </div>
              <div class="font-monospace text-truncate"
                   *ngIf="provider.provider_user_profile.email">
                {{provider.provider_user_profile.email}}
              </div>
              <div class="font-monospace text-truncate"
                   *ngIf="provider.provider_user_profile.phone">
                {{provider.provider_user_profile.phone}}
              </div>
            </div>
          </ng-template>
        </span>
        <span class="me-2"
              ngbTooltip="Provider does not have a Portal Account set up"
              *ngIf="!provider.provider_user_profile">
          <i class="fas fa-user-alt-slash text-danger"></i>
        </span>
        <span [innerHTML]="provider.display_name | markResult:searchRegexs"></span>
      </div>
      <div *ngIf="provider.facilities?.length > 0"><em>Departments</em></div>
      <div class="small ms-3 me-1 scroll-y" *ngIf="provider.facilities?.length > 0"
           style="max-height: 6em;">
        <div *ngFor="let department of provider.facilities | sort:'name'"
             class="ms-2" style="padding-left: 1em; text-indent: -1em;">
          {{department.name}}
        </div>
      </div>
    </div>
  </div>
</div>

