/**
 * returns a promise that fulfils after the specified number of milliseconds
 * @param ms
 */
export function waitFor(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Iterates over an array of objects and groups the array by a particular
 * key-field within the object.
 *
 * @template K,T -- K: The type of the key grouped by; T: the type of the object grouped
 * @param data The array of objects of type T to group
 * @param key The key-field name. Used to look up the value being grouped on.
 * @param keyFunc optional callback. If supplied, passes the key-field value to the
 * callback and uses the returned value as the key for the Map
 * @return A Map object of type K, T
 */
export function groupToMap<K, T>(data: T[],
                                 key: string,
                                 keyFunc: (val: any) => K = (a) => a): Map<K, T[]> {
    const map = new Map<K, T[]>();
    data.forEach(i => {
        const val: any = (i as any)[key];
        const id = keyFunc(val);
        if (!map.has(id)) {
            map.set(id, []);
        }
        map.get(id).push(i);
    });
    return map;
}

/**
 * Serializes a caught error object and its stack trace so that they can be logged
 * @param e
 */
export function getErrorData(e: any): string {
    if (e.response?.body) {
        e.responsebody = e.response.body;
    }
    if (e.options?.body) {
        e.requestbody = e.options.body;
    }
    return JSON.stringify(e, (name, value) => {
        if (value instanceof Error) {
            return {
                ...value,
                name: value.name,
                message: value.message,
                stack: value.stack
            };
        }
        return value;
    }, 2);
}

export function getErrorJson(e:any) {
    const errStr = getErrorData(e);
    return JSON.parse(errStr);
}
