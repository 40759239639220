import { Injectable } from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class PortalToasterService {

  constructor(private toasts: ToastrService ) { }

  showSuccess(message:string, title="Success") {
    this.toasts.success(message, title,{
      closeButton: true,
      positionClass: "toast-top-right"
    });
  }

  showError(message:string, title="Alert") {
    this.toasts.error(message, title,{
      closeButton: true,
      positionClass: "toast-top-right",
      disableTimeOut: true
    });
  }

  showWarning(message:string, title="Warning") {
    this.toasts.warning(message, title,{
      closeButton: true,
      positionClass: "toast-top-right",
    });
  }

  showInfo(message:string, title="Note") {
    this.toasts.info(message, title,{
      closeButton: true,
      positionClass: "toast-top-right"
    });
  }
}
