import {Inject, Injectable} from '@angular/core';
import {StorageService} from "./storage.service";
import {DOCUMENT} from "@angular/common";
import {BehaviorSubject, Observable} from "rxjs";

const CurrentThemeStorageName = "theme-switcher-current-theme";
const DefaultTheme = "default";

@Injectable({
  providedIn: 'root'
})
export class ThemeSwitcherService {
  private link;
  private _currentTheme = new BehaviorSubject<string>(null);
  CurrentTheme: Observable<string>;


  constructor(@Inject(DOCUMENT) private doc,
              private storage: StorageService) {
    this.CurrentTheme = this._currentTheme.asObservable();
    const link = this.doc.createElement("link");
    link.setAttribute("rel", "stylesheet");
    link.setAttribute("type", "text/css");
    this.link = link;
    let currentTheme = this.storage.local.get(CurrentThemeStorageName);
    if (!currentTheme) {
      this.doc.head.appendChild(link);
      return;
    }

    this.setTheme(currentTheme);
    this.doc.head.appendChild(link);
  }

  setTheme(themeName: string) {
    this.storage.local.set(CurrentThemeStorageName, themeName);
    this.link.setAttribute("href", `themes/${themeName}/bootstrap.min.css`);
    this._currentTheme.next(themeName);
  }
}
