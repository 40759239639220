<div class="container">
  <div class="card text-white bg-info mb-3 w-50">
    <div class="card-body">
      <p class="card-text" *ngIf="showSplash">
        You are not logged into the Forefront {{portalName}} Portal. To log in, please select a login provider
        from the options below.
      </p>
      <p class="card-text" *ngIf="showLoggedOut">
        You have been successfully logged out. To log in again, please select a login provider
        from the options below.
      </p>
      <p class="card-text" *ngIf="showTimedOut">
        You have been logged out due to inactivity. To log in again, please select a login provider
        from the options below.
      </p>
    </div>
  </div>
  <p *ngIf="showO365Login">
    <a class="btn btn-primary" [href]="o365LoginUrl">
      <portals-microsoft-icon class="d-inline-block me-3 icon25"></portals-microsoft-icon>
      Sign in with Forefront Microsoft Account</a>
  </p>
  <p *ngIf="showPccLogin">
    <a [href]="pccLoginUrl" class="btn btn-secondary btn-pcc-sign-in">
      <portals-pcc-icon class="d-inline-block me-3 icon25"></portals-pcc-icon>
      Sign in with <strong>PointClickCare</strong>
    </a>
  </p>
  <p *ngIf="showPasswordLogin">
    <a class="btn btn-primary" [href]="passwordLoginUrl">
      <portals-password-icon class="d-inline-block me-3 icon25"></portals-password-icon>
      Sign in with Forefront Portal Password</a>
  </p>
</div>
