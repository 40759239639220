import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterFunc'
})
export class FilterFuncPipe implements PipeTransform {

  transform<T>(items: T[], func: (item:T) => boolean, ...args: any[]): T[] {
    if(!items) {
      return items;
    }
    if(!func) {
      return items;
    }

    return items.filter(func);
  }
}
