import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PatchRequest} from "#psygnal-model/shared/PatchRequest";
import {ArrayResponse} from "#psygnal-model/shared/ArrayResponse";
import {ZoomMeetingResult} from "#psygnal-model/routes/zoom/ZoomMeetingResult";
import {PortalUser} from "#psygnal-model/routes/portal-users/PortalUser";
import {AddCredentialRequest} from "#psygnal-model/routes/portal-users/AddCredentialRequest";
import {PortalAuthProviders} from "#psygnal-model/shared/PortalAuthProviders";
import {ResetUserPasswordRequest} from "#psygnal-model/routes/portal-users/ResetUserPasswordRequest";
import {Provider} from "#psygnal-model/routes/providers/Provider";
// import {Client} from "#psygnal-model/routes/clients/Client";
import {Department} from "#psygnal-model/routes/clients/Department";
import {ZoomFacility} from "#psygnal-model/routes/zoom/ZoomFacility";
import {lastValueFrom} from "rxjs";
import {User} from "@microsoft/microsoft-graph-types";
import {CmsItem} from "#psygnal-model/routes/cms/CmsItem";
import {
  CmsChangeVersionRequest,
  CmsNewItemRequest,
  CmsSetVersionRequest
} from "#psygnal-model/routes/cms/CmsChangeRequests";
import {CmsItemVersion} from "#psygnal-model/routes/cms/CmsItemVersion";
import {TaskTypes} from "#psygnal-model/routes/tasks/TaskTypes";
import {TaskLogItem} from "#psygnal-model/routes/tasks/TaskLogItem";
import {TaskLogItemSearchRequest} from "#psygnal-model/routes/tasks/TaskLogItemSearchRequest";
import {SalesforceSowRecord} from "#psygnal-model/routes/clients/SalesforceSowRecord";


//region Resource Routes
const UsersUrl = "/portal-users";
const ZoomRootUrl = "/zoom";
const TasksUrl = "/tasks";
// const ClientsUrl = "/clients";
const DepartmentsUrl = "/departments";
const ProvidersUrl = "/providers";
const CmsUrl = "/cms";

//endregion

function url(path: string): string {
  return `${environment.portal.apiUrl}${path}`;
}

@Injectable({
  providedIn: 'root'
})
export class RestApiService {
  constructor(private http: HttpClient) {
  }

  //region User Management
  async getUsers(): Promise<ArrayResponse<PortalUser>> {
    const path = url(UsersUrl);
    return lastValueFrom(this.http.get<ArrayResponse<PortalUser>>(path));
  }

  async addUser(request: PortalUser): Promise<PortalUser> {
    const path = url(UsersUrl);
    return lastValueFrom(this.http.post<PortalUser>(path, request));
  }

  async patchUser(id: string, request: PatchRequest): Promise<PortalUser> {
    return lastValueFrom(this.http.patch<PortalUser>(url(`${UsersUrl}/${id}`), request));
  }

  async deleteUser(id: string): Promise<undefined> {
    return lastValueFrom(this.http.delete<undefined>(url(`${UsersUrl}/${id}`)));
  }

  async getUserById(id: string): Promise<PortalUser> {
    return lastValueFrom(this.http.get<PortalUser>(url(`${UsersUrl}/${id}`)));
  }

  async addUserCredential(id: string, request: AddCredentialRequest): Promise<any> {
    const path = url(`${UsersUrl}/${id}/credentials`);
    return lastValueFrom(this.http.post<any>(path, request));
  }

  async deleteUserCredential(id: string, type: PortalAuthProviders): Promise<undefined> {
    const path = url(`${UsersUrl}/${id}/credentials/${type}`);
    return lastValueFrom(this.http.delete<undefined>(path));
  }

  async resetUserPassword(id: string, request: ResetUserPasswordRequest): Promise<any> {
    const path = url(`${UsersUrl}/${id}/reset-password`);
    return lastValueFrom(this.http.post<any>(path, request));
  }

  async getMsUsers(): Promise<ArrayResponse<User>> {
    const path = url(`${UsersUrl}/ms-users`);
    return lastValueFrom(this.http.get<ArrayResponse<User>>(path));
  }

  //endregion

  //region Zoom
  async getZoomFacilityAppointments(appointmentDate: string): Promise<ArrayResponse<ZoomFacility>> {
    const path = `${ZoomRootUrl}/facility-appointments?appointment_date=${appointmentDate}`;
    return lastValueFrom(this.http.get<ArrayResponse<ZoomFacility>>(url(path)));
  }

  async getZoomMeetings(): Promise<ZoomMeetingResult> {
    const path = `${ZoomRootUrl}/meetings`;
    return lastValueFrom(this.http.get<ZoomMeetingResult>(url(path)));
  }

  //endregion

  //region Tasks
  async postStartTask(taskType: TaskTypes) {
    const path = url(`${TasksUrl}/${taskType}/start-manually`);
    return lastValueFrom(this.http.post(path, {
      start: true
    }));
  }

  async getTaskLogs(taskType: TaskTypes, searchRequest?: TaskLogItemSearchRequest) {
    const path = url(`${TasksUrl}/${taskType}`);
    return lastValueFrom(this.http.get<ArrayResponse<TaskLogItem>>(path, {
      params: searchRequest as any
    }));
  }
  //endregion

  //region Providers
  async getProviders(flat = false): Promise<ArrayResponse<Provider>> {
    const reqUrl = flat ? `${ProvidersUrl}?flat=true` : ProvidersUrl;
    const path = url(reqUrl);
    return lastValueFrom(this.http.get<ArrayResponse<Provider>>(path));
  }

  //endregion
  //region Clients
  // removed, but kept
  // async getClients(flat = false): Promise<ArrayResponse<Client>> {
  //   const reqUrl = flat ? `${ClientsUrl}?flat=true` : ClientsUrl;
  //   const path = url(reqUrl);
  //   return lastValueFrom(this.http.get<ArrayResponse<Client>>(path));
  // }
  //
  // async postAddClient(request: Client): Promise<Client> {
  //   const path = url(ClientsUrl);
  //   return lastValueFrom(this.http.post<Client>(path, request));
  // }
  //
  // async patchClient(clientId: string, request: PatchRequest): Promise<Client> {
  //   const path = url(`${ClientsUrl}/${clientId}`);
  //   return lastValueFrom(this.http.patch<Client>(path, request));
  // }
  //
  // async deleteClient(clientId: string): Promise<undefined> {
  //   const path = url(`${ClientsUrl}/${clientId}`);
  //   return lastValueFrom(this.http.delete<undefined>(path));
  // }

  //endregion
  //region Facilities
  async getDepartments(): Promise<ArrayResponse<Department>> {
    const path = url(`${DepartmentsUrl}`);
    return lastValueFrom(this.http.get<ArrayResponse<Department>>(path));
  }

  async getSalesforceSows(): Promise<ArrayResponse<SalesforceSowRecord>> {
    const path = url(`${DepartmentsUrl}/salesforce-sows`);
    return lastValueFrom(this.http.get<ArrayResponse<SalesforceSowRecord>>(path));

  }

  async getCareCoordinators(): Promise<ArrayResponse<string>> {
    const path = url(`${DepartmentsUrl}/care-coordinators`);
    return lastValueFrom(this.http.get<ArrayResponse<string>>(path));
  }

  async patchDepartment(departmentId: string, request: PatchRequest): Promise<Department> {
    const path = url(`${DepartmentsUrl}/${departmentId}`);
    return lastValueFrom(this.http.patch<Department>(path, request));
  }

  //endregion

  //region CMS
  async getCmsData(): Promise<ArrayResponse<CmsItem>> {
    const path = url(`${CmsUrl}?all_versions=true`); // always get all versions for admin portal.
    return lastValueFrom(this.http.get<ArrayResponse<CmsItem>>(path));
  }

  async addCmsItem(request: CmsNewItemRequest): Promise<CmsItem> {
    const path = url(CmsUrl);
    return lastValueFrom(this.http.post<CmsItem>(path, request));
  }

  async addCmsItemVersion(cmsKey: string, request: CmsChangeVersionRequest): Promise<CmsItem> {
    const path = url(`${CmsUrl}/${cmsKey}/versions`);
    return lastValueFrom(this.http.post<CmsItem>(path, request));
  }

  async setCmsItemVersionId(cmsKey: string, request: CmsSetVersionRequest): Promise<CmsItem> {
    const path = url(`${CmsUrl}/${cmsKey}`);
    return lastValueFrom(this.http.patch<CmsItem>(path, request));
  }

  // TODO: should this return an observable instead? Look into live-edit approaches in Angular
  async editCmsItemVersion(cmsKey: string, versionId: number, request: CmsChangeVersionRequest): Promise<CmsItemVersion> {
    const path = url(`${CmsUrl}/${cmsKey}/versions/${versionId}`);
    return lastValueFrom(this.http.patch<CmsItemVersion>(path, request));
  }

  async deleteCmsItemVersion(cmsKey: string, versionId: number) {
    const path = url(`${CmsUrl}/${cmsKey}/versions/${versionId}`);
    return lastValueFrom(this.http.delete<CmsItem>(path));
  }

  //endregion

  //region pcc admin
  // async getPccActivatedClients(): Promise<any> {
  //   return lastValueFrom(this.http.get<any>(url("/pcc/clients")));
  // }
  //
  // async getPccClientFacilities(orgId: string): Promise<any> {
  //   return lastValueFrom(this.http.get<any>(url(`/pcc/clients/${orgId}/facilities`)));
  // }

  //endregion
}
