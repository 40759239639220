import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {RestApiService} from "../../services/rest-api.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ToastrService} from "ngx-toastr";
import moment, {Moment} from "moment-timezone";
import {fromEvent, Subscription, timer} from "rxjs";
import {StatesList} from "#psygnal-model/shared/StateLookups";
import {AuthService, CheckMultivalue, CheckMultivalueSingleTarget, CheckValue} from "portal-lib";
import {TimeZoneList} from "#psygnal-model/shared/TimezoneLookups";
import {SearchBase, SearchDataBase} from "portal-lib";
import {ZoomAppointment, ZoomFacility} from "#psygnal-model/routes/zoom/ZoomFacility";
import {PsygnalIdToken} from "#psygnal-model/shared/PsygnalTokens";
import {ZoomMeeting} from "#psygnal-model/routes/zoom/ZoomMeetingResult";
import {DateFormat, DateTimeFormat} from "#psygnal-model/shared/DateFormats";

const zoomUrlPrefix = "https://forefronttelecare.zoom.us/j/";

function getZoomMeetingId(url: string): number {
  if (!url) {
    return null;
  }
  if (!url.startsWith(zoomUrlPrefix)) {
    return null;
  }
  let str = url.replace(zoomUrlPrefix, "");
  str = str.split("?")[0];
  if (str) {
    return parseInt(str);
  }
  return null;
}

function zoomAppointmentSorter(a: ZoomSessionAppointment, b: ZoomSessionAppointment): number {
  return a.start.valueOf() - b.start.valueOf();
}

function getUtcDateRangeForTime(time = moment()): [string, string] {
  const utcStart = time.clone().startOf("day").utc();
  const utcEnd = time.clone().endOf("day").utc();
  const start = utcStart.format(DateTimeFormat);
  const end = utcEnd.format(DateTimeFormat);
  return [start, end];
}


function buildZoomSessions(apts: ZoomFacility[]) {
  return apts.map(f => {
    const appts = f.appointments?.map(a => {

      // const start = moment.tz(`${a.date} ${a.startTime}`, "MM DD YYYY HH:mm", f.timezone);
      const start = moment(a.startTime, "HH:mm");

      const end = start.clone().add(a.duration, 'minutes');
      const base: ZoomSessionAppointment = Object.assign({
        start: start,
        end: end
      }, a);
      return base;
    });
    appts?.sort(zoomAppointmentSorter);
    let first: Moment = null, last: Moment = null;
    if (appts && appts.length > 0) {
      first = appts[0].start;
      last = appts[appts.length - 1].start;
    }
    let url: string = null, zoomId: number = null;
    if (f.zoom_links && f.zoom_links.length > 0) {
      const zoom = f.zoom_links[0]; // for now, just pick the first one.
      zoomId = getZoomMeetingId(zoom.url);
      url = zoom.url;
    }
    const base: ZoomSessionFacility = {
      zoomId: zoomId,
      zoomUrl: url,
      zoomMeeting: null,
      firstAppointment: first,
      lastAppointment: last,
      sessionAppointments: appts,
      isDanger: false,
      isWarning: false,
      isMissingParty: false,
      isUnscheduled: false,
      isOverloaded: false,
      isComplete: false,
      isCurrent: false,
      external_id: f.external_id,
      athena_department_id: f.athena_department_id,
      name: f.name,
      state: f.state,
      timezone: f.timezone,
      care_coordinator: f.care_coordinator,
      zoom_links: f.zoom_links,
    };
    return base;
  });
}

@Component({
  selector: 'admin-appointment-video-monitoring-route',
  templateUrl: './appointment-video-monitoring-route.component.html',
  styleUrls: ['./appointment-video-monitoring-route.component.scss']
})
export class AppointmentVideoMonitoringRouteComponent
  extends SearchBase<AppointmentSearchData>
  implements OnInit, AfterViewInit, OnDestroy {

  syncingWithZoom = false;
  zoomSessions: ZoomSessionFacility[] = null;
  unmappedSessions: ZoomMeeting[] = null;
  TimeZoneList = TimeZoneList;
  userTimezone = "America/New_York";
  statesList = StatesList;
  countdown: number;
  countdownListener: Subscription;
  showUnmapped = false;
  showKey = false;
  @ViewChild("searchControl") searchControl: ElementRef;

  constructor(private rest: RestApiService,
              private auth: AuthService,
              private modalService: NgbModal,
              private toast: ToastrService) {
    super();
    this.searchFilter = this.searchFilter.bind(this);
    this.resetSearchData();
  }

  async ngOnInit() {
    const userProfile = this.auth.getProfile() as PsygnalIdToken;
    this.userTimezone = userProfile.zoneinfo;
    try {
      await this.loadAppointments();
    } catch (e) {
      this.toast.error("Uncaught error loading page. Please refresh and try again. If the issue persists, please contact support.");
      console.log(e);
    }
  }

  ngAfterViewInit(): void {
    this.afterViewInit(this.searchControl);
  }

  ngOnDestroy() {
    this.countdownListener?.unsubscribe();
    this.countdownListener = null;
  }

  searchFilter(zoomSession: ZoomSessionFacility) {
    let found = this.testSearch(zoomSession.name);
    found = found && CheckMultivalueSingleTarget(found, this.searchData.stateFilter, zoomSession.state);
    switch (this.searchData.alertType) {
      case "warning":
        found = found && zoomSession.isWarning;
        break;
      case "danger":
        found = found && zoomSession.isDanger;
        break;
      case "both":
        found = found && (zoomSession.isWarning || zoomSession.isDanger);
        break;
    }
    if (this.searchData.missingParty) {
      found = found && zoomSession.isMissingParty;
    }
    if (this.searchData.overloaded) {
      found = found && zoomSession.isOverloaded;
    }
    if (this.searchData.unscheduled) {
      found = found && zoomSession.isUnscheduled;
    }
    if (this.searchData.complete) {
      found = found && !zoomSession.isComplete;
    }
    return found;
  }

  //region Display helpers
  getZoomLink(session: ZoomMeeting): string {
    return `https://forefronttelecare.zoom.us/account/metrics/livemeetingdetail?meeting_id=${encodeURIComponent(session.uuid)}`;
  }

  getAppointmentTime(utcTime: Moment): string {
    if (!utcTime) {
      return "";
    }
    return this
      .localTz(utcTime).format("hh:mma");
  }

  getMeetingTime(meetingTime: string): string {
    if (!meetingTime) {
      return "";
    }
    return this.localTz(meetingTime).format("hh:mma");
  }

  getMeetingDuration(meetingTime: string): string {
    if (!meetingTime) {
      return "";
    }
    return moment(meetingTime).from(moment.utc());
  }

  //endregion

  //region Data
  // Copies and converts a moment or creates a moment-parsable string
  // then converts to the currently selected timezone
  localTz(time: Moment | string): Moment {
    return moment(time).tz(this.userTimezone);
  }


  async loadAppointments() {
    try {
      this.loading = true;
      const date = moment().format(DateFormat);
      const apts = await this.rest.getZoomFacilityAppointments(date);
      const sessions = buildZoomSessions(apts.result);
      this.zoomSessions = sessions;
      await this.loadMeetings();
      this.loading = false;
    } catch (e) {
      this.toast.error("Error loading base appointment list");
      this.loading = false;
      console.log(e);
    }
  }

  async loadMeetings() {
    try {
      this.countdownListener?.unsubscribe();
      this.countdownListener = null;
      this.syncingWithZoom = true;
      const zooms = await this.rest.getZoomMeetings();
      const zoomMap = new Map<number, ZoomMeeting>(zooms.meetings.map(z => [z.id, z]));

      const mappedZoomIds = [];
      const curTime = moment.utc();
      for (const session of this.zoomSessions) {
        let meeting = null;
        if (zoomMap.has(session.zoomId)) {
          meeting = zoomMap.get(session.zoomId);
          session.zoomMeeting = meeting;
          mappedZoomIds.push(session.zoomId);
        } else {
          session.zoomMeeting = null;
        }
        this.setWarnings(session, meeting, curTime);
      }
      const allZooms = Array.from(zoomMap.values());
      this.unmappedSessions = allZooms.filter(z => !mappedZoomIds.includes(z.id));

      this.updateSearch();
      this.syncingWithZoom = false;
      this.refreshCountdown();
    } catch (e) {
      this.toast.error("Error loading zoom meetings");
      this.syncingWithZoom = false;
      console.log(e);
    }
  }

  refreshCountdown() {
    this.countdown = 60;
    this.countdownListener = timer(1000, 1000).subscribe(val => {
      this.countdown = this.countdown - 1;
      if (this.countdown === 0) {
        this.countdownListener?.unsubscribe();
        this.countdownListener = null;
        this.loadMeetings();
      }
    });
  }

  private setWarnings(session: ZoomSessionFacility, meeting: ZoomMeeting, curTime: Moment) {
    // clear all previous warnings
    session.isDanger = false;
    session.isWarning = false;
    session.isMissingParty = false;
    session.isUnscheduled = false;
    session.isOverloaded = false;
    session.isComplete = false;
    session.isCurrent = false;

    // recompute
    const start = this.localTz(session.firstAppointment);
    const end = this.localTz(session.lastAppointment);
    const isInTimeBlock = curTime.isAfter(start) && curTime.isBefore(end);

    // handle no participants
    if (!meeting) {
      if (isInTimeBlock) {
        // warn because there are no participants when an appointment is scheduled
        session.isWarning = true;
        return;
      } else {
        // a session is complete when it's past the last appointment and there are no participants
        session.isComplete = curTime.isAfter(end);
        return;
      }
    }
    const startDuration = moment.duration(curTime.diff(start)).asMinutes();
    const isLateStart = startDuration > 5 && startDuration < 10;
    const isMissing = meeting.participants === 1;
    const isOverloaded = meeting.participants > 2;
    // overloaded if 3 or more participants
    session.isOverloaded = isOverloaded;
    // missing party if only 1 participant
    session.isMissingParty = isMissing;
    // unscheduled if not in the appointment time block
    session.isUnscheduled = !isInTimeBlock;

    session.isCurrent = isInTimeBlock && !isLateStart && !isMissing && !isOverloaded;

    session.isWarning =
      // warning if scheduled and not a late start but missing a party,
      (isMissing && isInTimeBlock && !isLateStart)
      // warning if overloaded
      || (isOverloaded)
      // warning if unscheduled
      || (!isInTimeBlock);

    session.isDanger =
      // danger if scheduled and missing a party and within the late start timespan
      (isMissing && isInTimeBlock && isLateStart)
      // danger if 1 participant and unscheduled -- could indicate zoom is on and forgotten by facility
      || (!isInTimeBlock && isMissing);
  }

  //endregion
}

//region local types

interface ZoomSessionAppointment extends ZoomAppointment {
  start: Moment;
  end: Moment;
}

interface ZoomSessionFacility extends ZoomFacility {
  sessionAppointments: ZoomSessionAppointment[];
  zoomUrl: string;
  zoomId: number;
  zoomMeeting: ZoomMeeting;
  // the start time of the first appointment
  firstAppointment: Moment;
  // the end time of the last appointment
  lastAppointment: Moment;
  isDanger: boolean;
  isWarning: boolean;
  isMissingParty: boolean;
  isUnscheduled: boolean;
  isOverloaded: boolean;
  isComplete: boolean;
  isCurrent: boolean;
}


interface AppointmentSearchData extends SearchDataBase {
  stateFilter: string[];
  alertType: string;
  missingParty: boolean;
  overloaded: boolean;
  unscheduled: boolean;
  complete: boolean;
  current: boolean;
}

//endregion
