import {InjectionToken} from "@angular/core";

export const PortalLibConfigToken = new InjectionToken<PortalLibConfig>("Token for PortalLibConfig");

export interface PortalLibConfig {
  login: {
    allowPassword: boolean;
    allowPcc: boolean;
    allowO365: boolean;
  },
  auth: PortalLibAuthConfig,
  httpIntercept: HttpInterceptorConfigItem[];
  portal: {
    name: string;
    loginRoute: string;
    unauthorizedRoute: string;
    profilePicMaxSize: number;
  }
}

export interface HttpInterceptorConfigItem {
  interceptHost: string;
  exceptPaths: string[];
}

export interface PortalLibAuthConfig {
  loginStartUrl: string;
  tokenUrl: string;
  revokeUrl: string;
  profileUrl: string;
}
