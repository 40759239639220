import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {PortalToasterService, SearchBase, SearchDataBase} from "portal-lib";
import {RestApiService} from "../../services/rest-api.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
// import {Client} from "#psygnal-model/routes/clients/Client";
import {Provider} from "#psygnal-model/routes/providers/Provider";

interface ProviderSearchData extends SearchDataBase {
  missingUser: boolean;
}

@Component({
  selector: 'admin-providers',
  templateUrl: './providers.component.html',
  styleUrls: ['./providers.component.scss']
})
export class ProvidersComponent
  extends SearchBase<ProviderSearchData>
  implements OnInit, AfterViewInit {

  providers: Provider[];
  @ViewChild('loadingModal') loadingModal;
  @ViewChild("searchControl") searchControl: ElementRef;

  constructor(
    private rest: RestApiService,
    private modalService: NgbModal,
    private toast: PortalToasterService) {
    super("last_name");
    this.searchFilter = this.searchFilter.bind(this);
    this.resetSearchData();
  }

  async ngOnInit() {
    this.resetSearchData();
    return this.loadPageData();
  }

  ngAfterViewInit(): void {
    this.afterViewInit(this.searchControl);
  }

  searchFilter(provider: Provider): boolean {
    let found = this.testSearch(provider.display_name);
    if(this.searchData.missingUser) {
      found = found && !provider.provider_user_profile;
    }
    return found;
  }

  private async loadPageData() {
    try {
      this.loading = true;
      const providers = await this.rest.getProviders();
      this.providers = providers.result;
      this.loading = false;
    } catch (e) {
      this.toast.showError("Error loading providers");
      console.error(e);
      this.loading = false;
    }
  }
}
