<div class="container-fluid">
  <p *ngIf="!hasCode">
    Error: missing authorization code.
    Please try <a href="/login">logging in again</a>.
    If the issue persists, please contact an administrator.
  </p>
  <p *ngIf="showError">
    Error obtaining token from authorization code.
    Please try <a href="/login">logging in again</a>.
    If the issue persists, please contact an administrator.
  </p>
  <p *ngIf="hasCode && !showError">
    <portals-loading-icon></portals-loading-icon>
    Finalizing login...
  </p>
</div>
