import {Component, Inject, Input, OnInit} from '@angular/core';
import {PortalLibConfig, PortalLibConfigToken} from "../../PortalLibConfig";
import {ActivatedRoute} from "@angular/router";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'portals-login-splash-route',
  templateUrl: './login-splash-route.component.html',
  styleUrls: ['./login-splash-route.component.scss']
})
export class LoginSplashRouteComponent implements OnInit {
  readonly showPasswordLogin: boolean;
  readonly showPccLogin: boolean;
  readonly showO365Login: boolean;
  readonly portalName: string;
  passwordLoginUrl: string;
  pccLoginUrl: string;
  o365LoginUrl: string;
  showSplash = true;
  showLoggedOut = false;
  showTimedOut = false;

  constructor(@Inject(PortalLibConfigToken) private config: PortalLibConfig,
              private auth: AuthService,
              private route: ActivatedRoute) {
    this.portalName = this.getPortalName();
    this.showPasswordLogin = this.config.login.allowPassword;
    this.showPccLogin = this.config.login.allowPcc;
    this.showO365Login = this.config.login.allowO365;
  }

  ngOnInit(): void {
    const challenge = this.auth.buildLoginChallenge();
    this.passwordLoginUrl = this.getLoginUrl("password", challenge);
    this.pccLoginUrl = this.getLoginUrl("pcc", challenge);
    this.o365LoginUrl = this.getLoginUrl("o365", challenge);
    this.showSplash = true;
    this.showLoggedOut = false;
    this.showTimedOut = false;
    const reason = this.route.snapshot.queryParamMap.get("reason");
    switch(reason) {
      case "timeout":
        this.showSplash = false;
        this.showTimedOut = true;
        break;
      case "manual":
        this.showSplash = false;
        this.showLoggedOut = true;
        break;
    }
  }

  private getLoginUrl(authProvider: string, challenge: string): string {
    const rootUrl = this.config.auth.loginStartUrl;
    const portal = this.config.portal.name;
    return `${rootUrl}?portal=${portal}&provider=${authProvider}&code_challenge_method=S256&code_challenge=${challenge}`;
  }

  private getPortalName() {
    switch(this.config.portal.name) {
      case "psygnal-admin":
        return "Admin";
      case "psygnal-client":
        return "Client";
      case "psygnal-provider":
        return "Provider";
      default:
        return "";
    }
  }

}
