import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {RestApiService} from "../../services/rest-api.service";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {ApiPermissions, RoleNames} from "#psygnal-model/shared/ApiPermissions";
import {PatchField, PatchRequest} from "#psygnal-model/shared/PatchRequest";
import {CredentialIntegration, PortalUser} from "#psygnal-model/routes/portal-users/PortalUser";
import {PortalAuthProviders} from "#psygnal-model/shared/PortalAuthProviders";
import {PortalToasterService, SearchBase, SearchDataBase} from "portal-lib";
import {environment} from "../../../environments/environment";
import {MapTimezoneCodeToName, TimeZoneList,} from "#psygnal-model/shared/TimezoneLookups";
import {AddCredentialRequest} from "#psygnal-model/routes/portal-users/AddCredentialRequest";
// import {Client} from "#psygnal-model/routes/clients/Client";
import {Provider} from "#psygnal-model/routes/providers/Provider";
import {sortBy} from "lodash";
import {DOCUMENT} from "@angular/common";
import {User} from "@microsoft/microsoft-graph-types";
import {AbstractControl, ValidationErrors} from "@angular/forms";

const ProfilePicMaxSize = 56320;
const NoneSearchValue = "--none--";

//region utility functions
function buildRoleViewModel(roles: ApiPermissions[]): any {
  const allRoles = Object.values(ApiPermissions);
  const vm = {};
  for (const role of allRoles) {
    vm[role] = roles.includes(role);
  }
  return vm;
}

function rebuildRoles(roleVm: any): ApiPermissions[] {
  const roles: ApiPermissions[] = [];
  for (const role of Object.keys(roleVm)) {
    if (roleVm[role]) {
      roles.push(role as ApiPermissions);
    }
  }
  return roles;
}

function userHasCred(user: PortalUser, type: PortalAuthProviders): boolean {
  if (!user) {
    return false;
  }
  switch (type) {
    case PortalAuthProviders.Office365:
      return !!user.ms_integration;
    case PortalAuthProviders.PointClickCare:
      if (user.pcc_pending_integration || user.pcc_integration) {
        return true;
      }
      return false;
    case PortalAuthProviders.PsygnalPassword:
      return !!user.pw_integration;
  }
  return false;
}

// endregion

//region component interfaces
interface UserSearchData extends SearchDataBase {
  portalRole?: ApiPermissions;
  otherRole?: ApiPermissions;
  credentialType?: PortalAuthProviders;
  providerId?: string;
  // clientId?: string;
}

interface UserModel extends PortalUser {
  isEdit?: boolean;
  credentialSelection?: PortalAuthProviders;
  password?: string;
  msAccountId?: string;
}

// endregion

@Component({
  selector: 'admin-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent
  extends SearchBase<UserSearchData>
  implements OnInit, AfterViewInit {

  //region properties
  loading: boolean = true;
  bgLoading = false;
  users: PortalUser[];
  // allClients: Client[];
  allProviders: Provider[];
  msUsers: User[];

  userModel: UserModel;
  credentialModel: CredentialIntegration;
  roleEditModel: any;

  providersById: Map<string, Provider>;
  usersByMsId: Map<string, PortalUser>;

  ApiPermissions = ApiPermissions;
  RoleNames = RoleNames;
  TimeZoneList = TimeZoneList;
  MapTimezoneCodeToName = MapTimezoneCodeToName;
  authProviders = PortalAuthProviders;
  @ViewChild('addEditUserModal') addEditUserModal;
  @ViewChild('editCredentialModal') editCredentialModal;
  @ViewChild('deleteCredentialModal') deleteCredentialModal;
  @ViewChild('deleteUserModal') deleteUserModal;
  @ViewChild('resetPasswordModal') resetPasswordModal;
  @ViewChild('editAdminPortalModal') editAdminPortalModal;
  // @ViewChild('editClientPortalModal') editClientPortalModal;
  @ViewChild('editProviderPortalModal') editProviderPortalModal;
  @ViewChild('loadingModal') loadingModal;
  @ViewChild('addPwModal') addPwModal;
  @ViewChild('addPccModal') addPccModal;
  @ViewChild('addO365Modal') addO365Modal;
  @ViewChild("searchControl") searchControl: ElementRef;
  // endregion

  //region Initialization
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private rest: RestApiService,
    private modalService: NgbModal,
    private toast: PortalToasterService) {
    super();
    this.searchFilter = this.searchFilter.bind(this);
    this.validateProvider = this.validateProvider.bind(this);
    this.validateMsUser = this.validateMsUser.bind(this);
    this.resetSearchData();
    this.clearModels();
  }

  async ngOnInit() {
    await Promise.all([
      this.loadPageData(),
      this.loadBackgroundData(),
    ]);
  }

  ngAfterViewInit(): void {
    this.afterViewInit(this.searchControl);
  }

  async loadBackgroundData() {
    this.bgLoading = true;
    try {
      const [/*clients,*/ providers, msUsers] = await Promise.all([
        // this.rest.getClients(true),
        this.rest.getProviders(true),
        this.rest.getMsUsers(),
      ]);
      // this.allClients = sortBy(clients.result, ["name"]);
      this.allProviders = sortBy(providers.result, ["last_name"]);
      this.msUsers = msUsers.result;

      this.providersById = new Map(providers.result.map(p => [p.external_id, p]));
      this.bgLoading = false;
    } catch (e) {
      this.toast.showWarning("Error loading background data. Some features may not work correctly.");
      console.error(e);
      this.bgLoading = false;
    }
  }

  async loadPageData() {
    this.loading = true;
    try {
      const users = await this.rest.getUsers();
      this.users = users.result;
      this.usersByMsId = new Map<string, PortalUser>();
      for (const user of users.result) {
        const id = user.ms_integration?.integration_sub;
        if (id) {
          this.usersByMsId.set(id, user);
        }
      }
      this.loading = false;
    } catch (e) {
      this.toast.showError("Error loading page data");
      console.error(e);
      this.loading = false;
    }
  }

  async reloadUsers() {
    try {
      const users = await this.rest.getUsers();
      this.users = users.result;
    } catch (e) {
      this.toast.showError("Error reloading users");
      console.error(e);
    }
  }

  //endregion

  //region General Event Handlers
  searchFilter(user: PortalUser): boolean {
    let found = this.testSearch(user.name);
    if (this.searchData.portalRole) {
      if(this.searchData.portalRole as string === NoneSearchValue) {
        const portalRoles = user.roles.filter(r => r === ApiPermissions.AccessAdminPortal ||
          // r === ApiPermissions.AccessClientPortal ||
          r === ApiPermissions.AccessProviderPortal);
        found = found && portalRoles.length === 0;
      } else {
        found = found && user.roles.includes(this.searchData.portalRole);
      }
    }
    if (this.searchData.otherRole) {
      found = found && user.roles.includes(this.searchData.otherRole);
    }
    if (this.searchData.credentialType) {
      if(this.searchData.credentialType as string === NoneSearchValue) {
        found = found && (
          !userHasCred(user, PortalAuthProviders.Office365) &&
          !userHasCred(user, PortalAuthProviders.PointClickCare) &&
          !userHasCred(user, PortalAuthProviders.PsygnalPassword)
        );
      } else {
        found = found && userHasCred(user, this.searchData.credentialType);
      }
    }
    if (this.searchData.providerId) {
      found = found && user.provider_integration_id === this.searchData.providerId;
    }
    // if (this.searchData.clientId) {
    //   found = found && user.client_integration_id === this.searchData.clientId;
    // }
    return found;
  }

  handleModelRoleChangeEvent(role: ApiPermissions) {
    this.roleEditModel[role] = !this.roleEditModel[role];
  }

  async handleCopyPendingUrlClick(code: string) {
    try {
      await navigator.clipboard.writeText(this.getPendingUrl(code));
      this.toast.showInfo("Url Copied to Clipboard");
    } catch (e) {
      this.toast.showError("Error accessing clipboard. Please check browser permissions.");
    }
  }

  handleFileSelect(fileEvent: Event, model: PortalUser) {
    const fileInput = fileEvent.target as HTMLInputElement;
    const files = fileInput.files;
    if (!files || files.length === 0) {
      return; // didn't receive an HTML Input change event, or there are no files selected
    }
    const file = files[0]; // ignore multiply selected files in favor of the first.
    if (!file) {
      return; // still don't have one? Skip to my lou
    }
    if (file.size > ProfilePicMaxSize) {
      this.toast.showWarning("The file you select must be no larger than 55kb");
      model.picture = null;
      fileInput.value = null;
      return;
    }

    const mimeType = file.type;
    if (!mimeType.includes("image")) {
      this.toast.showWarning("The file you select must be an image");
      model.picture = null;
      fileInput.value = null;
      return;
    }
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      model.picture = reader.result as string; // call to readAsDataUrl should return string
    });
    reader.readAsDataURL(file);
  }

  handleClearProfilePictureClick(fileElement: HTMLInputElement) {
    this.userModel.picture = null;
    fileElement.value = null;
  }

  //endregion

  //region View Helpers
  modelHasRole(role: ApiPermissions): boolean {
    return this.roleEditModel[role];
  }

  userPortalAccess(user: PortalUser, role: ApiPermissions): boolean {
    return user.roles.includes(role);
  }

  // userWarnClientPortal(user: PortalUser): boolean {
  //   return this.userPortalAccess(user, ApiPermissions.AccessClientPortal) && !user.client_integration_id;
  // }

  userWarnProviderPortal(user: PortalUser): boolean {
    return this.userPortalAccess(user, ApiPermissions.AccessProviderPortal) && !user.provider_integration_id;
  }

  getPendingUrl(code: string): string {
    return `${environment.portal.credentialIntegrationUrl}?code=${code}`;
  }

  validateMsUser(control: AbstractControl): ValidationErrors | null {
    const val = control.value;
    if (!val) {
      return null;
    }
    const user = this.usersByMsId.get(val);
    if(user) {
      return {
        msUserAlreadyMapped: true
      };
    }
    return null;
  }

  validateProvider(control: AbstractControl): ValidationErrors | null {
    const val = control.value;
    if (!val) {
      return null;
    }
    const provider = this.providersById.get(val);
    if(provider?.user_id) {
      return {
        providerAlreadyMapped: true
      };
    }
    return null;
  }

  //endregion

  private clearModels() {
    this.credentialModel = {} as any;
    this.userModel = {} as any;
    this.roleEditModel = {} as any;
  }

  private openLoadingModal(): NgbModalRef {
    return this.modalService.open(this.loadingModal, {
      size: "sm",
      backdrop: "static"
    });
  }


  //region Data Event Handlers
  async handleAddUserClick() {
    this.clearModels();
    try {
      const ref = this.modalService.open(this.addEditUserModal, {size: "lg"});
      await ref.result;
    } catch (e) {
      // modal call cancelled
      this.clearModels();
      return;
    }

    const userRequest: PortalUser = {
      name: this.userModel.name,
      timezone: this.userModel.timezone,
      email: this.userModel.email,
      phone: this.userModel.phone ?? null,
      picture: this.userModel.picture ?? null
    } as PortalUser;
    const credsRequest: AddCredentialRequest = {
      integration_type: this.userModel.credentialSelection,
      email: null,
      password: null,
      subject: null,
    };
    switch (this.userModel.credentialSelection) {
      case PortalAuthProviders.PsygnalPassword:
        credsRequest.email = this.userModel.email;
        credsRequest.password = this.userModel.password;
        break;
      case PortalAuthProviders.PointClickCare:
        // Do nothing, as we are pushing a pending credentials rather than a mapped credentials
        break;
      case PortalAuthProviders.Office365:
        credsRequest.subject = this.userModel.msAccountId;
        break;
    }

    let user: PortalUser = null;
    const loadingModal = this.openLoadingModal();
    try {
      user = await this.rest.addUser(userRequest);
    } catch (err) {
      this.toast.showError("Error Adding User.");
      console.log("Error Adding User", err);
      loadingModal.close();
      this.clearModels();
      return;
    }
    let updatedUser: PortalUser = null;
    try {
      await this.rest.addUserCredential(user.external_id, credsRequest);
      updatedUser = await this.rest.getUserById(user.external_id);
      this.toast.showSuccess(`User '${user.name}' was successfully created.`);
    } catch (err) {
      this.toast.showWarning(`User '${user.name}' was successfully added, but there was an error when adding user credentials. You may have to specify credentials separately.`);
      console.log("Error Adding User Credentials", err);
    }
    const showModal = this.userModel.credentialSelection === PortalAuthProviders.PointClickCare;
    this.clearModels();
    await this.reloadUsers();
    loadingModal.close();
    if (showModal) {
      return this.showEditCredentialsModal(updatedUser, updatedUser.pcc_pending_integration);
    }
  }

  async handleDeleteUserClick(userModel: PortalUser) {
    try {
      const ref = this.modalService.open(this.deleteUserModal);
      await ref.result;
    } catch (e) {
      // do nothing on modal cancel
      return;
    }
    this.openLoadingModal();
    try {
      await this.rest.deleteUser(userModel.external_id);
      this.toast.showWarning(`User "${userModel.name}" permanently deleted`);
    } catch (err) {
      this.toast.showError("Error deleting user. User may have been partially but not completely deleted from system.");
      console.log("Error deleting user. ", err);
    }
    this.clearModels();
    await this.reloadUsers();
    this.modalService.dismissAll(); // necessary to close underlying Edit User modal.
  }

  async handleDeleteCredentialClick(user: PortalUser, cred: CredentialIntegration) {
    try {
      const ref = this.modalService.open(this.deleteCredentialModal);
      await ref.result;
    } catch (e) {
      // do nothing on modal cancel
      return;
    }
    this.openLoadingModal();
    try {
      await this.rest.deleteUserCredential(user.external_id, cred.integration_type);
      this.toast.showWarning("User unlinked from credential integration");
    } catch (err) {
      this.toast.showError("Error unlinking User from credential.");
      console.log("Error deleting credential. ", err);
    }
    this.clearModels();
    await this.reloadUsers();
    this.modalService.dismissAll(); // necessary to close underlying Edit User Credentials modal.
  }

  async handleEditProfileClick(user: PortalUser) {
    this.clearModels();
    this.userModel = Object.assign({
      isEdit: true
    }, user);
    try {
      const ref = this.modalService.open(this.addEditUserModal);
      await ref.result;
    } catch (e) {
      // modal call cancelled
      this.clearModels();
      return;
    }
    const fields = [
      new PatchField("name", this.userModel.name),
      new PatchField("timezone", this.userModel.timezone),
      new PatchField("email", this.userModel.email ?? null),
      new PatchField("phone", this.userModel.phone ?? null),
      new PatchField("picture", this.userModel.picture ?? null),
    ];
    const request = new PatchRequest(fields);
    const loading = this.openLoadingModal();
    try {
      await this.rest.patchUser(user.external_id, request);
      this.toast.showSuccess("User Profile Updated.");
    } catch (err) {
      this.toast.showError("Unspecified Error Updating User. Check console log for details.");
      console.log("Error Updating User", err);
    }
    this.clearModels();
    await this.reloadUsers();
    loading.close();
  }

  async handleEditAdminPortalClick(user: PortalUser) {
    this.clearModels();
    this.userModel = Object.assign({}, user);
    this.roleEditModel = buildRoleViewModel(user.roles);
    try {
      const ref = this.modalService.open(this.editAdminPortalModal);
      await ref.result;
    } catch (e) {
      // modal cancelled
      this.clearModels();
      return;
    }
    const roles = rebuildRoles(this.roleEditModel);
    const request = new PatchRequest([new PatchField("roles", roles)]);
    const loading = this.openLoadingModal();
    try {
      await this.rest.patchUser(user.external_id, request);
      this.toast.showSuccess("Admin Portal Access Updated");
    } catch (err) {
      this.toast.showError("Error Updating Admin Portal Access. Check logs for details.");
      console.log("Error Updating Admin Portal Access. ", err);
    }
    this.clearModels();
    await this.reloadUsers();
    loading.close();
  }

  // async handleEditClientPortalClick(user: PortalUser) {
  //   this.clearModels();
  //   this.userModel = Object.assign({}, user);
  //   this.roleEditModel = buildRoleViewModel(user.roles);
  //   try {
  //     const ref = this.modalService.open(this.editClientPortalModal);
  //     await ref.result;
  //   } catch (e) {
  //     // modal dismissed
  //     this.clearModels();
  //     return;
  //   }
  //   const roles = rebuildRoles(this.roleEditModel);
  //   const selected = this.userModel.client_integration_id;
  //   const request = new PatchRequest([
  //     new PatchField("roles", roles),
  //     new PatchField("client_integration_id", selected),
  //   ]);
  //   const loading = this.openLoadingModal();
  //   try {
  //     await this.rest.patchUser(user.external_id, request);
  //     this.toast.showSuccess("Client Portal Access Updated");
  //   } catch (err) {
  //     this.toast.showError("Error Updating Client Portal Access. Check logs for details.");
  //     console.log("Error Updating Client Portal Access. ", err);
  //   }
  //   this.clearModels();
  //   await this.reloadUsers();
  //   loading.close();
  // }

  async handleEditProviderPortalClick(user: PortalUser) {
    this.clearModels();
    this.userModel = Object.assign({}, user);
    this.roleEditModel = buildRoleViewModel(user.roles);
    try {
      const ref = this.modalService.open(this.editProviderPortalModal);
      await ref.result;
    } catch (e) {
      // modal dismissed
      this.clearModels();
      return;
    }
    const roles = rebuildRoles(this.roleEditModel);
    const selected = this.userModel.provider_integration_id;
    const request = new PatchRequest([
      new PatchField("roles", roles),
      new PatchField("provider_integration_id", selected),
    ]);
    const loading = this.openLoadingModal();
    try {
      await this.rest.patchUser(user.external_id, request);

      this.toast.showSuccess("Provider Portal Access Updated");
    } catch (err) {
      this.toast.showError("Error Updating Provider Portal Access. Check logs for details.");
      console.log("Error Updating Provider Portal Access. ", err);
    }
    this.clearModels();
    await this.reloadUsers();
    loading.close();
  }

  async handleResetPasswordClick(user: PortalUser) {
    try {
      const ref = this.modalService.open(this.resetPasswordModal);
      await ref.result;
    } catch (e) {
      // do nothing on cancel
      return;
    }
    this.openLoadingModal();
    try {
      await this.rest.resetUserPassword(user.external_id, {
        password: this.userModel.password
      });

      this.toast.showWarning(`User Password Changed`);
    } catch (err) {
      this.toast.showError("Error Changing Password. Check console for details.");
      console.log("Error Changing Password. ", err);
    }
    await this.reloadUsers();
    this.modalService.dismissAll(); // necessary to close underlying edit credentials modal
    this.clearModels();
  }

  async handleAddEditMicrosoftCredentialsClick(user: PortalUser) {
    if (user.ms_integration) {
      return this.showEditCredentialsModal(user, user.ms_integration);
    }

    // add MS credentials
    this.clearModels();
    this.userModel = Object.assign({}, user);
    try {
      const ref = this.modalService.open(this.addO365Modal);
      await ref.result;
    } catch (e) {
      // modal dismissed
      this.clearModels();
      return;
    }
    const request: AddCredentialRequest = {
      integration_type: PortalAuthProviders.Office365,
      subject: this.userModel.msAccountId,
      email: null,
      password: null
    };
    const loading = this.openLoadingModal();
    try {
      await this.rest.addUserCredential(this.userModel.external_id, request);
      this.toast.showSuccess("User linked to Forefront Microsoft Account.");
    } catch (err) {
      this.toast.showError("Error linking User to Forefront Microsoft Account.");
      console.log("Error adding credentials. ", err);
    }
    this.clearModels();
    await this.reloadUsers();
    loading.close();
  }

  async addEditPasswordCredentials(user: PortalUser) {
    if (user.pw_integration) {
      return this.showEditCredentialsModal(user, user.pw_integration);
    }
    // add password credentials
    this.clearModels();
    this.userModel = Object.assign({}, user);
    try {
      const ref = this.modalService.open(this.addPwModal);
      await ref.result;
    } catch (e) {
      // modal dismissed
      this.clearModels();
      return;
    }
    const request: AddCredentialRequest = {
      integration_type: PortalAuthProviders.PsygnalPassword,
      email: this.userModel.email,
      password: this.userModel.password,
      subject: null,
    };
    const loading = this.openLoadingModal();
    try {
      await this.rest.addUserCredential(this.userModel.external_id, request);
      this.toast.showSuccess("User linked to Forefront Password.");
    } catch (err) {
      this.toast.showError("Error linking User to Forefront Password.");
      console.log("Error adding credentials. ", err);
    }
    this.clearModels();
    await this.reloadUsers();
    loading.close();
  }

  async addEditPccCredentials(user: PortalUser) {
    let cred: CredentialIntegration = null;
    if (user.pcc_integration) {
      cred = user.pcc_integration;
    }
    if (user.pcc_pending_integration) {
      cred = user.pcc_pending_integration;
    }
    if (cred) {
      return this.showEditCredentialsModal(user, cred);
    }
    // add PCC credentials
    this.clearModels();
    this.userModel = Object.assign({}, user);
    try {
      const ref = this.modalService.open(this.addPccModal);
      await ref.result;
    } catch (e) {
      // modal dismissed
      this.clearModels();
      return;
    }
    const request: AddCredentialRequest = {
      integration_type: PortalAuthProviders.PointClickCare,
      email: null,
      password: null,
      subject: null,
    };
    const loading = this.openLoadingModal();
    const userId = this.userModel.external_id;
    let updatedUser: PortalUser = null;
    try {
      await this.rest.addUserCredential(userId, request);
      updatedUser = await this.rest.getUserById(userId);
      this.toast.showSuccess("User linked to Forefront Password.");
    } catch (err) {
      this.toast.showError("Error linking User to Forefront Password.");
      console.log("Error adding credentials. ", err);
    }
    this.clearModels();
    await this.reloadUsers();
    loading.close();
    if (updatedUser && updatedUser.pcc_pending_integration) {
      await this.showEditCredentialsModal(updatedUser, updatedUser.pcc_pending_integration);
    }
  }

  private async showEditCredentialsModal(user: PortalUser, cred: CredentialIntegration) {
    this.clearModels();
    this.userModel = Object.assign({}, user);
    this.credentialModel = Object.assign({}, cred);
    try {
      const ref = this.modalService.open(this.editCredentialModal);
      await ref.result;
      // modal is informational only (except for delete action, which is handled
      // elsewhere), so do nothing on either success or failure other than clear models.
      this.clearModels();
    } catch (e) {
      this.clearModels();
    }
  }

  //endregion
}



