<svg
  class="portals-pcc-icon"
  xmlns="http://www.w3.org/2000/svg"
  width="20px"
  height="20px"
  viewBox="0 0 20 20">
  <g fill="#97CC04" class="portals-pcc-icon-root-group">
    <g>
      <path
        d="M4.28571429,0 L4.28571429,5.87301587 L7.14285714,5.87301587 L7.14285714,2.85714286 L12.8571429,2.85714286 C15.4365079,2.89698389 17.1428571,4.40450535 17.1428571,7.27305937 C17.1428571,10.0348394 15.6253968,11.5758904 12.8571429,11.5758904 L12.8571429,14.4444444 C16.7328571,14.4444444 20,11.5758904 20,7.27305937 C20,2.97022833 17.4069841,0.101674304 12.8571429,0 L4.28571429,0 Z"/>
      <polyline
        points="7.14285714 18.7301587 7.14285714 14.4444444 11.4285714 14.4444444 11.4285714 11.5873016 7.14285714 11.5873016 7.14285714 7.3015873 4.28571429 7.3015873 4.28571429 11.5873016 0 11.5873016 0 14.4444444 4.28571429 14.4444444 4.28571429 18.7301587 7.14285714 18.7301587"/>
    </g>
  </g>
</svg>
