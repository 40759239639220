import {Injectable} from '@angular/core';

/**
 * Provides a unified interface for accessing local or session storage
 */
@Injectable({
  providedIn: 'root'
})
export class StorageService {
  readonly local = new InternalStorage(localStorage, "local");
  readonly session = new InternalStorage(sessionStorage, "session");

  constructor() {
  }
}

class InternalStorage {
  constructor(private storageMechanism: Storage,
              private mechanismName: string) {
  }

  get(key: string, parse = false) {
    try {
      const val = this.storageMechanism.getItem(key);
      if (!val) {
        return null;
      }
      if (parse) {
        return JSON.parse(val);
      }
      return val;
    } catch (e) {
      console.warn(`Error getting '${key}' from ${this.mechanismName} storage`, e);
      return null;
    }
  }

  getAs<T>(key): T {
    try {
      const val = this.storageMechanism.getItem(key);
      if (!val) {
        return null;
      }
      return JSON.parse(val);
    } catch (e) {
      console.warn(`Error getting '${key}' from ${this.mechanismName} storage`, e);
      return null;
    }
  }

  set(key: string, obj) {
    try {
      let val: string;
      if (typeof obj === "string") {
        val = obj;
      } else {
        val = JSON.stringify(obj);
      }
      this.storageMechanism.setItem(key, val);
    } catch (e) {
      console.warn(`Error setting '${key}' to ${this.mechanismName} storage`, e);
    }
  }

  delete(key: string) {
    this.storageMechanism.removeItem(key);
  }

  clearByPrefix(prefix: string) {
    const toClear: string[] = [];
    for (let i = 0; i < this.storageMechanism.length; i++) {
      const key = this.storageMechanism.key(i);
      if (key.startsWith(prefix)) {
        toClear.push(key);
      }
    }
    toClear.forEach(key => {
      this.delete(key);
    });
  }
}
