<div class="container-fluid">
  <ul class="breadcrumb">
    <li class="breadcrumb-item active">Athena Departments</li>
    <li class="breadcrumb-item" *ngIf="loading">
      <portals-loading-icon></portals-loading-icon>
    </li>
  </ul>
  <form #searchForm="ngForm">
<!--    <button class="btn btn-primary float-end ms-3"-->
<!--            [disabled]="loading"-->
<!--            (click)="handleAddClientClick()">-->
<!--      <i class="fas fa-plus fa-fw me-2"></i>-->
<!--      Add Client-->
<!--    </button>-->
    <fieldset [disabled]="loading">
      <div class="container-fluid mb-3">
        <div class="input-group flex-nowrap">
          <button class="btn btn-secondary dropdown-toggle"
                  ngbTooltip="Filter Results"
                  (click)="$event.stopPropagation(); searchDrop.open();">
            <i class="fas fa-sliders-h"></i>
          </button>
          <div ngbDropdown #searchDrop="ngbDropdown" [autoClose]="true"
               placement="bottom-left">
            <div ngbDropdownMenu class="p-2">
              <div class="clearfix">
                <button type="button" class="btn btn-sm btn-outline-info float-start"
                        (click)="clearForm(clearBtn); searchDrop.close();"
                        #clearBtn>
                  <i class="fas fa-eraser"></i>
                </button>
                <button type="button" class="btn-close float-end" #clearBtn
                        (click)="searchDrop.close()">
                </button>
              </div>
              <h6>Sort</h6>
              <div>
                <label class="small form-label m-0" for="sortField">By Field</label>
                <select name="sortField"
                        class="form-select"
                        (change)="updateSearch()"
                        [(ngModel)]="searchData.sortField"
                        id="sortField">
                  <option value="name" selected>Name</option>
                </select>
              </div>
              <div>
                <label class="small form-label m-0" for="sortOrder">Direction</label>
                <select name="sortOrder"
                        class="form-select"
                        (change)="updateSearch()"
                        [(ngModel)]="searchData.sortOrder"
                        id="sortOrder">
                  <option value="asc" selected>Ascending</option>
                  <option value="desc">Descending</option>
                </select>
              </div>
              <h6>Filter</h6>
              <div ngbTooltip="Show deleted, active, or all Departments">
                <label class="small form-label m-0" for="deletedOptions">By Deletion Status</label>
                <select name="deletedOptions"
                        class="form-select"
                        (change)="updateSearch()"
                        [(ngModel)]="searchData.deletedOptions"
                        id="deletedOptions">
                  <option [value]="DeletedOptions.active" selected>Active Only</option>
                  <option [value]="DeletedOptions.deleted">Deleted Only</option>
                  <option [value]="DeletedOptions.any">Any Status</option>
                </select>
              </div>
              <div ngbTooltip="Show Departments with the selected Care Coordinator">
                <label class="small form-label m-0" for="coordinatorOptions">By Care Coordinator</label>
                <select name="coordinatorOptions"
                        class="form-select"
                        (change)="updateSearch()"
                        [(ngModel)]="searchData.careCoordinator"
                        id="coordinatorOptions">
                  <option [value]="null" selected></option>
                  <option *ngFor="let coordinator of careCoordinators" [value]="coordinator">{{coordinator}}</option>
                </select>
              </div>
              <!-- temporarily removed -->
              <!--                <div ngbTooltip="Show only Clients that match the specified EHR integration">-->
              <!--                  <label class="small m-0" for="alertType">By EHR Integration</label>-->
              <!--                  <select name="alertType"-->
              <!--                          class="form-select"-->
              <!--                          (change)="updateSearch()"-->
              <!--                          [(ngModel)]="searchData.integration"-->
              <!--                          id="alertType">-->
              <!--                    <option value="" selected></option>-->
              <!--                    <option value="pcc">PointClickCare</option>-->
              <!--                    <option value="epic" disabled>Epic</option>-->
              <!--                  </select>-->
              <!--                </div>-->
              <div class="form-check form-switch"
                   ngbTooltip="Show only Departments that do not have Zoom links specified">
                <input type="checkbox" class="form-check-input"
                       id="missingZoom"
                       name="missingZoom"
                       (change)="updateSearch()"
                       [(ngModel)]="searchData.missingZoom">
                <label class="small form-check-label text-nowrap" for="missingZoom">
                  Missing Zoom Integration
                </label>
              </div>
              <div class="form-check form-switch"
                   ngbTooltip="Show only Departments that are not linked to a Salesforce SOW record">
                <input type="checkbox" class="form-check-input"
                       id="missingSalesforce"
                       name="missingSalesforce"
                       (change)="updateSearch()"
                       [(ngModel)]="searchData.missingSalesforce">
                <label class="small form-check-label text-nowrap" for="missingSalesforce">
                  Missing Salesforce Integration
                </label>
              </div>
              <div class="form-check form-switch"
                   ngbTooltip="Show only Departments that do not have Contacts specified">
                <input type="checkbox" class="form-check-input"
                       id="missingContacts"
                       name="missingContacts"
                       (change)="updateSearch()"
                       [(ngModel)]="searchData.missingContacts">
                <label class="small form-check-label text-nowrap" for="missingContacts">
                  Missing Contact Information
                </label>
              </div>
              <div class="form-check form-switch"
                   ngbTooltip="Show only Departments that do not have a care coordinator specified">
                <input type="checkbox" class="form-check-input"
                       id="missingCareCoordinator"
                       name="missingCareCoordinator"
                       (change)="updateSearch()"
                       [(ngModel)]="searchData.missingCareCoordinator">
                <label class="small form-check-label text-nowrap" for="missingCareCoordinator">
                  No Care Coordinator Specified
                </label>
              </div>
            </div>
          </div>

          <input type="search" class="form-control" placeholder="Search Departments by name"
                 #searchControl
                 (keydown)="updateSearch()"
                 (search)="updateSearch()"
                 [disabled]="loading"
                 [(ngModel)]="searchData.searchValue"
                 name="search">
          <button class="btn btn-secondary z-0"
                  (click)="updateSearch()"
                  [disabled]="loading">
            <i class="fas fa-search"></i>
          </button>
        </div>
      </div>
    </fieldset>
  </form>
<!--  <h4 *ngIf="!loading || clients && clients.length > 0">Client Portal Clients And Associated Departments</h4>-->
<!--  <div>-->
<!--    <div class="d-flex flex-wrap align-items-start">-->
<!--      <div class="text-center" *ngIf="!loading && clients && clients.length === 0">-->
<!--        <em>No Clients have been created yet</em>-->
<!--      </div>-->
<!--      <div class="client"-->
<!--           *ngFor="let client of clients | sort:searchData.sortField:searchData.sortOrder | filterFunc:searchFilter:searchUpdated">-->
<!--        <div class="client-header">-->
<!--          <button (click)="handleEditClientClick(client)"-->
<!--                  class="btn btn-sm btn-link shadow-none me-1">-->
<!--            <i class="fas fa-edit"></i>-->
<!--          </button>-->
<!--          <div class="fw-bold client-title text-truncate"-->
<!--               [ngbTooltip]="client.name">-->
<!--            <span [innerHTML]="client.name | markResult:searchRegexs"></span>-->
<!--          </div>-->
<!--          <portals-pcc-icon *ngIf="showPccIcon(client)"-->
<!--                            ngbTooltip="Client EHR Integration Through PointClickCare"-->
<!--                            class="me-3 float-end"></portals-pcc-icon>-->
<!--        </div>-->
<!--        <div class="d-flex flex-wrap flex-column align-items-start align-content-center client-body">-->
<!--          <div-->
<!--            *ngFor="let department of client.facilities | sort:searchData.sortField:searchData.sortOrder | filterFunc:searchFilterDepartments:searchUpdated">-->
<!--            <ng-container [ngTemplateOutlet]="departmentView"-->
<!--                          [ngTemplateOutletContext]="{department: department}"></ng-container>-->
<!--          </div>-->
<!--          <p *ngIf="!client.facilities || client.facilities.length === 0">-->
<!--            <em class="m-1">No Departments associated with this Client</em>-->
<!--          </p>-->
<!--          <p *ngIf="(client.facilities | filterFunc:searchFilterDepartments:searchUpdated)?.length === 0">-->
<!--            <em class="m-1">No Departments matched the search</em>-->
<!--          </p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
  <h4 *ngIf="departments && departments.length > 0">Athena Departments</h4>
  <div>
    <div class="d-flex flex-wrap">
      <div
        *ngFor="let department of departments | sort:searchData.sortField:searchData.sortOrder | filterFunc:searchFilterDepartments:searchUpdated">
        <ng-container [ngTemplateOutlet]="departmentView"
                      *ngIf="!inPersonDepartmentsById.has(department.external_id)"
                      [ngTemplateOutletContext]="{department: department}"></ng-container>
      </div>
    </div>
  </div>
</div>

<!-- modals and sub-components -->
<!-- Edit Portal Client Modal -->
<!--<ng-template #addEditClientModal let-modal>-->
<!--  <div class="modal-header">-->
<!--    <h4 class="modal-title">-->
<!--      {{ clientModel.external_id ? "Edit" : "Add" }}-->
<!--      Client-->
<!--    </h4>-->
<!--    <button (click)="modal.dismiss()" class="btn btn-link"-->
<!--            type="button">-->
<!--      <i class="fas fa-lg fa-window-close"></i>-->
<!--    </button>-->
<!--  </div>-->
<!--  <div class="modal-body">-->
<!--    <form #addEditClientForm="ngForm">-->
<!--      <div>-->
<!--        <label class="required form-label" for="client_name">Display Name *</label>-->
<!--        <div class="input-group">-->
<!--          <input [(ngModel)]="clientModel.name" class="form-control" id="client_name"-->
<!--                 name="client_name"-->
<!--                 maxlength="255"-->
<!--                 required-->
<!--                 type="text">-->
<!--        </div>-->
<!--      </div>-->
<!--      <fieldset>-->
<!--        <legend>-->
<!--          Client EHR Integration-->
<!--          <button class="btn btn-link small btn-sm float-end shadow-none"-->
<!--                  (click)="clientModel['integrationDisabled'] = !clientModel['integrationDisabled']">-->
<!--            toggle edit-->
<!--          </button>-->
<!--        </legend>-->
<!--        <div class="me-1">-->
<!--          <label class="form-label" for="integration_type">Integration Type</label>-->
<!--          <select name="integration_type"-->
<!--                  [disabled]="clientModel['integrationDisabled']"-->
<!--                  class="form-select"-->
<!--                  [(ngModel)]="clientModel.integration_type"-->
<!--                  id="integration_type">-->
<!--            <option value="" selected></option>-->
<!--            <option value="pcc">PointClickCare</option>-->
<!--            <option value="epic" disabled>Epic</option>-->
<!--          </select>-->
<!--        </div>-->
<!--        <div>-->
<!--          <label class="form-label" for="integration_client_id">Integration ID</label>-->
<!--          <div class="input-group">-->
<!--            <input [(ngModel)]="clientModel.integration_client_id" class="form-control" id="integration_client_id"-->
<!--                   name="integration_client_id"-->
<!--                   [disabled]="clientModel['integrationDisabled']"-->
<!--                   maxlength="255"-->
<!--                   type="text">-->
<!--          </div>-->
<!--        </div>-->
<!--      </fieldset>-->
<!--    </form>-->
<!--  </div>-->
<!--  <div class="modal-footer">-->
<!--    <button *ngIf="clientModel.external_id"-->
<!--            (click)="handleDeleteClient(clientModel)"-->
<!--            class="btn btn-danger me-auto" type="button">Delete Client-->
<!--    </button>-->
<!--    <button (click)="modal.dismiss()"-->
<!--            autofocus-->
<!--            class="btn btn-outline-primary me-4" type="button">Cancel-->
<!--    </button>-->
<!--    <button (click)="modal.close('save')" [disabled]="!addEditClientForm.dirty || !addEditClientForm.valid"-->
<!--            class="btn btn-secondary" type="button">-->
<!--      Save-->
<!--    </button>-->
<!--  </div>-->
<!--</ng-template>-->

<!-- Edit Department Modal -->
<ng-template #editDepartmentModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      Edit Athena Department
    </h4>
    <button (click)="modal.dismiss()" class="btn btn-link shadow-none"
            type="button">
      <i class="fas fa-lg fa-window-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <label>Department Name</label>
      <div class="input-group ps-4">
        <em>{{departmentModel.name}}</em>
      </div>
    </div>
    <div>
      <label>Linked Salesforce SOW</label>
      <div class="row ps-4">
        <div class="col-10">
          <em *ngIf="!departmentModel.salesforce_sow_id">[none]</em>
          <ng-container *ngIf="departmentModel.salesforce_sow_id"
                        [ngTemplateOutlet]="salesforceMappedSOW"
                        [ngTemplateOutletContext]="{sow: mapSow(departmentModel.salesforce_sow_id)}"></ng-container>
        </div>
        <div class="col-2">
          <button *ngIf="departmentModel.salesforce_sow_id"
                  class="btn btn-sm btn-secondary float-end"
                  (click)="handleRemoveSalesforceSowLinkClick(departmentModel)">
            remove link
          </button>
          <button *ngIf="!departmentModel.salesforce_sow_id"
                  class="btn btn-sm btn-secondary float-end"
                  (click)="handleSetSalesforceSowLinkClick(modal)">
            set link
          </button>
        </div>
      </div>
    </div>
    <form #editDepartmentForm="ngForm">
      <div>
        <label class=" form-label" for="care_coordinator">Care Coordinator</label>
        <ng-select [(ngModel)]="departmentModel.care_coordinator"
                   [items]="careCoordinators"
                   addTag="true"
                   addTagText="Add new Care Coordinator"
                   maxlength="255"
                   id="care_coordinator"
                   name="care_coordinator">
        </ng-select>
      </div>
      <div>
        <button class="btn btn-link small btn-sm float-end shadow-none"
                (click)="departmentModel['integrationDisabled'] = !departmentModel['integrationDisabled']">
          toggle edit
        </button>
        <label class=" form-label" for="in_person_external_id">In Person Department</label>
        <ng-select [(ngModel)]="departmentModel.in_person_external_id" [clearable]="true"
                   [disabled]="departmentModel['integrationDisabled']"
                   id="in_person_external_id"
                   name="in_person_external_id">
<!--          <ng-option [value]="null" ><span class="text-sm">(not selected)</span></ng-option>-->
          <ng-option *ngFor="let dept of departments | sort:'name'"
                     [disabled]="linkedDepartments.has(dept.external_id) || departmentModel.external_id === dept.external_id || dept.is_deleted"
                     [value]="dept.external_id">{{dept.name}}</ng-option>
        </ng-select>
      </div>
<!--      <div>-->
<!--        <label class=" form-label" for="client_external_id">Client</label>-->
<!--        <ng-select [(ngModel)]="departmentModel.client_external_id"-->
<!--                   [items]="clients"-->
<!--                   bindLabel="name"-->
<!--                   bindValue="external_id"-->
<!--                   id="client_external_id"-->
<!--                   name="client_external_id">-->
<!--        </ng-select>-->
<!--      </div>-->
<!--        <div>-->
<!--        <label class=" form-label" for="integration_client_facility_id">-->
<!--          Client EHR Integration Facility ID-->
<!--        </label>-->
<!--        <div class="input-group">-->
<!--          <input [(ngModel)]="departmentModel.integration_client_facility_id"-->
<!--                 class="form-control"-->
<!--                 [disabled]="departmentModel['integrationDisabled']"-->
<!--                 id="integration_client_facility_id"-->
<!--                 maxlength="100"-->
<!--                 name="integration_client_facility_id"-->
<!--                 type="text">-->
<!--        </div>-->
<!--      </div>-->
      <div class="form-check form-switch">
        <input type="checkbox" class="form-check-input"
               [disabled]="departmentModel['integrationDisabled']"
               id="use_hospital_workflow"
               name="use_hospital_workflow"
               [(ngModel)]="departmentModel.use_hospital_workflow">
        <label class="small form-check-label text-nowrap" for="use_hospital_workflow">
          Participate in Hospital Next Day Appointment Workflow
        </label>
      </div>
      <div *ngIf="departmentModel?.appointment_schedule_contacts?.length > 0"><em>Contacts</em></div>
      <div class="ms-2 small row" *ngFor="let contact of departmentModel.appointment_schedule_contacts | sort:'name'">
        <div class="col">
          <i class="fas fa-fw fa-bell text-primary"
             ngbTooltip="Contact Receives Appointment Schedule Reminder Emails"
             *ngIf="contact.send_schedule_email"></i>
          <i class="fas fa-fw fa-bell-slash text-info"
             ngbTooltip="Contact Does Not Receive Appointment Schedule Reminder Emails"
             *ngIf="!contact.send_schedule_email"></i>
          {{contact.name}}
        </div>
        <div class="col">
          role: {{contact.role}}
        </div>
        <div class="col">
          phone: {{contact.phone}}
        </div>
        <div class="col">
          email:
          <a [href]="'mailto://' + contact.email" target="_blank">
            {{contact.email}}
          </a>
        </div>
      </div>
      <div *ngIf="departmentModel?.zoom_links?.length > 0"><em>Telehealth Links</em></div>
      <div class="ms-2 small row">
        <div class="col">
          <span *ngFor="let zoom of departmentModel.zoom_links | sort:'name'; last as isLast">
            {{zoom.name}}<span *ngIf="!isLast">,</span>
          </span>
        </div>
      </div>
      <div class="d-flex flex-row justify-content-around mt-4">
        <button class="btn btn-secondary"
                [disabled]="editDepartmentForm.dirty"
                (click)="handleEditContactsClick(modal); ">
          Edit Appointment Contacts
        </button>
        <button class="btn btn-secondary"
                [disabled]="editDepartmentForm.dirty"
                (click)="handleEditZoomClick(modal)">
          Edit Telehealth Links
        </button>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button (click)="modal.dismiss()"
            autofocus
            class="btn btn-outline-primary me-4" type="button">Cancel
    </button>
    <button (click)="modal.close('save')"
            [disabled]="!editDepartmentForm.dirty || !editDepartmentForm.valid"
            class="btn btn-secondary" type="button">
      Save
    </button>
  </div>
</ng-template>

<!-- Edit Department Zoom Links Modal -->
<ng-template #editZoomsModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      Edit Department Zoom Links
    </h4>
    <button (click)="modal.dismiss()" class="btn btn-link"
            type="button">
      <i class="fas fa-lg fa-window-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <label>Department Name</label>
      <div class="input-group ps-4">
        <em>{{zoomModel.name}}</em>
      </div>
    </div>
    <form #editZoomsForm="ngForm">
      <div class="row">
        <div class="col-10">
          <div><strong>Zoom Links</strong></div>
          <div class="ms-2 small"><em>(All fields required)</em></div>
        </div>
        <div class="col-2">
          <button class="btn btn-lg btn-link rounded-pill text-primary shadow-none"
                  (click)="handleAddZoom(editZoomsForm)">
            <i class="fas fa-plus-circle fa-fw"></i>
          </button>
        </div>
      </div>
      <div class="mb-2 row small"
           *ngFor="let zoom of zoomModel.zoom_links; let i = index;">
        <div class="col-4">
          <div>
            <label class=" form-label" [for]="'appointment_zoom_name_' + i">Name</label>
            <div class="input-group">
              <input [(ngModel)]="zoom.name"
                     required
                     class="form-control"
                     [id]="'appointment_zoom_name_' + i"
                     [name]="'appointment_zoom_name_' + i"
                     maxlength="100"
                     type="text">
            </div>
          </div>
        </div>
        <div class="col-6">
          <div>
            <label class=" form-label" [for]="'appointment_zoom_url_' + i">Url</label>
            <div class="input-group">
              <input [(ngModel)]="zoom.url"
                     required pattern="https://.*|zoommtg://.*|zoomus://.*"
                     class="form-control"
                     [id]="'appointment_zoom_url_' + i"
                     [name]="'appointment_zoom_url_' + i"
                     maxlength="255"
                     type="url">
            </div>
          </div>
        </div>
        <div class="col-2">
          <button class="btn btn-lg btn-link rounded-pill text-danger mt-4 shadow-none"
                  (click)="handleRemoveZoom(editZoomsForm, i)">
            <i class="fas fa-minus-circle fa-fw"></i>
          </button>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button (click)="modal.dismiss()"
            autofocus
            class="btn btn-outline-primary me-4" type="button">Cancel
    </button>
    <button (click)="modal.close('save')" [disabled]="!editZoomsForm.dirty || !editZoomsForm.valid"
            class="btn btn-secondary" type="button">
      Save
    </button>
  </div>
</ng-template>

<!-- Edit Department Contacts Modal -->
<ng-template #editContactsModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      Edit Department Contacts
    </h4>
    <button (click)="modal.dismiss()" class="btn btn-link"
            type="button">
      <i class="fas fa-lg fa-window-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <label>Department Name</label>
      <div class="input-group ps-4">
        <em>{{contactsModel.name}}</em>
      </div>
    </div>
    <form #editContactsForm="ngForm">
      <div class="row">
        <div class="col-10">
          <div><strong>Appointment Contacts</strong></div>
          <div class="ms-2 small"><em>(All fields required)</em></div>
        </div>
        <div class="col-2">
          <button class="btn btn-lg btn-link rounded-pill text-primary shadow-none"
                  (click)="handleAddContact(editContactsForm)">
            <i class="fas fa-plus-circle fa-fw"></i>
          </button>
        </div>
      </div>
      <div class="small"
           *ngFor="let contact of contactsModel.appointment_schedule_contacts; let i = index;">
        <div class="row ps-1 pe-1">
          <div class="col-3 p-0 pe-1">
            <div class="m-0">
              <label class=" form-label" [for]="'appointment_contact_name_' + i">Name</label>
              <div class="input-group">
                <input [(ngModel)]="contact.name"
                       required
                       class="form-control"
                       [id]="'appointment_contact_name_' + i"
                       [name]="'appointment_contact_name_' + i"
                       maxlength="100"
                       type="text">
              </div>
            </div>
          </div>
          <div class="col-2 p-0 pe-1">
            <div class="m-0">
              <label class=" form-label" [for]="'appointment_contact_role_' + i">Role</label>
              <div class="input-group">
                <input [(ngModel)]="contact.role"
                       required
                       class="form-control"
                       [id]="'appointment_contact_role_' + i"
                       [name]="'appointment_contact_role_' + i"
                       maxlength="25"
                       type="text">
              </div>
            </div>
          </div>
          <div class="col-2 p-0 pe-1">
            <div class="m-0">
              <label class=" form-label" [for]="'appointment_contact_phone_' + i">Phone</label>
              <div class="input-group">
                <input [(ngModel)]="contact.phone"
                       required
                       class="form-control"
                       [id]="'appointment_contact_phone_' + i"
                       [name]="'appointment_contact_phone_' + i"
                       maxlength="25"
                       type="tel">
              </div>
            </div>
          </div>
          <div class="col-4 p-0 pe-1">
            <div class="m-0">
              <label class=" form-label" [for]="'appointment_contact_email_' + i">Email</label>
              <div class="input-group">
                <input [(ngModel)]="contact.email"
                       required email
                       class="form-control"
                       [id]="'appointment_contact_email_' + i"
                       [name]="'appointment_contact_email_' + i"
                       maxlength="255"
                       type="email">
              </div>
            </div>
          </div>
          <div class="col-1">
            <button class="btn btn-lg btn-link rounded-pill text-danger mt-4 shadow-none"
                    (click)="handleRemoveContact(editContactsForm, i)">
              <i class="fas fa-minus-circle fa-fw"></i>
            </button>
          </div>
        </div>
        <div class="form-check form-switch">
          <input type="checkbox" class="form-check-input"
                 [id]="'appointment_contact_send_schedule_email_' + i"
                 [name]="'appointment_contact_send_schedule_email_' + i"
                 [(ngModel)]="contact.send_schedule_email">
          <label class="form-check-label text-nowrap" style="padding-top: .15rem;"
                 [for]="'appointment_contact_send_schedule_email_' + i">
            <i class="fas fa-fw fa-bell text-primary"></i>
            Contact Receives Appointment Schedule Reminders by Email
          </label>
        </div>
        <hr class="mb-2">
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button (click)="modal.dismiss()"
            autofocus
            class="btn btn-outline-primary me-4" type="button">Cancel
    </button>
    <button (click)="modal.close('save')" [disabled]="!editContactsForm.dirty || !editContactsForm.valid"
            class="btn btn-secondary" type="button">
      Save
    </button>
  </div>
</ng-template>

<!-- Loading Icon Modal -->
<ng-template #loadingModal let-modal>
  <portals-loading-icon class="text-center"
                        style="font-size: 45pt"></portals-loading-icon>
</ng-template>

<!-- Delete Client Confirmation Modal -->
<!--<ng-template #deleteClientModal let-modal>-->
<!--  <div class="modal-header">-->
<!--    <h4 class="modal-title">Delete Client</h4>-->
<!--    <button (click)="modal.dismiss()" class="btn btn-link"-->
<!--            type="button">-->
<!--      <i class="fas fa-lg fa-window-close"></i>-->
<!--    </button>-->
<!--  </div>-->
<!--  <div class="modal-body">-->
<!--    <p>You are about to delete the following Client</p>-->
<!--    <p class="ms-2 font-italic">{{clientModel.name}}</p>-->
<!--    <p>-->
<!--      This will result in all Departments associated with the Client becoming linked,-->
<!--      and will also remove access to the Client Portal for any Users associated with this-->
<!--      Client.-->
<!--    </p>-->
<!--    <p class="p-2 text-white bg-danger">-->
<!--      This action cannot be undone, and if done in error you will have to manually remap-->
<!--      Departments and Users.-->
<!--    </p>-->
<!--    <p>Are you <em>certain</em> you want to delete this Client?</p>-->
<!--  </div>-->
<!--  <div class="modal-footer">-->
<!--    <button (click)="modal.dismiss()"-->
<!--            autofocus-->
<!--            class="btn btn-outline-primary me-auto" type="button">Cancel-->
<!--    </button>-->
<!--    <button (click)="modal.close('delete')"-->
<!--            class="btn btn-danger" type="button">Delete Client-->
<!--    </button>-->
<!--  </div>-->
<!--</ng-template>-->

<!-- Set Salesforce SOW Link Modal -->
<ng-template #setSalesforceSowLinkModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      Set Department Salesforce SOW
    </h4>
    <button (click)="modal.dismiss()" class="btn btn-link"
            type="button">
      <i class="fas fa-lg fa-window-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <label>Department Name</label>
      <div class="input-group ps-4">
        <em>{{salesforceModel.name}}</em>
      </div>
    </div>
    <div>
      <label>Linked Salesforce SOW</label>
      <div class="row ps-4">
        <div class="col-10">
          <em *ngIf="!salesforceModel.salesforce_sow_id">[none]</em>

          <ng-container *ngIf="salesforceModel.salesforce_sow_id"
                        [ngTemplateOutlet]="salesforceMappedSOW"
                        [ngTemplateOutletContext]="{sow: mapSow(salesforceModel.salesforce_sow_id)}"></ng-container>
        </div>
      </div>
    </div>
    <form #editSalesforceForm="ngForm">
      <h5 class="mt-2">Select Salesforce SOW</h5>
      <div class="input-group d-flex flex-nowrap container-fluid">
        <input type="search" class="form-control" placeholder="Search Salesforce SOW Records"
               #salesforceSearchControl
               (keydown)="updateSalesforceSearch()"
               (search)="updateSalesforceSearch()"
               [(ngModel)]="salesforceSearch"
               name="search">
        <div>
          <button class="btn btn-secondary z-0"
                  (click)="updateSalesforceSearch()">
            <i class="fas fa-search"></i>
          </button>
        </div>
      </div>
      <div style="max-height: 15em;" class="scroll-y mt-3 container">
        <div class="mb-2 pb-1 pt-1 row"
             [class.bg-secondary]="even"
             [class.text-white]="even"
             *ngFor="let sow of salesforceSows | sort:'name':'asc' | filterFunc:searchFilterSalesforce:searchUpdated; let even=even; let odd=odd">
          <div class="col-2 text-center">
            <button (click)="salesforceModel.salesforce_sow_id = sow.id; editSalesforceForm.form.markAsDirty();"
                    *ngIf="salesforceModel.salesforce_sow_id !== sow.id && !departmentsBySalesforceId.has(sow.id)"
                    [class.btn-light]="even"
                    [class.btn-secondary]="odd"
                    class="btn btn-sm d-inline-block mt-3">select
            </button>
            <em class="small d-inline-block mt-3" *ngIf="departmentsBySalesforceId.has(sow.id)">
              (already linked)
            </em>
            <em class="small d-inline-block mt-3" *ngIf="salesforceModel.salesforce_sow_id == sow.id">
              (selected)
            </em>
          </div>
          <div class="col-10 container-fluid">
            <ng-container [ngTemplateOutlet]="salesforceMappedSOW"
                          [ngTemplateOutletContext]="{sow: mapSow(sow.id), mark: true}"></ng-container>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button (click)="modal.dismiss()"
            autofocus
            class="btn btn-outline-primary me-4" type="button">Cancel
    </button>
    <button (click)="modal.close('save')" [disabled]="!editSalesforceForm.dirty"
            class="btn btn-secondary" type="button">
      Save
    </button>
  </div>
</ng-template>

<!-- Salesforce SOW View Template -->
<ng-template #salesforceMappedSOW let-sow="sow" let-mark="mark">
  <div *ngIf="sow">
    <div class="text-truncate">
      <em *ngIf="mark" [innerHTML]="sow.name | markResult:salesforceRegexes"></em>
      <em *ngIf="!mark">{{sow.name}}</em>
    </div>
    <ng-container *ngIf="!sow.accountName">
      <div class="sow-account-header"><span class="fw-bold pe-2">Parent Account:</span> none</div>
    </ng-container>
    <ng-container *ngIf="sow.accountName">
      <div class="fw-bold sow-account-header">Parent Account</div>
      <div class="row">
        <div class="col-2 sow-account-header fw-bold text-end">Name:</div>
        <div class="col small p-0">{{sow.accountName}}</div>
        <div class="col-2 sow-account-header fw-bold text-end">Status:</div>
        <div class="col-2 small p-0">{{sow.accountStatus}}</div>
      </div>
      <div class="row">
        <div class="col-2 sow-account-header fw-bold text-end">Location:</div>
        <div class="col small  p-0">
          <span *ngIf="sow.accountCity">{{sow.accountCity}}, </span>
          {{sow.accountState}}
        </div>
        <div class="col-2 sow-account-header fw-bold text-end">Intacct ID:</div>
        <div class="col-2 small p-0 text-truncate">
        <span [ngbTooltip]="sow.accountIntacctId" placement="left">
          {{sow.accountIntacctId}}
        </span>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

<!-- Remove Salesforce SOW Link Confirmation Modal -->
<ng-template #deleteSalesforceSowLinkModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Remove Salesforce SOW Link</h4>
    <button (click)="modal.dismiss()" class="btn btn-link"
            type="button">
      <i class="fas fa-lg fa-window-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <label>Department Name</label>
      <div class="input-group ps-4">
        <em>{{departmentModel.name}}</em>
      </div>
    </div>
    <div>
      <label>Current Linked Salesforce SOW</label>
      <div class="row ps-4">
        <div class="col-10">
          <ng-container [ngTemplateOutlet]="salesforceMappedSOW"
                        [ngTemplateOutletContext]="{sow: mapSow(departmentModel.salesforce_sow_id)}"></ng-container>
        </div>
        <div class="col-2">&nbsp;</div>
      </div>
    </div>
    <p>
      You are about to remove the link Between the Athena Department and the Salesforce SOW
      listed above.
    </p>
    <p>
      In addition to removing the link between the Department and the Salesforce SOW, all
      Zoom Links associated with the Salesforce SOW through its linked Salesforce Cart objects
      will be removed.
    </p>
    <p class="p-2 text-white bg-warning">
      While the Salesforce Cart deletion cannot be undone, the Salesforce SOW
      and Athena Department will remain unchanged other than the removal of the link. If done in
      error you can re-establish the link to refresh the Salesforce Cart objects with the Department's
      Zoom Link data.
    </p>
    <p>Are you <em>certain</em> you want to remove this link?</p>
  </div>
  <div class="modal-footer">
    <button (click)="modal.dismiss()"
            autofocus
            class="btn btn-outline-primary me-auto" type="button">Cancel
    </button>
    <button (click)="modal.close('delete')"
            class="btn btn-danger" type="button">Remove Link
    </button>
  </div>
</ng-template>

<!-- Department View Template -->
<ng-template #departmentView let-department="department">
  <div class="department"
       [class.border-secondary]="department.is_deleted">
    <div class="dept-header">
      <button (click)="handleEditDepartmentClick(department)"
              [disabled]="department.is_deleted"
              class="btn btn-sm btn-link shadow-none d-inline-block pt-0">
        <i class="fas fa-edit"></i>
      </button>
      <div class="text-truncate dept-title"
           [ngbTooltip]="department.name"
           [class.fw-bold]="!department.is_deleted"
           [class.font-italic]="department.is_deleted">
        <span [innerHTML]="department.name | markResult:searchRegexs"></span>
      </div>
    </div>
    <div class="dept-body" *ngIf="!department.is_deleted">
      <div class="m-1">
        <div class="d-inline-block icon-val">
          <i *ngIf="isMissingZoom(department)"
             ngbTooltip="This Department has no defined Telehealth Links"
             class="fas fa-fw fa-video-slash text-danger"></i>
          <div *ngIf="!isMissingZoom(department)">
            <ng-template #zoomTip>
              <div><em>Telehealth Links</em></div>
              <ul>
                <li class="text-start" *ngFor="let zoom of department.zoom_links">{{zoom.name}}</li>
              </ul>
            </ng-template>
            <i [ngbTooltip]="zoomTip" class="fas fa-fw fa-video text-primary"></i>
            {{department.zoom_links?.length || 0 }}
          </div>
        </div>
        <div class="d-inline-block icon-val">
          <div *ngIf="isMissingContacts(department)"
               ngbTooltip="This Department has no Client contacts specified"
               class="slash-stack fa-fw text-danger">
            <i class="fas fa-address-card fa-stack-1x"></i>
            <i class="fas fa-slash fa-stack-1x slash-shadow"></i>
          </div>
          <div *ngIf="!isMissingContacts(department)">
            <ng-template #contactsTip>
              <div><em>Client Contacts</em></div>
              <ul>
                <li class="text-start" *ngFor="let contact of department.appointment_schedule_contacts">
                  <i class="fas fa-fw fa-bell text-primary"
                     ngbTooltip="Contact Receives Appointment Schedule Reminder Emails"
                     *ngIf="contact.send_schedule_email"></i>
                  <i class="fas fa-fw fa-bell-slash text-info"
                     ngbTooltip="Contact Does Not Receive Appointment Schedule Reminder Emails"
                     *ngIf="!contact.send_schedule_email"></i>
                  {{contact.name}}
                  <span *ngIf="contact.email">({{contact.email}})</span>
                </li>
              </ul>
            </ng-template>
            <i class="fas fa-fw fa-address-card text-primary" [ngbTooltip]="contactsTip"></i>
            {{department.appointment_schedule_contacts?.length || 0 }}
          </div>
        </div>
        <div class="d-inline-block icon-val">
          <div *ngIf="isMissingProviders(department)"
               ngbTooltip="This Department has no Providers assigned"
               class="slash-stack fa-fw text-danger">
            <i class="fas fa-user-md fa-stack-1x"></i>
            <i class="fas fa-slash fa-stack-1x slash-shadow"></i>
          </div>
          <div *ngIf="!isMissingProviders(department)">
            <ng-template #providersTip>
              <div><em>Department Providers</em></div>
              <ul>
                <li class="text-start" *ngFor="let contact of department.providers">{{contact.display_name}}</li>
              </ul>
            </ng-template>
            <i [ngbTooltip]="providersTip" class="fas fa-fw fa-user-md text-primary"></i>
            {{department.providers?.length || 0 }}
          </div>
        </div>
        <div class="d-inline-block icon-val">
          <div *ngIf="department.salesforce_sow_id">
            <ng-template #salesforceTip>
              <div class="text-start">
                <div><em>SOW:</em></div>
                <div class="ms-2">
                  {{mapSow(department.salesforce_sow_id)?.name}}
                </div>
                <ng-container *ngIf="mapSow(department.salesforce_sow_id)?.accountName">
                  <div><em>Parent Account:</em></div>
                  <div class="ms-2">
                    {{mapSow(department.salesforce_sow_id)?.accountName}}
                  </div>
                </ng-container>
              </div>
            </ng-template>
            <i [ngbTooltip]="salesforceTip"
               class="fab fa-salesforce text-primary "></i>
          </div>
          <div *ngIf="!department.salesforce_sow_id"
               ngbTooltip="This Department is not associated with a Salesforce SOW"
               class="slash-stack fa-fw text-danger">
            <i class="fab fa-salesforce fa-stack-1x"></i>
            <i class="fas fa-slash fa-stack-1x slash-shadow"></i>
          </div>
        </div>
        <div class="d-inline-block icon-val">
          <i *ngIf="department.use_hospital_workflow"
             ngbTooltip="This Department participates in Hospital next day Appointment workflow"
             class="fas fa-fw fa-hospital-alt text-primary"></i>
          <div *ngIf="!department.use_hospital_workflow"
               ngbTooltip="This Department does not participate in Hospital next day Appointment workflow"
               class="slash-stack fa-fw text-info">
            <i class="fas fa-hospital-alt fa-stack-1x"></i>
            <i class="fas fa-slash fa-stack-1x slash-shadow"></i>
          </div>
        </div>
      </div>
      <div class="m-1 position-relative">
        <div class="d-inline-block icon-val">
          <i class="fas fa-fw fa-map-marker-alt text-primary"></i>
          <span class="ms-1">{{department.state}}</span>
        </div>
        <div class="d-inline-block dept-timezone">
          <portals-timezone-icon class="text-primary small tz-icon mt-1"></portals-timezone-icon>
          <span class="ms-4">
          {{MapTimezoneCodeToName(department.timezone)}}
        </span>
        </div>
        <ng-template #coordinatorTip>
          <div><em>Care Coordinator</em></div>
          <ul>
            <li class="text-start">{{department.care_coordinator ?? "n/a"}}</li>
          </ul>
        </ng-template>
        <div [ngbTooltip]="coordinatorTip" class="d-inline-block care-coordinator text-truncate position-absolute">
          <i class="fas fa-fw fa-user-clock text-primary"></i>
          <span class="ms-1">{{department.care_coordinator ?? "n/a"}}</span>
        </div>
      </div>
      <div class="m-1 text-truncate text-sm"
           [ngbTooltip]="mapInPerson(department.in_person_external_id)?.name"
           *ngIf="department.in_person_external_id">
        <em>In-Person:</em> {{mapInPerson(department.in_person_external_id)?.name}}
      </div>
    </div>
  </div>
</ng-template>
