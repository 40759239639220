import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'markResult'
})
export class MarkResultPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(value: any, matchers: RegExp[]): SafeHtml {
    if(!value)
    {
      return value;
    }
    if(!matchers) {
      return value;
    }
    for(const matcher of matchers) {
      if(!matcher.test(value)) {
        continue;
      }
      const result = matcher.exec(value);
      const match = result[0];
      const retValue = value.replace(match, `<mark>${match}</mark>`);
      return this.sanitizer.bypassSecurityTrustHtml(retValue);
    }
    return value;
  }
}
