<nav class="navbar navbar-expand-lg navbar-dark sticky-top">
  <div class="container-fluid">
    <button class="btn btn-link navbar-brand"
            [disabled]="!showUser()"
            [routerLink]="routes.Dashboard">
      <img src="/assets/ForfrontLogo_Clear.png"
           class="bg-white ps-3 pe-3"
           alt="Forefront Telecare Admin Portal"
           style="height: 40px;" />
      <br />
      Admin Portal
    </button>
    <div class="collapse navbar-collapse" *ngIf="showUser()">
      <ul class="navbar-nav me-auto">
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="routes.Dashboard">Dashboard</a>
        </li>
        <li *ngIf="isClientManager()" class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="routes.Clients">Athena Departments</a>
        </li>
        <li *ngIf="isClientManager() || isUserManager()" class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="routes.Providers">Providers</a>
        </li>
        <li *ngIf="isUserManager()" class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="routes.Users">Portal Users</a>
        </li>
        <li *ngIf="isZoomVideoMonitor()" class="nav-item" >
          <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="routes.VideoMonitoring">Appointment Video Monitoring</a>
        </li>
        <li *ngIf="isContentManager()" class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="routes.ContentManagement">Portal Content Management</a>
        </li>
        <li *ngIf="isTaskManager()" class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="routes.TaskLogs">Task Logs</a>
        </li>
      </ul>
      <div class="align-middle" *ngIf="showUser()">
        <navbar-profile-menu></navbar-profile-menu>
      </div>
    </div>
  </div>
</nav>
