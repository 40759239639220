import {Component, OnInit} from '@angular/core';
import {RestApiService} from "../../services/rest-api.service";
import {PortalToasterService, SearchDataBase} from "portal-lib";
import {TaskLogItem} from "#psygnal-model/routes/tasks/TaskLogItem";
import {TaskStatusTypes, TaskTypes} from "#psygnal-model/routes/tasks/TaskTypes";
import {TaskLogItemSearchRequest} from "#psygnal-model/routes/tasks/TaskLogItemSearchRequest";
import {
  NgbDateStruct,
  NgbDropdown,
} from "@ng-bootstrap/ng-bootstrap";
import {UntypedFormGroup, ValidationErrors} from "@angular/forms";
import moment from "moment";
import {DateFormat} from "#psygnal-model/shared/DateFormats";

const DefaultSkip = 0;
const DefaultTake = 20;

@Component({
  selector: 'admin-task-logs',
  templateUrl: './task-logs.component.html',
  styleUrls: ['./task-logs.component.scss']
})
export class TaskLogsComponent implements OnInit {
  loading = false;
  disableStartTask = false;
  currentLogs: TaskLogItem[] = null;
  currentDetail: TaskLogItem = null;
  currentTask: TaskTypes = null;
  lastSearchModel: SearchModel;
  searchModel: SearchModel;

  TaskTypes = TaskTypes;
  DateRangeOptions = DateRangeOptions;
  TaskStatusTypes = TaskStatusTypes;
  AthenaFollowupMetaFieldOptions = AthenaFollowupMetaFieldOptions;
  SalesforceClaimMetaFieldOptions = SalesforceClaimMetaFieldOptions;
  SalesforcePatientVerificationMetaFieldOptions = SalesforcePatientVerificationMetaFieldOptions;
  AthenaSyncMetaFieldOptions = AthenaSyncMetaFieldOptions;
  averageTaskTime: string = null;
  longestTaskTime: string = null;

  // showSingleRunTask = true;
  showSingleRunTask = false;

  constructor(private rest: RestApiService,
              private toast: PortalToasterService) {
  }

  async ngOnInit() {
    return this.loadTaskTab(TaskTypes.AthenaSync);
  }

  async loadTaskTab(task: TaskTypes, disableStartTask = false) {
    this.loading = true;
    this.clearSearch();
    this.currentTask = task;
    this.disableStartTask = disableStartTask;
    return this.search();
  }

  async startTaskManually() {
    try {
      this.loading = true;
      this.disableStartTask = true;
      await this.rest.postStartTask(this.currentTask);
      this.toast.showSuccess("Task Manually Started.");
      this.disableStartTask = true;
      this.loading = false;
    } catch (e) {
      console.log(e);
      this.loading = false;
      this.toast.showError("Error manually starting task. Check logs for details.");
    }
  }

  jsonify(val): string {
    return this.stringify(JSON.parse(val));
  }

  stringify(val): string {
    return JSON.stringify(val, null, 2);
  }

  async copyErrorData(val, msg = "Error Data") {
    const data = this.stringify(val);
    try {
      await navigator.clipboard.writeText(data);
      this.toast.showInfo(`${msg} Copied to Clipboard`);
    } catch (e) {
      this.toast.showError("Error accessing clipboard. Please check browser permissions.");
    }
  }

  startSearch(searchDrop: NgbDropdown) {
    this.searchModel = Object.assign({}, this.lastSearchModel);
    searchDrop.open();
  }

  clearSearch() {
    this.searchModel = Object.assign({}, DefaultSearchModel);
    this.lastSearchModel = Object.assign({}, this.searchModel);
  }


  async updateSearch() {
    this.lastSearchModel = Object.assign({}, this.searchModel);
    this.lastSearchModel.skip = DefaultSkip;
    this.lastSearchModel.take = DefaultTake;
    return this.search();
  }

  async previous() {
    let next = this.lastSearchModel.skip - this.lastSearchModel.take;
    if (next < 0) {
      next = 0;
    }
    this.lastSearchModel.skip = next;
    return this.search();
  }

  validateDateRanges(control: UntypedFormGroup): ValidationErrors | null {
    const options = control?.value?.dateRangeOptions;
    if (options !== "between") {
      return null;
    }
    const from = control?.value?.dateRangeStart;
    const to = control?.value?.dateRangeEnd;
    if (!from || !to) {
      return null;
    }
    const fromDate = moment(getDateForSearch(from), DateFormat);
    const toDate = moment(getDateForSearch(to), DateFormat);
    if (fromDate.isSameOrAfter(toDate)) {
      return {
        invalidDateRange: true
      };
    }
    return null;
  }


  async next() {
    this.lastSearchModel.skip = this.lastSearchModel.skip + this.lastSearchModel.take;
    return this.search();
  }

  disableNext(): boolean {
    if (!this.currentLogs) {
      return true;
    }
    if (!this.lastSearchModel) {
      return true;
    }
    return this.currentLogs.length < this.lastSearchModel.take;
  }

  disablePrevious(): boolean {
    if (!this.lastSearchModel) {
      return true;
    }
    return this.lastSearchModel.skip === 0;
  }

  async search() {
    try {
      this.loading = true;
      const request = this.getSearchRequest();
      const logs = await this.rest.getTaskLogs(this.currentTask, request);
      this.currentLogs = logs.result;
      this.currentDetail = null;
      this.calculateTaskTimes();
      this.loading = false;
    } catch (e) {
      console.log(e);
      this.loading = false;
      this.toast.showError("Error loading tasks tab. Check logs for details.");
    }
  }

  getSearchRequest(): TaskLogItemSearchRequest {
    const model = this.lastSearchModel;
    const req: TaskLogItemSearchRequest = {
      offset: model.skip,
      limit: model.take
    };
    switch (model.dateRangeOption) {
      case DateRangeOptions.between:
        req.start = getDateForSearch(model.dateRangeStart);
        req.end = getDateForSearch(model.dateRangeEnd);
        break;
      case DateRangeOptions.before:
        req.end = getDateForSearch(model.dateRangeSingle);
        break;
      case DateRangeOptions.after:
        req.start = getDateForSearch(model.dateRangeSingle);
        break;
      default:
        break;
    }
    if (model.statusOption) {
      req.status = model.statusOption;
    }
    if (model.metaField) {
      req.has_meta = true;
      req.meta_field = model.metaField;
      req.meta_value = true;
    }

    return req;
  }

  getSearchDescription(model: SearchModel, numRecords: number = 0) {
    const pageFragment = `${model.skip + 1} to ${model.skip + numRecords}`;
    const dateFragment = getDateFragment(model);
    const statusFragment = getStatusFragment(model);

    if (numRecords) {
      return `${dateFragment}, ${statusFragment},  ${pageFragment}`;

    }
    return `${dateFragment}, ${statusFragment}`;
  }

  private calculateTaskTimes() {
    if (!this.currentLogs || this.currentLogs.length === 0) {
      this.averageTaskTime = null;
      this.longestTaskTime = null;
      return;
    }
    const taskTimes = this.currentLogs.map(t => {
      const start = moment(t.start_time);
      const end = moment(t.end_time);
      return end.diff(start);
    });
    const avg = Math.floor(taskTimes.reduce((accumulator, item) => accumulator + item) / taskTimes.length);
    const avgMoment = moment.duration(avg);
    const max = Math.max(...taskTimes);
    const maxMoment = moment.duration(max);
    this.averageTaskTime = avgMoment.humanize();
    this.longestTaskTime = maxMoment.humanize();
  }
}

function getDateFragment(model: SearchModel): string {
  switch (model.dateRangeOption) {
    case DateRangeOptions.after:
      return `After ${getDate(model.dateRangeSingle)}`;
    case DateRangeOptions.before:
      return `Before ${getDate(model.dateRangeSingle)}`;
    case DateRangeOptions.between:
      return `From ${getDate(model.dateRangeStart)} to ${getDate(model.dateRangeEnd)}`;
    default:
      return "Most Recent";
  }
}


function getDate(date: NgbDateStruct): string {
  if (!date) {
    return "???";
  }
  if (!date.month || !date.day || !date.year) {
    return "???";
  }
  return `${date.month}/${date.day}/${date.year}`;
}

function getDateForSearch(date: NgbDateStruct): string {
  if (!date) {
    return null;
  }
  return `${date.year}-${pad(date.month)}-${pad(date.day)}`;
}

function pad(num: number): string {
  return num.toString().padStart(2, "0");
}

function getStatusFragment(model: SearchModel): string {
  if (model.statusOption) {
    return `Status: ${model.statusOption}`;
  }
  return "Status: any";
}

enum DateRangeOptions {
  before = "before",
  after = "after",
  between = "between"
}


enum AthenaFollowupMetaFieldOptions {
  hasSubscription = "hasSubscription",
  hasProcessedAppointments = "hasProcessedAppointments",
  hasSkippedNoSlotsAvailable = "hasSkippedNoSlotsAvailable"
}

enum SalesforceClaimMetaFieldOptions {
  hasClaimsGenerated = "hasClaimsGenerated",
  hasErrors = "hasErrors"
}

enum SalesforcePatientVerificationMetaFieldOptions {
  hasVerified = "hasVerified",
  hasErrors = "hasErrors"

}

enum AthenaSyncMetaFieldOptions {
  hasNewProviders = "hasNewProviders",
  hasDeletedProviders = "hasDeletedProviders",
  hasNewDepartments = "hasNewDepartments",
  hasDeletedDepartments = "hasDeletedDepartments",
}

interface SearchModel {
  skip: number;
  take: number;
  dateRangeOption?: DateRangeOptions;
  dateRangeSingle?: NgbDateStruct;
  dateRangeStart?: NgbDateStruct;
  dateRangeEnd?: NgbDateStruct;
  statusOption?: TaskStatusTypes;
  metaField?: string;
}

const DefaultSearchModel: SearchModel = {
  skip: DefaultSkip,
  take: DefaultTake
};
