// TODO: transform to interface

export class PatchRequest {
    fields: PatchField[];
    constructor(fields: PatchField[]) {
        this.fields = fields;
    }
}

export class PatchField {
    fieldName: string;
    value: any;
    constructor(fieldName: string, value: any) {
        this.fieldName = fieldName;
        this.value = value;
    }
}
