export const TimeZoneCodeToNameLookup = Object.freeze({
    "America/New_York": "Eastern",
    "America/Chicago": "Central",
    "America/Denver": "Mountain",
    "America/Phoenix": "Mountain/Arizona (No DST)",
    "America/Los_Angeles": "Pacific",
    "America/Anchorage": "Alaska",
    "Pacific/Honolulu": "Hawaii",
});

export const TimeZoneNameToCodeLookup = Object.freeze({
    "Eastern": "America/New_York",
    "US/Eastern": "America/New_York",
    "America/New_York": "America/New_York",
    "Central": "America/Chicago",
    "US/Central": "America/Chicago",
    "America/Chicago": "America/Chicago",
    "Mountain": "America/Denver",
    "US/Mountain": "America/Denver",
    "America/Denver": "America/Denver",
    "Mountain/Arizona (No DST)": "America/Phoenix",
    "US/Arizona": "America/Phoenix",
    "America/Phoenix": "America/Phoenix",
    "Pacific": "America/Los_Angeles",
    "US/Pacific": "America/Los_Angeles",
    "America/Los_Angeles": "America/Los_Angeles",
    "Alaska": "America/Anchorage",
    "US/Alaska": "America/Anchorage",
    "America/Anchorage": "America/Anchorage",
    "Hawaii": "Pacific/Honolulu",
    "US/Hawaii": "Pacific/Honolulu",
    "Pacific/Honolulu": "Pacific/Honolulu",
});

export const TimeZoneList = Object.freeze(Object.entries(TimeZoneCodeToNameLookup));

export function MapTimezoneCodeToName(code: string): string {
    if (!code) {
        return null;
    }
    return (TimeZoneCodeToNameLookup as any)[code];
}

export function MapTimeZoneNameToCode(name: string): string {
    if (!name) {
        return null;
    }
    const result = (TimeZoneNameToCodeLookup as any)[name];
    if(result) {
        return result;
    }
    return name;

}
