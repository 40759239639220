import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {RestApiService} from "../../services/rest-api.service";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {PortalToasterService, SearchBase, SearchDataBase} from "portal-lib";
import {ApiIntegrationTypes} from "#psygnal-model/shared/ApiIntegrationTypes";
import {Department} from "#psygnal-model/routes/clients/Department";
// import {Client} from "#psygnal-model/routes/clients/Client";
import {PatchRequest} from "#psygnal-model/shared/PatchRequest";
import {waitFor} from "#psygnal-model/util/UtilityFunctions";
import {NgForm} from "@angular/forms";
import {MapTimezoneCodeToName} from "#psygnal-model/shared/TimezoneLookups";
import {SalesforceSowRecord} from "#psygnal-model/routes/clients/SalesforceSowRecord";

@Component({
  selector: 'admin-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent
  extends SearchBase<ClientsSearchData>
  implements OnInit, AfterViewInit {
  careCoordinators: string[];
  // clients: Client[];
  departments: Department[];
  // clientModel: Client;
  departmentModel: Department;
  contactsModel: Department;
  zoomModel: Department;
  salesforceSearch: string;
  salesforceModel: Department;
  salesforceSows: SalesforceSowRecord[];
  MapTimezoneCodeToName = MapTimezoneCodeToName;
  DeletedOptions = DeletedOptions;
  salesforceSowsById: Map<string, SalesforceSowRecord>;
  linkedDepartments: Map<string, Department>;
  departmentsBySalesforceId: Map<string, Department>;
  inPersonDepartmentsById: Map<string, Department>;
  salesforceRegexes: RegExp[];
  @ViewChild('loadingModal') loadingModal;
  // @ViewChild('addEditClientModal') addEditClientModal;
  // @ViewChild('deleteClientModal') deleteClientModal;
  @ViewChild('editDepartmentModal') editDepartmentModal;
  @ViewChild('editZoomsModal') editZoomsModal;
  @ViewChild('editContactsModal') editContactsModal;
  @ViewChild('setSalesforceSowLinkModal') setSalesforceAccountLinkModal;
  @ViewChild('deleteSalesforceSowLinkModal') deleteSalesforceAccountLinkModal;
  @ViewChild("searchControl") searchControl: ElementRef;


  constructor(
    private rest: RestApiService,
    private modalService: NgbModal,
    private toast: PortalToasterService) {
    super();
    // this.searchFilter = this.searchFilter.bind(this);
    this.searchFilterDepartments = this.searchFilterDepartments.bind(this);
    this.searchFilterSalesforce = this.searchFilterSalesforce.bind(this);
    this.resetSearch();
  }

  async ngOnInit() {
    this.resetSearch();
    return this.loadPageData();
  }

  resetSearch() {
    this.resetSearchData();
    this.searchData.deletedOptions = DeletedOptions.active;
  }

  ngAfterViewInit(): void {
    this.afterViewInit(this.searchControl);
  }

  // searchFilter(client: Client): boolean {
  //   let found = this.testSearch(client.name);
  //   switch (this.searchData.integration) {
  //     case "pcc":
  //       found = found && client.integration_type === "pcc" && !!client.integration_client_id;
  //       break;
  //   }
  //   return found;
  // }

  searchFilterDepartments(department: Department): boolean {
    let found: boolean;
    switch (this.searchData.deletedOptions) {
      case DeletedOptions.active:
        found = !department.is_deleted;
        break;
      case DeletedOptions.deleted:
        found = department.is_deleted;
        break;
      case DeletedOptions.any:
      default:
        found = true;
        break;
    }
    found = found && this.testSearch(department.name);
    if (this.searchData.careCoordinator) {
      found = found && department.care_coordinator === this.searchData.careCoordinator;
    }
    if (this.searchData.missingSalesforce) {
      found = found && !department.salesforce_sow_id;
    }
    if (this.searchData.missingZoom) {
      found = found && this.isMissingZoom(department);
    }
    if (this.searchData.missingContacts) {
      found = found && this.isMissingContacts(department);
    }
    if (this.searchData.missingCareCoordinator) {
      found = found && this.isMissingCareCoordinator(department);
    }
    return found;
  }

  private async loadPageData() {
    try {
      this.loading = true;
      const [
        departments,
        // clients,
        coordinators,
        sows
      ] = await Promise.all([
        this.rest.getDepartments(),
        // this.rest.getClients(),
        this.rest.getCareCoordinators(),
        this.rest.getSalesforceSows(),
      ]);

      // this.clients = clients?.result || [];
      this.departments = departments?.result || [];
      this.careCoordinators = coordinators?.result || [];
      this.salesforceSows = sows?.result || [];
      this.salesforceSowsById = new Map<string, SalesforceSowRecord>(sows?.result?.map(a => [a.id, a]));
      const sfDepts = departments?.result?.filter(d => d.salesforce_sow_id);
      this.departmentsBySalesforceId = new Map<string, Department>(sfDepts.map(d => [d.salesforce_sow_id, d]));
      // linkedDepartments contains all departments that are mapped to salesforce, and all departments that are linked as an in-person department
      // start by filling the salesforce departments already determined above.
      this.linkedDepartments = new Map<string, Department>(sfDepts.map(d => [d.external_id, d]));
      this.inPersonDepartmentsById = new Map<string, Department>();
      // next get a mapping of all departments by ID for easier searching
      const allDeptsById = new Map<string, Department>(this.departments.map(d => [d.external_id, d]));
      for (const dept of this.departments) {
        const id = dept.in_person_external_id;
        if (id && allDeptsById.has(id)) {

          this.linkedDepartments.set(id, allDeptsById.get(id));
          this.inPersonDepartmentsById.set(id, allDeptsById.get(id))
        }
      }
      this.loading = false;
    } catch (e) {
      this.toast.showError("Error loading clients");
      console.error(e);
      this.loading = false;
    }
  }

  // Client related methods. Removed but kept
  // async handleAddClientClick() {
  //   try {
  //     this.clientModel = Object.assign({
  //       integrationDisabled: true
  //     }, {} as any);
  //     const modalRef = this.modalService.open(this.addEditClientModal);
  //     await modalRef.result;
  //
  //     try {
  //       this.openLoadingModal();
  //       const request = {
  //         name: this.clientModel.name,
  //         integration_client_id: this.clientModel.integration_client_id || null,
  //         integration_type: this.clientModel.integration_type || null
  //       } as Client;
  //       await this.rest.postAddClient(request);
  //       this.toast.showSuccess(`Client ${request.name} was successfully added`);
  //       await this.loadPageData();
  //       this.modalService.dismissAll();
  //       this.clearClientModel();
  //     } catch (err) {
  //       this.toast.showError("Error Adding Client. Check console log for details.");
  //       console.log("Error Adding Client", err);
  //       this.modalService.dismissAll();
  //       this.clearClientModel();
  //     }
  //   } catch (e) {
  //     this.modalService.dismissAll();
  //     this.clearClientModel();
  //   }
  // }
  // showPccIcon(client: Client): boolean {
  //   return client.integration_type === ApiIntegrationTypes.PointClickCare;
  // }
  // async handleEditClientClick(client: Client) {
  //   try {
  //     this.clientModel = Object.assign({
  //       integrationDisabled: true
  //     }, client);
  //     const modalRef = this.modalService.open(this.addEditClientModal);
  //     await modalRef.result;
  //
  //     try {
  //       this.openLoadingModal();
  //       const request = {fields: []} as PatchRequest;
  //       if (this.clientModel.name != client.name) {
  //         request.fields.push({
  //           fieldName: "name",
  //           value: this.clientModel.name
  //         });
  //       }
  //       if (this.clientModel.integration_type != client.integration_type) {
  //         request.fields.push({
  //           fieldName: "integration_client_id",
  //           value: this.clientModel.integration_client_id || null
  //         });
  //       }
  //       if (this.clientModel.integration_client_id != client.integration_client_id) {
  //         request.fields.push({
  //           fieldName: "integration_type",
  //           value: this.clientModel.integration_type || null
  //         });
  //       }
  //       if (request.fields.length > 0) {
  //         await this.rest.patchClient(this.clientModel.external_id, request);
  //       }
  //       this.toast.showSuccess(`Client ${this.clientModel.name} was successfully added`);
  //       this.clearClientModel();
  //       await this.loadPageData();
  //       this.modalService.dismissAll();
  //     } catch (err) {
  //       this.toast.showError("Error Adding Client. Check console log for details.");
  //       console.log("Error Adding Client", err);
  //       this.modalService.dismissAll();
  //       this.clearClientModel();
  //     }
  //   } catch (e) {
  //     this.modalService.dismissAll();
  //     this.clearClientModel();
  //   }
  // }
  // async handleDeleteClient(clientModel: Client) {
  //   try {
  //     const ref = this.modalService.open(this.deleteClientModal);
  //     await ref.result;
  //     try {
  //       this.openLoadingModal();
  //       await this.rest.deleteClient(clientModel.external_id);
  //       this.clients = this.clients.filter(c => c.external_id !== clientModel.external_id);
  //       this.toast.showWarning(`Client "${clientModel.name}" was permanently deleted.`);
  //       this.modalService.dismissAll();
  //       this.clearClientModel();
  //
  //     } catch (err) {
  //       this.toast.showError("Error deleting Client. Client may not have been deleted. Check console for details.");
  //       console.log("Error deleting client. ", err);
  //       this.modalService.dismissAll();
  //       this.clearClientModel();
  //     }
  //   } catch (e) {
  //     // do nothing on cancel
  //   }
  // }
  // private clearClientModel() {
  //   this.clientModel = {} as Client;
  // }

  isMissingZoom(department: Department): boolean {
    if (!department.zoom_links) {
      return true;
    }
    return department.zoom_links.length === 0;
  }

  isMissingProviders(department: Department): boolean {
    if (!department.providers) {
      return true;
    }
    return department.providers.length === 0;
  }

  isMissingContacts(department: Department): boolean {
    if (!department.appointment_schedule_contacts) {
      return true;
    }
    return department.appointment_schedule_contacts.length === 0;
  }

  isMissingCareCoordinator(department: Department): boolean {
    return !department.care_coordinator;
  }

  private clearContactsModel() {
    this.contactsModel = {} as Department;
  }

  private clearZoomModel() {
    this.zoomModel = {} as Department;
  }

  private clearSalesforceModel() {
    this.salesforceModel = {} as Department;
  }

  private clearDepartmentModel() {
    this.departmentModel = {} as Department;
  }

  private openLoadingModal() {
    this.modalService.open(this.loadingModal, {
      size: "sm",
      backdrop: "static"
    });
  }

  async handleEditDepartmentClick(department: Department) {
    try {
      this.departmentModel = Object.assign({
        integrationDisabled: true
      }, department);
      this.departmentModel.zoom_links = department.zoom_links?.map(z => Object.assign({}, z));
      this.departmentModel.appointment_schedule_contacts = department.appointment_schedule_contacts?.map(c => Object.assign({}, c));
      const modalRef = this.modalService.open(this.editDepartmentModal, {size: "lg"});
      await modalRef.result;

      try {
        this.openLoadingModal();
        const request = {fields: []} as PatchRequest;
        // if (this.departmentModel.client_external_id != department.client_external_id) {
        //   request.fields.push({
        //     fieldName: "client_external_id",
        //     value: this.departmentModel.client_external_id
        //   });
        // }
        // if (this.departmentModel.integration_client_facility_id != department.integration_client_facility_id) {
        //   request.fields.push({
        //     fieldName: "integration_client_facility_id",
        //     value: this.departmentModel.integration_client_facility_id
        //   });
        // }
        if (this.departmentModel.in_person_external_id != department.in_person_external_id) {
          request.fields.push({
            fieldName: "in_person_external_id",
            value: this.departmentModel.in_person_external_id
          });
        }
        if (this.departmentModel.care_coordinator != department.care_coordinator) {
          request.fields.push({
            fieldName: "care_coordinator",
            value: this.departmentModel.care_coordinator
          });
        }
        if (this.departmentModel.use_hospital_workflow !== department.use_hospital_workflow) {
          request.fields.push({
            fieldName: "use_hospital_workflow",
            value: this.departmentModel.use_hospital_workflow
          });
        }
        if (request.fields.length > 0) {
          await this.rest.patchDepartment(this.departmentModel.external_id, request);
        }
        this.toast.showSuccess(`Department ${this.departmentModel.name} was successfully updated`);
        this.clearDepartmentModel();
        await this.loadPageData();
        this.modalService.dismissAll();
      } catch (err) {
        this.toast.showError("Error updating Department. Check console log for details.");
        console.log("Error updating Department", err);
        this.modalService.dismissAll();
        this.clearDepartmentModel();
      }
    } catch (e) {
      this.modalService.dismissAll();
      this.clearDepartmentModel();
    }
  }

  async handleEditContactsClick(modal: NgbActiveModal) {
    this.contactsModel = Object.assign({}, this.departmentModel);
    modal.dismiss();
    await waitFor(0);

    try {
      const modalRef = this.modalService.open(this.editContactsModal, {size: "lg"});
      await modalRef.result;

      try {
        this.openLoadingModal();
        const request = {
          fields: [
            {
              fieldName: "appointment_schedule_contacts",
              value: this.contactsModel.appointment_schedule_contacts
            }
          ]
        } as PatchRequest;
        await this.rest.patchDepartment(this.contactsModel.external_id, request);
        this.toast.showSuccess(`Department ${this.contactsModel.name} was successfully updated`);
        this.clearContactsModel();
        await this.loadPageData();
        this.modalService.dismissAll();
      } catch (err) {
        this.toast.showError("Error updating Department. Check console log for details.");
        console.log("Error updating Department", err);
        this.modalService.dismissAll();
        this.clearContactsModel();
      }
    } catch (e) {
      this.modalService.dismissAll();
      this.clearContactsModel();
    }
  }

  async handleEditZoomClick(modal: NgbActiveModal) {
    this.zoomModel = Object.assign({}, this.departmentModel);
    modal.dismiss();
    await waitFor(0);

    try {
      const modalRef = this.modalService.open(this.editZoomsModal, {size: "lg"});
      await modalRef.result;

      try {
        this.openLoadingModal();
        const request = {
          fields: [
            {
              fieldName: "zoom_links",
              value: this.zoomModel.zoom_links
            }
          ]
        } as PatchRequest;
        await this.rest.patchDepartment(this.zoomModel.external_id, request);
        this.toast.showSuccess(`Department ${this.zoomModel.name} was successfully updated`);
        this.clearZoomModel();
        await this.loadPageData();
        this.modalService.dismissAll();
      } catch (err) {
        this.toast.showError("Error updating Department. Check console log for details.");
        console.log("Error updating Department", err);
        this.modalService.dismissAll();
        this.clearZoomModel();
      }
    } catch (e) {
      this.modalService.dismissAll();
      this.clearZoomModel();
    }
  }

  handleAddContact(editContactsForm: NgForm) {
    if (!this.contactsModel.appointment_schedule_contacts) {
      this.contactsModel.appointment_schedule_contacts = [];
    }
    this.contactsModel.appointment_schedule_contacts.push({
      role: null,
      email: null,
      name: null,
      phone: null,
      send_schedule_email: false,
    });
    editContactsForm.form.markAsDirty();
  }

  handleRemoveContact(editContactsForm: NgForm, i: number) {
    this.contactsModel.appointment_schedule_contacts.splice(i, 1);
    editContactsForm.form.markAsDirty();
  }

  handleAddZoom(editZoomsForm: NgForm) {
    if (!this.zoomModel.zoom_links) {
      this.zoomModel.zoom_links = [];
    }
    this.zoomModel.zoom_links.push({
      external_id: null,
      name: `Cart ${this.zoomModel.zoom_links.length + 1}`,
      url: null,
    });
    editZoomsForm.form.markAsDirty();
  }

  handleRemoveZoom(editZoomsForm: NgForm, i: number) {
    this.zoomModel.zoom_links.splice(i, 1);
    editZoomsForm.form.markAsDirty();
  }

  async handleSetSalesforceSowLinkClick(modal: NgbActiveModal) {
    this.salesforceModel = Object.assign({}, this.departmentModel);
    this.salesforceSearch = null;
    modal.dismiss();
    await waitFor(0);

    try {
      const modalRef = this.modalService.open(this.setSalesforceAccountLinkModal, {size: "lg"});
      await modalRef.result;

      try {
        this.openLoadingModal();
        let val = this.salesforceModel.salesforce_sow_id;
        if (!val) {
          val = null;
        }
        const request = {
          fields: [
            {
              fieldName: "salesforce_sow_id",
              value: val
            }
          ]
        } as PatchRequest;
        await this.rest.patchDepartment(this.salesforceModel.external_id, request);
        this.toast.showSuccess(`Salesforce Account Link for Department ${this.salesforceModel.name} was successfully established.`);
        this.clearSalesforceModel();
        await this.loadPageData();
        this.modalService.dismissAll();
      } catch (err) {
        this.toast.showError("Error updating Department. Check console log for details.");
        console.log("Error updating Department", err);
        this.modalService.dismissAll();
        this.clearSalesforceModel();
      }
    } catch (e) {
      this.modalService.dismissAll();
      this.clearSalesforceModel();
    }
  }

  async handleRemoveSalesforceSowLinkClick(departmentModel: Department) {
    try {
      const ref = this.modalService.open(this.deleteSalesforceAccountLinkModal, {
        size: "lg"
      });
      await ref.result;
      try {
        this.openLoadingModal();

        const request = {
          fields: [
            {
              fieldName: "salesforce_sow_id",
              value: null
            }
          ]
        } as PatchRequest;
        await this.rest.patchDepartment(departmentModel.external_id, request);
        this.toast.showWarning(`The Salesforce Account link for ${departmentModel.name} was successfully removed.`);
        this.clearSalesforceModel();
        await this.loadPageData();
        this.modalService.dismissAll();

      } catch (err) {
        this.toast.showError("Error removing Department Salesforce Account link. Data may not have been updated. Check console for details.");
        console.log("Error deleting salesforce link. ", err);
        this.modalService.dismissAll();
        this.clearSalesforceModel();
      }
    } catch (e) {
      // do nothing on cancel
    }
  }

  updateSalesforceSearch() {
    setTimeout(() => {
      const {regexes} = this.parseSearch(this.salesforceSearch);
      this.salesforceRegexes = regexes;
      this.searchUpdated = new Date();
    });
  }

  mapSow(id: string): SalesforceSowRecord {
    return this.salesforceSowsById.get(id);
  }

  mapInPerson(id: string): Department {
    return this.inPersonDepartmentsById.get(id);
  }

  searchFilterSalesforce(account: SalesforceSowRecord): boolean {
    return this.testSalesforceSearch(account.name);
  }

  testSalesforceSearch(itemValue) {
    let found = true;
    if (this.salesforceSearch) {
      let hasVal = false;
      if (itemValue) {
        hasVal = this.salesforceRegexes.some(r => r.test(itemValue));
      }
      found = found && (hasVal);
    }
    return found;
  }
}


interface ClientsSearchData extends SearchDataBase {
  integration?: string;
  missingZoom: boolean;
  missingContacts: boolean;
  missingCareCoordinator: boolean;
  missingSalesforce: boolean;
  deletedOptions: DeletedOptions;
  careCoordinator: string;
}

enum DeletedOptions {
  active = "active",
  deleted = "deleted",
  any = "any"
}
