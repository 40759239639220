<svg
  class="portals-password-icon"
  xmlns="http://www.w3.org/2000/svg"
  x="0px"
  y="0px"
  width="20px"
  height="20px"
  viewBox="0 0 512 512">
<g>
	<g>
		<g>
			<path d="M102.385,186.667c-2.958-5.104-9.51-6.854-14.573-3.906l-2.479,1.431v-2.858c0-5.896-4.771-10.667-10.667-10.667
				c-5.896,0-10.667,4.771-10.667,10.667v2.858l-2.479-1.431c-5.094-2.948-11.625-1.198-14.573,3.906
				c-2.948,5.104-1.198,11.625,3.906,14.573l2.473,1.427l-2.473,1.427c-5.104,2.948-6.854,9.469-3.906,14.573
				c1.979,3.417,5.563,5.333,9.25,5.333c1.813,0,3.646-0.458,5.323-1.427L64,221.142V224c0,5.896,4.771,10.667,10.667,10.667
				c5.896,0,10.667-4.771,10.667-10.667v-2.858l2.479,1.431c1.677,0.969,3.51,1.427,5.323,1.427c3.688,0,7.271-1.917,9.25-5.333
				c2.948-5.104,1.198-11.625-3.906-14.573l-2.473-1.427l2.473-1.427C103.583,198.292,105.333,191.771,102.385,186.667z"/>
      <path d="M187.719,186.667c-2.969-5.104-9.5-6.854-14.573-3.906l-2.479,1.431v-2.858c0-5.896-4.771-10.667-10.667-10.667
				s-10.667,4.771-10.667,10.667v2.858l-2.479-1.431c-5.115-2.948-11.635-1.198-14.573,3.906
				c-2.948,5.104-1.198,11.625,3.906,14.573l2.473,1.427l-2.473,1.427c-5.104,2.948-6.854,9.469-3.906,14.573
				c1.979,3.417,5.563,5.333,9.25,5.333c1.813,0,3.646-0.458,5.323-1.427l2.479-1.431V224c0,5.896,4.771,10.667,10.667,10.667
				s10.667-4.771,10.667-10.667v-2.858l2.479,1.431c1.677,0.969,3.51,1.427,5.323,1.427c3.688,0,7.271-1.917,9.25-5.333
				c2.948-5.104,1.198-11.625-3.906-14.573l-2.473-1.427l2.473-1.427C188.917,198.292,190.667,191.771,187.719,186.667z"/>
      <path d="M273.052,186.667c-2.958-5.104-9.49-6.854-14.573-3.906L256,184.191v-2.858c0-5.896-4.771-10.667-10.667-10.667
				c-5.896,0-10.667,4.771-10.667,10.667v2.858l-2.479-1.431c-5.104-2.948-11.635-1.198-14.573,3.906
				c-2.948,5.104-1.198,11.625,3.906,14.573l2.473,1.427l-2.473,1.427c-5.104,2.948-6.854,9.469-3.906,14.573
				c1.979,3.417,5.563,5.333,9.25,5.333c1.813,0,3.646-0.458,5.323-1.427l2.479-1.431V224c0,5.896,4.771,10.667,10.667,10.667
				c5.896,0,10.667-4.771,10.667-10.667v-2.858l2.479,1.431c1.677,0.969,3.51,1.427,5.323,1.427c3.688,0,7.271-1.917,9.25-5.333
				c2.948-5.104,1.198-11.625-3.906-14.573l-2.473-1.427l2.473-1.427C274.25,198.292,276,191.771,273.052,186.667z"/>
      <path d="M490.667,256v-10.667c0-20.266-8.178-38.622-21.333-52.09v-43.91c0-23.531-19.135-42.667-42.667-42.667h-384
				C19.135,106.667,0,125.802,0,149.333V256c0,23.531,19.135,42.667,42.667,42.667H320V384c0,11.76,9.573,21.333,21.333,21.333
				h149.333c11.76,0,21.333-9.573,21.333-21.333V277.333C512,265.573,502.427,256,490.667,256z M469.333,245.333V256H362.667
				v-10.667C362.667,215.927,386.594,192,416,192S469.333,215.927,469.333,245.333z M320,277.333H42.667
				c-11.76,0-21.333-9.573-21.333-21.333V149.333c0-11.76,9.573-21.333,21.333-21.333h384c11.76,0,21.333,9.573,21.333,21.333
				v28.823c-9.737-4.661-20.505-7.49-32-7.49c-25.81,0-48.589,13.18-62.004,33.148l-1.99-1.148l2.473-1.427
				c5.104-2.948,6.854-9.469,3.906-14.573c-2.958-5.104-9.51-6.854-14.573-3.906l-2.479,1.431v-2.858
				c0-5.896-4.771-10.667-10.667-10.667c-5.896,0-10.667,4.771-10.667,10.667v2.858l-2.479-1.431
				c-5.094-2.948-11.625-1.198-14.573,3.906c-2.948,5.104-1.198,11.625,3.906,14.573l2.473,1.427l-2.473,1.427
				c-5.104,2.948-6.854,9.469-3.906,14.573c1.979,3.417,5.563,5.333,9.25,5.333c1.813,0,3.646-0.458,5.323-1.427l2.479-1.431V224
				c0,5.896,4.771,10.667,10.667,10.667c5.896,0,10.667-4.771,10.667-10.667v-2.858l2.479,1.431
				c0.421,0.243,0.897,0.178,1.335,0.358c-2.26,7.117-3.814,14.547-3.814,22.402V256C329.573,256,320,265.573,320,277.333z
				 M490.667,384H341.333V277.333h149.333V384z"/>
      <path d="M405.333,338.053V352c0,5.896,4.771,10.667,10.667,10.667c5.896,0,10.667-4.771,10.667-10.667v-13.947
				c6.258-3.724,10.667-10.262,10.667-18.053c0-11.76-9.573-21.333-21.333-21.333c-11.76,0-21.333,9.573-21.333,21.333
				C394.667,327.792,399.076,334.329,405.333,338.053z"/>
		</g>
	</g>
</g>
</svg>
