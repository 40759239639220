import {Inject, Injectable} from '@angular/core';
import {PortalLibConfig, PortalLibConfigToken} from "../PortalLibConfig";
import {JwtHelperService} from "@auth0/angular-jwt";
import moment from "moment";
import CryptoJs from "crypto-js";
import {StorageService} from "./storage.service";
import {HttpClient} from "@angular/common/http";

const VerifierKey = "psygnal_pkce_verifier";
const TokenExpirationKey = "psygnal_token_expiration";
const AccessTokenKey = "psygnal_access_token";
const IdTokenKey = "psygnal_id_token";
const RefreshTokenKey = "psygnal_refresh_token";
const RolesTokenKey = "psygnal_auth_roles";
const IntegrationSetKey = "psygnal_auth_integration_set";
const ExpirationPadding = 60; // expire tokens early by 60 seconds before their declared expiration time.

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private expiration: moment.Moment;
  private accessToken: string;
  private idToken: any;
  private permissions: string[];
  private integrations: IntegrationSet;
  private jwt = new JwtHelperService();

  constructor(@Inject(PortalLibConfigToken) private config: PortalLibConfig,
              private http: HttpClient,
              private storage: StorageService) {
    this.loadToken();
  }

  /**
   * When called, tests the expiration date of the user's token against the current date. Returns
   * true if the user's token is expired, or false otherwise
   */
  isExpired(): boolean {
    const expire = this.expiration.clone();
    expire.subtract(ExpirationPadding, "seconds");
    return expire.isBefore(moment());
  }

  /**
   * Returns true if there is an access token associated with the user, and that token is not
   * currently expired. False otherwise.
   */
  isAuthenticated(): boolean {
    return !!this.accessToken && !this.isExpired();
  }

  /**
   * Returns the encoded access token for the current user, for use as a bearer token during API calls.
   */
  getAccessToken(): string {
    return this.accessToken;
  }

  /**
   * Returns a list of permissions associated with the user as strings. The valuse of these strings
   * correspond to an ApiPermissions enum value. ApiPermissions enum is not referenced in portal-lib
   * due to packaging constraints for the portals
   * and must be specifically cast as a ApiPermissions if the consumer of this method wishes
   * any sort of compile-time type safety
   */
  getPermissions(): string[] {
    return this.permissions;
  }

  /**
   * Returns the IDs of the user's current integrations, i.e. the IDs of the client or provider
   * the user can act as.
   */
  getIntegrations(): IntegrationSet {
    return this.integrations;
  }

  /**
   * Returns the current user's timezone
   */
  getTimezone(): string {
    return this.idToken?.timezone || this.idToken?.zoneinfo;
  }

  /**
   * Updates the user's profile / id token.
   * @param profile:MyProfile|PsygnalIdToken
   */
  updateProfile(profile: any) {
    this.storage.session.set(IdTokenKey, profile);
    this.idToken = profile;
  }

  /**
   * Returns the IDs of the user's current integrations, i.e. the IDs of the facilities, provider, or patient
   * the user can act as.
   */
  getExpiration(): string {
    return this.expiration.toISOString();
  }

  /**
   * Returns true if the passed in permission is within the set of permissions.
   * @param permission
   */
  hasPermission(permission: string): boolean {
    return this.permissions?.includes(permission);
  }

  /**
   * Returns a profile object for the currently logged in user, as a PsygnalIdToken object.
   * PsygnalIdToken is not referenced in portal-lib due to packaging constraints for the portals
   * and must be specifically cast as a PsygnalIdToken if the consumer of this method wishes
   * any sort of compile-time type safety
   */
  getProfile(): any {
    return this.idToken;
  }

  async logout() {
    await this.http.post(this.config.auth.revokeUrl, {
      token: this.accessToken
    }).toPromise();
    this.clearTokenData();
  }

  /**
   * Fetches the token as part of the final step of the OAuth2 Authorization
   * Code flow, as described here
   *
   * https://tools.ietf.org/html/rfc6749
   *
   * And stores the token and ID profile in session storage.
   * @param code
   */
  async downloadAccessToken(code: string) {
    const verifier = this.storage.session.get(VerifierKey);
    const request = {
      client_id: this.config.portal.name,
      grant_type: "authorization_code",
      code_verifier: verifier,
      code: code
    };
    const response: any = await this.http.post(this.config.auth.tokenUrl, request).toPromise();

    const expiration = moment().add(response.expires_in, "seconds");
    let idToken, accessToken;
    try {
      idToken = this.jwt.decodeToken(response.id_token);
      accessToken = this.jwt.decodeToken(response.access_token);
    } catch (e) {
      console.log("Error parsing ID token", e);
      throw e;
    }
    const integrations: IntegrationSet = {
      clientId: accessToken.client_id,
      providerId: accessToken.provider_id,
      integrationType: accessToken.integration_type,
    };
    this.storage.session.set(TokenExpirationKey, expiration.format());
    this.storage.session.set(IdTokenKey, idToken);
    this.storage.session.set(AccessTokenKey, response.access_token);
    this.storage.session.set(RefreshTokenKey, response.refresh_token);
    this.storage.session.set(RolesTokenKey, accessToken.roles);
    this.storage.session.set(IntegrationSetKey, integrations);
    this.loadToken();
  }


  /**
   * Builds a challenge verifier for later validation according to the PKCE Auth
   * Code flow, as explained here:
   *
   * https://tools.ietf.org/html/rfc7636
   * https://auth0.com/docs/flows/call-your-api-using-the-authorization-code-flow-with-pkce
   */
  buildLoginChallenge(): string {
    const verifier = safeString(CryptoJs.lib.WordArray.random(96));
    const hashed = safeString(CryptoJs.SHA256(verifier));
    this.storage.session.set(VerifierKey, verifier);
    return hashed;
  }

  private clearTokenData() {
    this.accessToken = null;
    this.idToken = null;
    this.expiration = null;
    this.storage.session.delete(TokenExpirationKey);
    this.storage.session.delete(AccessTokenKey);
    this.storage.session.delete(IdTokenKey);
    this.storage.session.delete(RolesTokenKey);
    this.storage.session.delete(IntegrationSetKey);
    // TODO: Should refresh token be in local storage? Not necessary until refresh
    //       tokens are implemented
    this.storage.session.delete(RefreshTokenKey);
  }

  private loadToken() {
    const expiration = this.storage.session.get(TokenExpirationKey);
    // if there's nothing there, stop loading and clear data
    if (!expiration) {
      this.clearTokenData();
      return;
    }
    this.expiration = moment(expiration);
    // if there's no expiration, or if the current expiration is invalid,
    // stop loading and clear data
    if (!this.expiration.isValid() || this.isExpired()) {
      this.clearTokenData();
      return;
    }
    this.accessToken = this.storage.session.get(AccessTokenKey);
    this.idToken = this.storage.session.get(IdTokenKey, true);
    this.permissions = this.storage.session.get(RolesTokenKey, true);
    this.integrations = this.storage.session.get(IntegrationSetKey, true);
    // if we don't get both tokens and the roles, stop loading and clear data
    if (!this.accessToken || !this.idToken || !this.permissions) {
      console.log("Session storage data mishap. Missing at least one of access token, id token, or permissions from session storage. Clearing auth and requiring reauthentication.");
      this.clearTokenData();
      return;
    }
  }
}

function safeString(data: CryptoJs.lib.WordArray) {
  return data.toString(CryptoJs.enc.Base64)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
}

export interface IntegrationSet {
  clientId?: string;
  providerId?: string;
  integrationType: string;  // is of type PortalAuthProviders, which cannot be imported into portal-lib
}

