import {
    EmailValidator,
    EnumArrayValidator,
    MaxLengthValidator,
    RequiredValidator, StateArrayValidator, String100Validator, String255Validator, String50Validator, StringArrayValidator,
    StringValidator,
    Validator
} from "#psygnal-model/util/validation/Validator";
import ApiPermissions from "#psygnal-model/shared/ApiPermissions";
import {PortalAuthProviders} from "#psygnal-model/shared/PortalAuthProviders";

export interface PortalUser {
    external_id: string;
    name: string;
    phone: string;
    email: string;
    timezone: string;
    picture: string;
    email_verified: boolean;
    phone_verified: boolean;
    roles?: ApiPermissions[];
    ms_integration?: CredentialIntegration;
    pw_integration?: CredentialIntegration;
    pcc_integration?: CredentialIntegration;
    pcc_pending_integration?: CredentialIntegration;
    client_integration_id?: string;
    provider_integration_id?: string;
}

/**
 * Represents either a linked credential integration OR a pending credential integration
 *
 * Difference varies on whether integration_sub or integration_code has a value.
 *
 * Currently, pending integrations only matter to pointclickcare integrations, and will
 * not matter once a userless integration is established for PCC
 */
export interface CredentialIntegration {
    integration_type: PortalAuthProviders;
    integration_sub?: string;
    integration_code?: string;
    created: string;
}


const validator = new Map<string, Validator[]>();
validator.set("name", [...String255Validator, RequiredValidator]);
validator.set("timezone", [...String50Validator, RequiredValidator]);
validator.set("phone", [StringValidator, new MaxLengthValidator(15)]);
validator.set("email", [...String255Validator, EmailValidator]);
validator.set("picture", [StringValidator, new MaxLengthValidator(1500000)]); // 1MB, base 64 encoded, plus some padding

export const PortalUserAddValidator = validator;

const patchValidator = new Map<string, Validator[]>();
patchValidator.set("name", String255Validator);
patchValidator.set("timezone", String50Validator);
patchValidator.set("phone", [StringValidator, new MaxLengthValidator(15)]);
patchValidator.set("email", [...String255Validator, EmailValidator]);
patchValidator.set("picture", [StringValidator, new MaxLengthValidator(1500000)]); // 1MB, base 64 encoded, plus some padding
patchValidator.set("roles", [new EnumArrayValidator(Object.values(ApiPermissions))]);
patchValidator.set("client_integration_id", [StringValidator, new MaxLengthValidator(100)]);
patchValidator.set("provider_integration_id", [StringValidator, new MaxLengthValidator(100)]);

export const PortalUserPatchValidator = patchValidator;
