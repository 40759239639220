import { Component, OnInit } from '@angular/core';
import {RoutingPaths} from "../RoutePaths";
import {ApiPermissions} from "#psygnal-model/shared/ApiPermissions";
import {AuthService} from "portal-lib";

@Component({
  selector: 'admin-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  routes = RoutingPaths;

  constructor(private auth: AuthService) { }

  ngOnInit() {
  }

  isClientManager() {
    return this.auth.hasPermission(ApiPermissions.AccessAdminPortal) &&
      this.auth.hasPermission(ApiPermissions.AdminManageClients);
  }

  isUserManager() {
    return this.auth.hasPermission(ApiPermissions.AccessAdminPortal) &&
      this.auth.hasPermission(ApiPermissions.AdminManageUsers);
  }

  isZoomVideoMonitor() {
    return this.auth.hasPermission(ApiPermissions.AccessAdminPortal) &&
      this.auth.hasPermission(ApiPermissions.AdminMonitorVideo);
  }

  isTaskManager() {
    return this.auth.hasPermission(ApiPermissions.AccessAdminPortal) &&
      this.auth.hasPermission(ApiPermissions.AdminManageDailyTasks);
  }

  isContentManager() {
    return this.auth.hasPermission(ApiPermissions.AccessAdminPortal) &&
      this.auth.hasPermission(ApiPermissions.AdminManagePortalContent);
  }

}
