import {environment} from "../environments/environment";
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {PortalLibConfig, PortalLibModule, ProvidePortalLibConfig, ProvidePsygnalHttpInjector} from 'portal-lib';
import {DashboardComponent} from './routes/dashboard/dashboard.component';
import {NavbarComponent} from './controls/navbar/navbar.component';
import {HttpClientModule} from '@angular/common/http';
import {SalesForceLinkComponent} from './controls/sales-force-link/sales-force-link.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SortIconComponent} from './controls/sort-icon/sort-icon.component';
import {UsersComponent} from './routes/users/users.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ToastrModule} from "ngx-toastr";
import {MdstaffLinkComponent} from './controls/mdstaff-link/mdstaff-link.component';
import {NgSelectModule} from "@ng-select/ng-select";
import {
  AppointmentVideoMonitoringRouteComponent
} from './routes/appointment-video-monitoring-route/appointment-video-monitoring-route.component';
import {RoutePaths} from "./routes/RoutePaths";
import {ClientsComponent} from './routes/clients/clients.component';
import {ProvidersComponent} from './routes/providers/providers.component';
import {PortalContentManagementComponent} from './routes/portal-content-management/portal-content-management.component';
import {TaskLogsComponent} from './routes/task-logs/task-logs.component';
import {NgxJsonViewerModule} from "ngx-json-viewer";

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    NavbarComponent,
    SalesForceLinkComponent,
    SortIconComponent,
    UsersComponent,
    MdstaffLinkComponent,
    AppointmentVideoMonitoringRouteComponent,
    ClientsComponent,
    ProvidersComponent,
    PortalContentManagementComponent,
    TaskLogsComponent
  ],
  imports: [
    PortalLibModule,
    NgbModule,
    NgSelectModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    PortalLibModule,
    NgxJsonViewerModule,
  ],
  providers: [
    // add the http injector to add tokens to the API calls
    ProvidePsygnalHttpInjector(),
    // calling this function sets up configuration values for the shared library.
    // I much prefer this function call in a provider to the common forRoot() approach
    // many modules use
    ProvidePortalLibConfig(<PortalLibConfig> {
      portal: {
        name: environment.portal.name,
        loginRoute: RoutePaths.Login,
        unauthorizedRoute: RoutePaths.Unauthorized,
        profilePicMaxSize: environment.portal.profilePicMaxSize,
      },
      login: {
        allowPassword: true,
        allowO365: true,
        allowPcc: false,
      },
      auth: environment.auth,
      httpIntercept: environment.httpIntercept
    }),
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
