<ng-template #profileModal let-modal>
  <div class="modal-header border-0 modal-header-background bg-secondary">
    <button type="button" class="btn-close shadow-none" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
    </button>
  </div>
  <div class="modal-body">
    <div class="text-center user-icon-div">
      <img *ngIf="userProfile.picture"
           [src]="userProfile.picture"/>
      <i *ngIf="!userProfile.picture"
         class="fas fa-user-circle fa-5x p-0 text-secondary bg-white"></i>
    </div>
    <h5 class="mt-4 text-center">{{userProfile.name || "(name not set)"}}</h5>
    <div class="container-fluid">
      <div class="row">
        <div class="mt-1 col-md-7">
          <div class="fw-bold small mb-1">Personal Info</div>
          <div class="ms-2 text-truncate">
            <i class="fas fa-fw fa-envelope"></i>
            <span class="text-muted">
              {{userProfile.email || "(not set)" }}
            </span>
          </div>
          <div class="ms-2">
            <i class="fas fa-fw fa-mobile-alt"></i>
            <span class="text-muted">
              {{userProfile.phone || "(not set)" }}
            </span>
          </div>
          <div class="ms-2">
            <portals-timezone-icon></portals-timezone-icon>
            <span class="text-muted">
              {{MapTimezoneCodeToName(userProfile.timezone) || "(not set)"}}
            </span>
          </div>
        </div>
        <div class="mt-1 col-md-5">
          <div class="fw-bold small mb-2">Credential Integrations</div>
          <div *ngIf="isO365()" class="icon30 ms-2 mb-4">
            <portals-microsoft-icon
              ngbTooltip="Forefront Microsoft Account"></portals-microsoft-icon>
          </div>
          <div *ngIf="isPassword()" class="icon30 ms-2 mb-4">
            <portals-password-icon class="me-4"
                                   ngbTooltip="Forefront Portals Password Account"></portals-password-icon>
            <button class="btn btn-sm btn-secondary p-1"
                    (click)="showEditPassword()">
              <small>Change Password</small>
            </button>
          </div>
          <div *ngIf="isPcc()" class="icon30 ms-2 mb-4">
            <portals-pcc-icon
              ngbTooltip="PointClickCare Partner Integration Account"></portals-pcc-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" ngbAutofocus class="btn btn-primary btn-sm me-auto"
            (click)="showEditProfile()">
      Edit Profile
    </button>
    <button type="button" class="btn btn-secondary btn-sm" (click)="modal.dismiss()">
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #editProfileModal let-modal>
  <div class="modal-header border-0 modal-header-background bg-secondary">
    <button type="button" class="btn-close shadow-none" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
    </button>
  </div>
  <div class="modal-body">
    <div class="text-center user-icon-edit">
      <img *ngIf="userProfile.picture"
           [src]="userProfile.picture"/>
      <i *ngIf="!userProfile.picture"
         class="fas fa-user-circle fa-5x p-0 text-secondary bg-white"></i>
      <label class="cursor-pointer form-label" for="picture">
        <span class="fa-stack edit-icon-overlay cursor-pointer">
            <i class="fa fa-circle fa-stack-2x edit-icon-background"></i>
            <i class="far fa-circle fa-stack-2x edit-icon-border"></i>
            <i class="fa fa-camera fa-stack-1x"></i>
        </span>
        <input type="file" #picture name="picture" id="picture" accept="image/*" data-type='image'
               (change)="handleFileSelect($event, userProfile)"
               class="d-none"/>
      </label>
      <div *ngIf="userProfile.picture"
           class="fa-stack clear-icon-overlay cursor-pointer"
           (click)="clearProfilePicture(picture);">
        <i class="fa fa-circle fa-stack-2x edit-icon-background"></i>
        <i class="far fa-circle fa-stack-2x edit-icon-border"></i>
        <i class="fa fa-eraser fa-stack-1x"></i>
      </div>
    </div>
    <div class="container-fluid mt-3">
      <div class="row">
        <form class="mt-1 col-md-12" #editProfileForm="ngForm">
          <div>
            <label class=" form-label" for="name"><i class="fas fa-fw fa-user-circle"></i> Display Name</label>
            <input type="text" required class="form-control" id="name" name="name"
                   [(ngModel)]="userProfile.name"/>
          </div>
          <div>
            <label class=" form-label" for="phone">
              <i class="fas fa-fw fa-mobile-alt"></i>
              Phone</label>
            <input type="phone" class="form-control" id="phone" name="phone"
                   [(ngModel)]="userProfile.phone"/>

          </div>
          <div>
            <label class=" form-label" for="timezone">
              <portals-timezone-icon></portals-timezone-icon>
              Timezone</label>
            <select name="timezone"
                    required
                    class="form-select"
                    [(ngModel)]="userProfile.timezone"
                    id="timezone">
              <option *ngFor="let entry of TimeZoneList"
                      [value]="entry[0]">{{entry[1]}}</option>
            </select>
          </div>

        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" ngbAutofocus class="btn btn-primary btn-sm me-auto"
            (click)="cancelEditUserProfile()">
      Cancel
    </button>
    <button type="button" class="btn btn-secondary btn-sm"
            [disabled]="!editProfileForm.valid"
            (click)="saveUserProfile()">
      Save Profile
    </button>
  </div>
</ng-template>

<ng-template #editPasswordModal let-modal>
  <div class="modal-header border-0">
    <h4 class="modal-title" id="modal-basic-title">Change Portal Password</h4>

    <button type="button" class="btn-close shadow-none" aria-label="Close" (click)="modal.dismiss('Cross click')">
    </button>
  </div>
  <div class="modal-body">
    <form #editPasswordForm="ngForm">
      <div>
        <label class=" form-label" for="password">New Password</label>
        <input type="password" class="form-control" minlength="6" required id="password"
               name="password"
               [(ngModel)]="editPasswordValue"/>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" ngbAutofocus class="btn btn-primary btn-sm me-auto"
            (click)="modal.dismiss()">
      Cancel
    </button>
    <button type="button" class="btn btn-secondary btn-sm"
            [disabled]="!editPasswordForm.valid"
            (click)="savePassword()">
      Save Password
    </button>
  </div>
</ng-template>

<ng-template #loadingModal let-modal>
  <div class="modal-body text-center">
    <portals-loading-icon class="text-center"
                          style="font-size: 45pt"></portals-loading-icon>
  </div>
</ng-template>
