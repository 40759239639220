import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {PortalToasterService, SearchBase, SearchDataBase} from "portal-lib";
import {CmsItem} from "#psygnal-model/routes/cms/CmsItem";
import {RestApiService} from "../../services/rest-api.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {MaxCmsBodySize} from "#psygnal-model/routes/cms/CmsChangeRequests";

@Component({
  selector: 'admin-portal-content-management',
  templateUrl: './portal-content-management.component.html',
  styleUrls: ['./portal-content-management.component.scss']
})
export class PortalContentManagementComponent
  extends SearchBase<SearchDataBase>
  implements OnInit, AfterViewInit {

  cmsItems: CmsItem[];
  addItemModel: AddCmsItemModel;
  bodyMax = MaxCmsBodySize;
  @ViewChild('addCmsItem') addCmsItem;
  @ViewChild('loadingModal') loadingModal;
  @ViewChild("searchControl") searchControl: ElementRef;

  constructor(
    private rest: RestApiService,
    private modalService: NgbModal,
    private toast: PortalToasterService) {
    super();
    this.searchFilter = this.searchFilter.bind(this);
  }

  async ngOnInit() {
    this.resetSearchData();
    return this.loadPageData();
  }

  ngAfterViewInit(): void {
    this.afterViewInit(this.searchControl);
  }

  searchFilter(item:CmsItem): boolean {
    return this.testSearch(item.cms_key);
  }

  private async loadPageData() {
    try {
      this.loading = true;
      const items = await this.rest.getCmsData();
      this.cmsItems = items.result;
      this.loading = false;
    } catch (e) {
      this.toast.showError("Error loading CMS Items");
      console.error(e);
      this.loading = false;
    }
  }

  async handleAddKey() {
    this.addItemModel = {};
    const modalRef = this.modalService.open(this.addCmsItem, {
      size: "lg"
    });
    await modalRef.result;
    const key = `${this.addItemModel.portal_name}.${this.addItemModel.page_name}.${this.addItemModel.unique_name}`;
    try {
      this.openLoadingModal();
      await this.rest.addCmsItem({
        cms_key: key,
        body: this.addItemModel.body
      })
      this.toast.showSuccess(`CMS Item "${key}" created.`);
      await this.loadPageData();
      this.modalService.dismissAll();
      this.addItemModel = {};
    }
    catch (err) {
      this.toast.showError("Error Adding CMS Item. Check console log for details.");
      console.error(err);
      this.modalService.dismissAll();
      this.addItemModel = {};
    }
  }

  private openLoadingModal() {
    this.modalService.open(this.loadingModal, {
      size: "sm",
      backdrop: "static"
    });
  }
}

interface AddCmsItemModel {
  portal_name?: string;
  page_name?: string;
  unique_name?: string;
  body?: string;
}
