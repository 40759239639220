<div ngbDropdown>
  <a class="nav-link text-dark" ngbDropdownToggle role="button">
    <img *ngIf="auth.getProfile().picture"
         style="width: 45px; height: 45px; border-radius: 50%" class="d-inline-block"
         [src]="auth.getProfile().picture"/>
    <i *ngIf="!auth.getProfile().picture" class="fas fa-user-circle fa-3x fa-fw m-0 p-0 text-secondary"></i>
  </a>
  <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end menu-layout">
    <div class="dropdown-header menu-header-background bg-secondary">
      <img *ngIf="auth.getProfile().picture"
           [src]="auth.getProfile().picture"/>
      <i *ngIf="!auth.getProfile().picture" class="fas fa-user-circle fa-3x p-0 text-secondary bg-white"></i>
    </div>
    <h6 class="text-center mt-4 mb-4">{{auth.getProfile().name}}</h6>
    <hr class="dropdown-divider mb-2 mt-2"/>
    <button ngbDropdownItem class="mb-1 mt-1" (click)="showProfileModal()"><i class="far fa-fw fa-id-card me-1"></i>
      View My Profile
    </button>
    <ng-content select=[importMenuItems]></ng-content>
    <button ngbDropdownItem class="mb-1 mt-1" (click)="logout()"><i class="fas fa-fw fa-sign-out-alt me-1"></i> Logout
    </button>
  </div>
</div>
<div placement="bottom"
     #timeoutPopover="ngbPopover"
     [ngbPopover]="popTimeoutContent"
     triggers="manual"
     popoverClass="session-timeout-popover"
     container="body"
     [autoClose]="false"></div>
<ng-template #popTimeoutContent>Your session will expire {{sessionExpireTimeText}}</ng-template>

<ng-template #loggingOutModal let-modal>
  <div class="modal-header">Logging Out</div>
  <div class="modal-body text-center">
    <portals-loading-icon class="text-center"
                          style="font-size: 45pt"></portals-loading-icon>
  </div>
</ng-template>
<navbar-profile #navbarProfile></navbar-profile>
